import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ClockHistory } from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { BrandedChannel } from "../../common/interfaces";
import getBrandedChannelHistory, { GetBrandedChannelHistoryResponse } from "../../services/api/brandedChannels/getBrandedChannelHistory";
import { AppContext } from "../../services/context/contextProvider";
import Spinner from "../Spinner/Spinner";

import './BrandedChannelHistoryModal.scss';

export interface BrandedChannelModalProps {
    show: boolean;
    handleClose: () => void;
    br?: BrandedChannel;
};

export default function BrandedChannelHistoryModal({show, handleClose, br}: BrandedChannelModalProps) {
    const appContext = useContext(AppContext);
    
    const [history, setHistory] = useState<GetBrandedChannelHistoryResponse>();

    const apiLoad = useCallback(
        async () => {
            if (br?.id && br.id > 0)
            {
                let res = await getBrandedChannelHistory(br.id);
                if (res.success && res.body) {
                    setHistory(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }
            else
            {
                setHistory(undefined);
            }
        },
        [br, appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [br]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }
    
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <Modal.Header className="branded-channel-history-modal-header">
                <ClockHistory className="branded-channel-history-modal-icon"/>
                <div className="branded-channel-history-modal-title">
                    {`${br? br.name + " History" : "Error"}`}
                </div>
            </Modal.Header>
            <Modal.Body className="branded-channel-history-modal-body">
                <div className="users-tab-table-container">
                    <Table className="users-tab-table" bordered size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Channel</th>
                                <th>Description</th>
                                <th>Customer Types</th>
                                <th>Countries</th>
                                <th>Products</th>
                                <th>Updated By</th>
                                <th>Date Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history ? history.brandedChannelHistory.length > 0 ? history.brandedChannelHistory.map((brc, i) => 
                            <tr key={`Data-${i}`}>
                                <td>{brc.name}</td>
                                <td>{brc.channel}</td>
                                <td>{brc.description}</td>
                                <td>{brc.customerTypes}</td>
                                <td>{brc.countries}</td>
                                <td>{brc.products}</td>
                                <td>{brc.lastUpdatedBy}</td>
                                <td>{DateTime.fromISO(brc.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                            </tr>
                            )
                            :
                            <tr>
                                <td colSpan={8}>No history found...</td>
                            </tr>
                            :
                            <tr>
                                <td className="branded-channel-history-modal-spinner" colSpan={8}>
                                    <Spinner/>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="branded-channel-modal-button-container">
                    <Button
                        className="branded-channel-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}