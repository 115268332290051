import React, { ReactElement, useContext } from "react"
import { DropTargetMonitor, useDrop } from "react-dnd"

import { RoadMapConstants } from "../../../common/appConstants"
import { ItemType } from "../../../common/interfaces"
import { AppContext } from "../../../services/context/contextProvider";
import "./DropWrapper.scss";

type DropWrapperProps = {
    onDrop: (item: ItemType, monitor: DropTargetMonitor, status: string) => void,
    style?: Object;
    children: ReactElement,
    status: string,
    isEmpty: boolean
}
const DropWrapper = ({ onDrop, style, children, status, isEmpty }: DropWrapperProps) => {
    const appContext = useContext(AppContext);

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: RoadMapConstants.itemType,
        canDrop: (item: ItemType, monitor) => {
            const itemIndex = RoadMapConstants.states.findIndex(si => si.status === item.state);
            const statusIndex = RoadMapConstants.states.findIndex(si => si.status === status);
            return [itemIndex + 1, itemIndex - 1, itemIndex + 2, itemIndex - 2, itemIndex].includes(statusIndex);
        },
        drop: (item, monitor) => {
            onDrop(item, monitor, status);
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    if (appContext?.auth.isEditingActive)
        return (
            <div ref={drop} className={`dnd-drop-wrapper${canDrop ? ' dnd-can-drop' : ''}${isEmpty ? ' empty' : ''}`} >
                {React.cloneElement(children, { isOver })}
            </div >
        )
    else
        return (
            <div className={`dnd-drop-wrapper${isEmpty ? ' empty' : ''}`} >
                {React.cloneElement(children, { isOver })}
            </div >
        )
}

export default DropWrapper;