import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M32,14.18a5.87,5.87,0,1,1,5.87-5.87A5.89,5.89,0,0,1,32,14.18ZM32,4.09a4.22,4.22,0,1,0,4.22,4.22A4.22,4.22,0,0,0,32,4.09Z"/>
      <path d="M26.48,61.56a.83.83,0,0,1-.83-.81l-.46-19.18c-2.16-.93-4.1-2.7-3.88-4.81l1.51-15a4.86,4.86,0,0,1,4.74-4.28h8.89a4.86,4.86,0,0,1,4.73,4.28l1.51,15c.22,2.11-1.71,3.88-3.87,4.81l-.47,19.18a.87.87,0,0,1-.84.81.83.83,0,0,1-.81-.85L37.18,41a.82.82,0,0,1,.55-.75c1.75-.64,3.46-2,3.32-3.31l-1.51-15a3.22,3.22,0,0,0-3.09-2.8H27.56a3.23,3.23,0,0,0-3.1,2.8L23,36.92c-.13,1.34,1.57,2.67,3.33,3.31a.8.8,0,0,1,.54.75l.48,19.73a.83.83,0,0,1-.8.85Z"/>
      <path d="M49.08,19.2a5.3,5.3,0,1,1,5.3-5.3A5.31,5.31,0,0,1,49.08,19.2Zm0-8.95a3.65,3.65,0,1,0,3.65,3.65A3.66,3.66,0,0,0,49.08,10.25Z"/>
      <path d="M54,61.56h0a.83.83,0,0,1-.81-.85l.43-17.51a.82.82,0,0,1,.54-.76c1.52-.55,3-1.7,2.89-2.83L55.68,26.27A2.76,2.76,0,0,0,53,23.87h-7.5a.83.83,0,0,1,0-1.65H53a4.41,4.41,0,0,1,4.29,3.89l1.34,13.33c.21,2-1.62,3.55-3.44,4.34l-.41,17A.83.83,0,0,1,54,61.56Z"/>
      <path d="M14.92,19.2a5.3,5.3,0,1,1,5.3-5.3A5.31,5.31,0,0,1,14.92,19.2Zm0-8.95a3.65,3.65,0,1,0,3.65,3.65A3.66,3.66,0,0,0,14.92,10.25Z"/>
      <path d="M10,61.56a.83.83,0,0,1-.83-.81l-.41-17C7,43,5.13,41.46,5.34,39.44L6.68,26.11A4.41,4.41,0,0,1,11,22.22h7.5a.83.83,0,0,1,0,1.65H11a2.76,2.76,0,0,0-2.65,2.4L7,39.6c-.12,1.14,1.37,2.29,2.89,2.84a.84.84,0,0,1,.55.76l.42,17.51a.83.83,0,0,1-.81.85Z"/>
    </svg>
  )
}

export default SvgComponent
