import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M17.58,49.36l14.1,7.94a.65.65,0,0,0,.54,0l.21-.08,14-7.89,14.14,6.26a.92.92,0,0,0,1.21-1.22h0L53.64,36.5a1,1,0,0,0-.47-.46l-9.54-4.28c-.26.56-.52,1.11-.79,1.67l9.26,4.15,7,15.38-12-5.32-3.61-9.42a.92.92,0,0,0-1.19-.53.89.89,0,0,0-.51.48.92.92,0,0,0,0,.71l3.46,9-12.29,7V48.39a2.11,2.11,0,0,1-1.85,0v6.46l-12.29-7,3.47-9a1,1,0,0,0,0-.71.89.89,0,0,0-.51-.48.88.88,0,0,0-.71,0,.91.91,0,0,0-.49.51L16.9,47.64,4.91,53l7-15.38,9.24-4.15-.79-1.66L10.81,36a.9.9,0,0,0-.46.46L2.25,54.4a.91.91,0,0,0,1.2,1.22Z"/>
      <path d="M44.07,18.72a12.09,12.09,0,0,0-24.18,0c0,5.07,7.1,18.38,11.3,25.44a1,1,0,0,0,1.58,0C33.93,42.21,44.07,24.94,44.07,18.72ZM32,41.87l-.5-.88c-2.29-4-9.75-17.48-9.75-22.27a10.25,10.25,0,1,1,20.5,0c0,4.79-7.46,18.26-9.75,22.27Z"/>
      <path d="M25.28,18.72A6.7,6.7,0,1,0,32,12,6.71,6.71,0,0,0,25.28,18.72ZM32,13.86a4.86,4.86,0,1,1-4.85,4.86A4.86,4.86,0,0,1,32,13.86Z"/>
    </svg>
  )
}

export default SvgComponent
