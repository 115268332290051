import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ClockHistory } from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { ProductWithOfferFamilyId } from "../../common/interfaces";
import getProductHistory, { GetProductHistoryResponse } from "../../services/api/products/getProductHistory";
import { AppContext } from "../../services/context/contextProvider";
import Spinner from "../Spinner/Spinner";

import './ProductHistoryModal.scss';

export interface ProductHistoryModalProps {
    show: boolean;
    handleClose: () => void;
    product?: ProductWithOfferFamilyId;
};

export default function ProductHistoryModal({show, handleClose, product}: ProductHistoryModalProps) {
    const appContext = useContext(AppContext);
    
    const [history, setHistory] = useState<GetProductHistoryResponse>();

    const apiLoad = useCallback(
        async () => {
            if (product?.id && product.id > 0)
            {
                let res = await getProductHistory(product.id);
                if (res.success && res.body) {
                    setHistory(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }
            else
            {
                setHistory(undefined);
            }
        },
        [product, appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [product]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }
    
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <Modal.Header className="product-history-modal-header">
                <ClockHistory className="product-history-modal-icon"/>
                <div className="product-history-modal-title">
                    {`${product? product.name + " History" : "Error"}`}
                </div>
            </Modal.Header>
            <Modal.Body className="product-history-modal-body">
                <Table className="users-tab-table" bordered size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Offer Family</th>
                            <th>Product Manager</th>
                            <th>Lifecycle Stage</th>
                            <th>Countries</th>
                            <th>Updated By</th>
                            <th>Date Updated</th>
                        </tr>
                    </thead>
                    <tbody>
                        {history ? history.productHistory.length > 0 ? history.productHistory.map((product, i) => 
                        <tr key={`product-${i}`}>
                            <td>{product.name}</td>
                            <td>{product.description}</td>
                            <td>{product.offerFamily}</td>
                            <td>{product.productManager}</td>
                            <td>{product.lifecycleStage}</td>
                            <td>{product.countries}</td>
                            <td>{product.lastUpdatedBy}</td>
                            <td>{DateTime.fromISO(product.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                        </tr>
                        )
                        :
                        <tr>
                            <td colSpan={8}>No history found...</td>
                        </tr>
                        :
                        <tr>
                            <td className="product-history-modal-spinner" colSpan={8}>
                                    <Spinner/>
                                </td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <div className="product-history-modal-button-container">
                    <Button
                        className="product-history-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}