import './Spinner.scss';

interface SpinnerProps {
    size?: number;
    colour?: string;
}

export default function Spinner({size, colour} : SpinnerProps) {
    if (!size) {
        size = 30;
    }
    let thickness = 3;
    if (size > 50) {
        thickness = 5;
    }
    else if (size > 30) {
        thickness = 4;
    }
    let innerSize = size - 2 * thickness;

    let colourClass = "lds-ring-colour-primary";
    if (colour === "secondary") {
        colourClass = "lds-ring-colour-secondary";
    }
    else if (colour === "light") {
        colourClass = "lds-ring-colour-light";
    }

    return (
        <div 
            className="lds-ring"
            style={{ height: size, width: size}}
        >
            <div
                className={colourClass}
                style={{ height: innerSize, width: innerSize, margin: thickness, borderWidth: thickness}}
            />
            <div
                className={colourClass}
                style={{ height: innerSize, width: innerSize, margin: thickness, borderWidth: thickness}}
            />
            <div
                className={colourClass}
                style={{ height: innerSize, width: innerSize, margin: thickness, borderWidth: thickness}}
            />
            <div
                className={colourClass}
                style={{ height: innerSize, width: innerSize, margin: thickness, borderWidth: thickness}}
            />
        </div>
    )
}