// package imports
import { useCallback, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import TagManager from 'react-gtm-module';

// project imports
import { AppContext } from '../../services/context/contextProvider';
import Banner, { BannerProps } from "../../components/Banner/Banner";
import Spinner from '../../components/Spinner/Spinner';
import { ISidebar } from "../../components/Sidebar/Sidebar";
import { MessageStatus } from '../../common/appConstants';
import BusinessLinesIcon from "../../assets/images/icons/BusinessLinesIcon";

import getProductCountryMatrix, { GetProductCountryMatrixResponse } from "../../services/api/products/getProductCountryMatrix";
import getBusinessLines from "../../services/api/businessLines/getBusinessLines";
import getOfferFamilies from "../../services/api/offerFamilies/getOfferFamilies";
import uniqueFilter from "../../services/utils/uniqueFilter";
import { BusinessLineCountry } from "../../common/interfaces";

import './PrimaryMarkets.scss';
import '../../styles/country_flags.scss';

type ProductCountryMatrixParams = {
    businessLineLink: string,
    offerFamilyLink: string
}

function isFirstRegion(matrix: BusinessLineCountry[], region: string, index: number) : boolean
{
    return matrix.map(m => m.region.toLowerCase()).indexOf(region.toLowerCase()) === index;
}

function appendRegionShading(matrix: BusinessLineCountry[], region: string) : string
{
    var regions = matrix?.map((blc) => blc.region.toLowerCase()).filter(uniqueFilter);
    var i = regions.indexOf(region.toLowerCase());

    if (i < 0)
        return '';

    return i % 2 ? 'col-even' : 'col-odd';
}

function truncate(text: string, len: number) : string {
    return text.length > len ? text.substring(0,len-3) + '...' : text;
}

const tagManagerArgs = {
    dataLayer: {
        page: 'PrimaryMarkets'
    },
    dataLayerName: 'PageDataLayer'
}

export default function PrimaryMarkets() {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'PrimaryMarkets Views',
            value: 'PrimaryMarkets',
            page: {
                location: pageLocation,
                title: "PrimaryMarkets"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "PrimaryMarkets",
                value: "PrimaryMarkets",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    let { businessLineLink } = useParams<ProductCountryMatrixParams>();

    const [bannerProps, setBannerProps] = useState<BannerProps>({});
    const [details, setDetails] = useState<GetProductCountryMatrixResponse>();
    const [loading, setLoading] = useState(false);

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            const sidebar: ISidebar = {
                options: {
                    businessLines: { list: [] }
                }
            }

            let blRes = await getBusinessLines();
            if (blRes.success && blRes.body) {
                sidebar.options.businessLines = {
                    list: blRes.body.businessLines.map(bl => {
                        return {
                            link: bl.link,
                            name: bl.name,
                            isActive: bl.link === businessLineLink
                        };
                    })
                };
            }
            else if (blRes.message) {
                appContext?.setMessage({ show: true, message: blRes.message, status: blRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            let ofRes = await getOfferFamilies(businessLineLink);

            if (ofRes.success && ofRes.body) {
                sidebar.options.selectedBusinessLine = {
                    link: ofRes.body.businessLine.link,
                    name: ofRes.body.businessLine.name,
                    offerFamilies: ofRes.body.businessLine.offerFamilies.map(of => {
                        return {
                            link: of.link,
                            name: of.name
                        };
                    }),
                    primaryMarketsActive: true
                };

               let bannerProps: BannerProps = {
                    titleIcon: <BusinessLinesIcon />,
                    title1: ofRes.body.businessLine.name,
                    title2: "Primary Markets",
                    breadcrumbs: [{ link: "/", displayName: "Home" }, 
                                  { link: "/business-lines", displayName: "Business Lines" },
                                  { link: `/business-lines/${ofRes.body.businessLine.link}`, displayName: ofRes.body.businessLine.name }],
                    breadcrumbActive: "Primary Markets",
                    subtitle1: {
                        field: "Head of Product",
                        value: ofRes.body.businessLine.headOfProduct,
                    }
                }
                setBannerProps(bannerProps);

            }
            else if (ofRes.message) {
                appContext?.setMessage({ show: true, message: ofRes.message, status: ofRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            } 

            let matrixRes = await getProductCountryMatrix(businessLineLink, '');
            if (matrixRes && matrixRes.body)
            {
                setDetails(matrixRes.body);
            }
            else if (matrixRes.message) {
                appContext?.setMessage({ show: true, message: matrixRes.message, status: matrixRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            } 

            appContext?.setSidebar(sidebar);
            setLoading(false);
        },
        [businessLineLink, appContext]
    );

    useEffect(() => {
        setBannerProps({});
        setDetails(undefined);
        apiLoad();
    }, [businessLineLink]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-container">

            <Banner
                titleIcon={bannerProps.titleIcon}
                title1={bannerProps.title1}
                title2={bannerProps.title2}
                breadcrumbs={bannerProps.breadcrumbs}
                breadcrumbActive={bannerProps.breadcrumbActive}
                subtitle1={bannerProps.subtitle1}
            />

            <Container className="primary-markets-container">
                {  loading ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        { //the Product Country grid 
                        <div className="primary-markets-table-container">
                            <table> 
                                {/* spacer row */}
                                <tr className="sticky-row-0"> 
                                    <td colSpan={100} className="spacer-row">&nbsp;</td> 
                                </tr>

                                {/* row for region headers */}
                                <tr className="sticky-row-1"> 
                                    <td className="sidebar">&nbsp;</td> 

                                    { details && details.value.businessLineCountries.map((blc, i) => ( 
                                        isFirstRegion(details.value.businessLineCountries, blc.region, i) ?
                                        <td colSpan={details.value.businessLineCountries.filter(c => c.region.toLowerCase() === blc.region.toLowerCase()).length } 
                                            className={ appendRegionShading(details.value.businessLineCountries, blc.region) }>
                                            <div className="region-row" > { blc.region.length > 0 ? blc.region : <span>&nbsp;</span> } </div>
                                        </td>
                                    :    
                                        <></>
                                    ))}

                                </tr>

                                {/* row for flag icons */}
                                <tr className="sticky-row-2"> 
                                    <td className="sidebar">&nbsp;</td> 
                                    { details && details.value.businessLineCountries.map((blc, j) => ( 
                                    <td className={ appendRegionShading(details.value.businessLineCountries, blc.region) }>
                                        <div className="flags-row" >
                                            <div title={ blc.country.name } className={"flagx flag-iso3-" + blc.country.iso3 }  ></div>
                                                {/* <img className="flag" alt={ blc.country.iso3 } src={countryFlags} /> */}
                                                {/* <div className={"flag flag-iso3-" + blc.country.iso3 } style={{backgroundImage: 'url(' + {countryFlags} +')' }} ></div>  */}
                                                {/* <div className={"flag flag-iso3-" + blc.country.iso3} style={{backgroundImage: 'url(' + {countryFlags} +')' }} /></div> */}
                                        </div> 
                                    </td>
                                    ))}
                                </tr>

                                {/* row for ISO country codes */}
                                <tr className="sticky-row-3"> 
                                    <td className="sidebar">&nbsp;</td> 
                                    { details && details.value.businessLineCountries.map((blc, i) => ( 
                                    <td className={ appendRegionShading(details.value.businessLineCountries, blc.region) }>
                                        <div className="country-row" >
                                            { blc.country.iso3 }
                                        </div>                           
                                    </td>
                                    ))}
                                </tr>

                                {/* now iterate over each Offer Family in the Grid data */}
                                { details && details.value.productCountryMatrix.map((ofp) =>
                                <>
                                    <tr>
                                        <td className="truncate">
                                            <Link to={`/business-lines/${businessLineLink}/${ofp.offerFamilyLink}/vision-board`}>

                                            { details && details.value.businessLineCountries.length > 10 ? 
                                                <div className="offerFamilyName" 
                                                     title={ ofp.offerFamilyName.length > 24 ? ofp.offerFamilyName : ''  } >
                                                { truncate(ofp.offerFamilyName,24) } 
                                                </div>
                                                :    
                                                <div className="offerFamilyName" title={ ofp.offerFamilyName.length > 40 ? ofp.offerFamilyName : ''  } >
                                                    { truncate(ofp.offerFamilyName,40) } 
                                                </div>
                                            }
                                            </Link>
                                        </td>
                                        { details && details.value.businessLineCountries.map((blc) => 
                                        <td className={ appendRegionShading(details.value.businessLineCountries, blc.region) } >
                                            <div>&nbsp;</div>  {/* no ticks or crosses at Offer Family level? */} 
                                        </td>
                                        )}
                                    </tr>

                                    {/* iterate over each Product in the Offer Family */}
                                    { ofp.productCountries.map((pc) =>
                                    <tr>
                                        <td className="truncate">
                                            <Link to={`/business-lines/${businessLineLink}/${ofp.offerFamilyLink}/${pc.link}/vision-board`}>
                                                { details && details.value.businessLineCountries.length > 10 ? 
                                                    <div className="productName" title={ pc.name.length > 32 ? pc.name : ''  } >
                                                    { truncate(pc.name,32) } 
                                                    </div>
                                                    :    
                                                    <div className="productName" title={ pc.name.length > 50 ? pc.name : ''  } >    
                                                    { truncate(pc.name,50) }
                                                    </div>
                                                }
                                            </Link>
                                        </td>

                                        { details.value.businessLineCountries.map((blc, i) => 
                                        <td className={ (pc.countryIndicators[i] ? 'col-tick' : 'col-cross') 
                                            + ' ' + appendRegionShading(details.value.businessLineCountries, blc.region) }>
                                            { details && details.value.businessLineCountries.length > 10 ? 
                                                <div>
                                                    { pc.countryIndicators[i] ? <span>&#10004;</span> : <span>&#8212;</span>  } 
                                                </div>
                                                    :    
                                                <div className="wider-countries">
                                                    { pc.countryIndicators[i] ? <span>&#10004;</span> : <span>&#8212;</span>  } 
                                                </div>
                                            }
                                        </td>
                                        )}

                                    </tr>
                                    )}     
                                </>
                                )}

                                {/* footer row  */}
                                <tr> 
                                    <td className="sidebar">&nbsp;</td> 

                                    { details && details.value.businessLineCountries.map((blc, i) => ( 
                                        isFirstRegion(details.value.businessLineCountries, blc.region, i) ?
                                        <td colSpan={details.value.businessLineCountries.filter(c => c.region.toLowerCase() === blc.region.toLowerCase()).length } 
                                            className={ appendRegionShading(details.value.businessLineCountries, blc.region) }>
                                            <div className="region-row" > <span>&nbsp;</span>  </div>
                                        </td>
                                    :    
                                        <></>
                                    ))}

                                </tr>

                            </table>
                            </div>
                        }
                    </>
                }
            </Container>
        </div>
    );
}