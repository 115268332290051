import { Button, Modal } from "react-bootstrap";
import { ExclamationCircle, InfoCircle, XCircle } from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import './MessageModal.scss';

export interface MessageModalProps {
    show: boolean;
    handleClose: () => void;
    message?: string;
    status?: string;
};

export default function MessageModal({ show, handleClose, message, status } : MessageModalProps) {
    const getIcon = () => {
        if (status === MessageStatus.error) {
            return <XCircle className="message-modal-icon-error"/>
        }
        else if (status === MessageStatus.warning) {
            return <ExclamationCircle className="message-modal-icon-warning"/>
        }
        else {
            return <InfoCircle className="message-modal-icon-info"/>
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
        >
            <Modal.Header className="message-modal-header">
                {getIcon()}
            </Modal.Header>
            <Modal.Body className="message-modal-body">
                <div className="message-modal-message">{message}</div>
            </Modal.Body>
            <Modal.Footer className="message-modal-footer">
                <Button
                    className="message-modal-button"
                    onClick={handleClose}
                    variant="outline-secondary"
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
}