import React, { createContext, useEffect, useState } from "react";
import { Config, MessageStatus } from "../../common/appConstants";
import { ISidebar } from "../../components/Sidebar/Sidebar";
import { LoginResponse } from "../api/auth/login";
import { externalAuth, getCurrentUserAuth, loginAuth, logoutAuth, setEditorState } from "../auth/authService";

interface IAuth {
    loggedIn: boolean;
    loggingOut: boolean;
    id: string;
    username: string;
    isAdmin: boolean;
    isEditor: boolean;
    isRootAdmin: boolean;
    isEditingActive: boolean;
}

interface IMessage {
    show: boolean;
    message?: string;
    status?: string;
}

interface IAppContext {
    auth: IAuth;
    login: () => Promise<ActionResponse>;
    setAuthExternal: (authParams: LoginResponse) => void;
    logout: () => void;
    toggleEditingActive: () => void;
    message: IMessage;
    setMessage: React.Dispatch<React.SetStateAction<IMessage>>;
    sidebar: ISidebar;
    setSidebar: React.Dispatch<React.SetStateAction<ISidebar>>;
    feedback: boolean;
    setFeedback: React.Dispatch<React.SetStateAction<boolean>>;
    newDate: boolean;
    setNewDate: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ActionResponse {
    success: boolean;
    message: string;
    status?: string;
}

const blankAuth: IAuth = {
    loggedIn: false,
    loggingOut: false,
    id: "",
    username: "",
    isAdmin: false,
    isEditor: false,
    isRootAdmin: false,
    isEditingActive: false
}

const blankMessage: IMessage = {
    show: false,
    message: ""
}

const blankSidebar: ISidebar = {
    options: {
        businessLines: {
            list: []
        }
    }
}

export const AppContext = createContext<IAppContext | null>(null);

export const ContextProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [auth, setAuth] = useState(blankAuth);
    const [message, setMessage] = useState(blankMessage);
    const [sidebar, setSidebar] = useState(blankSidebar);
    const [feedback, setFeedback] = useState(false);
    const [newDate, setNewDate] = useState(false);

    useEffect(() => {
        loadUser();

        let interval = setInterval(() => {
            loadUser();
        }, Config.refreshUserMs);

        return () => { clearInterval(interval) }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    async function loadUser() {
        const response = await getCurrentUserAuth();
        setAuth({
            loggedIn: response.loggedIn,
            loggingOut: false,
            id: response.id,
            username: response.username,
            isAdmin: response.isAdmin,
            isEditor: response.isEditor,
            isRootAdmin: response.isRootAdmin,
            isEditingActive: response.isEditingActive
        });
    }

    async function login(): Promise<ActionResponse> {
        const response = await loginAuth();
        setAuth({
            loggedIn: response.loggedIn,
            loggingOut: false,
            id: response.id,
            username: response.username,
            isAdmin: response.isAdmin,
            isEditor: response.isEditor,
            isRootAdmin: response.isRootAdmin,
            isEditingActive: response.isEditingActive
        });

        if (response.loggedIn) {
            return { success: true, message: "Logged in successfully." };
        }
        else if (response.message) {
            return { success: false, message: response.message, status: response.status };
        }
        else {
            return { success: false, message: "An unknown error occurred during login.", status: MessageStatus.error }
        }
    }

    function setAuthExternal(authParams: LoginResponse) {
        const response = externalAuth(authParams);
        setAuth({
            loggedIn: response.loggedIn,
            loggingOut: false,
            id: response.id,
            username: response.username,
            isAdmin: response.isAdmin,
            isEditor: response.isEditor,
            isRootAdmin: response.isRootAdmin,
            isEditingActive: response.isEditingActive
        });

        if (response.loggedIn) {
            return { success: true, message: "Logged in successfully." };
        }
        else if (response.message) {
            return { success: false, message: response.message, status: response.status };
        }
        else {
            return { success: false, message: "An unknown error occurred during login.", status: MessageStatus.error }
        }
    }

    function logout() {
        const response = logoutAuth();
        setAuth({
            loggedIn: response.loggedIn,
            loggingOut: true,
            id: response.id,
            username: response.username,
            isAdmin: response.isAdmin,
            isEditor: response.isEditor,
            isRootAdmin: response.isRootAdmin,
            isEditingActive: response.isEditingActive
        });

        if (response.message) {
            return { success: false, message: response.message };
        }
        else {
            return { success: true, message: "Logged out successfully." };
        }
    }

    function toggleEditingActive() {
        if (auth.isEditor) {
            const editorState = !auth.isEditingActive;
            setAuth({
                ...auth,
                isEditingActive: editorState
            });
            setEditorState(editorState);
        }
    }

    return (
        <AppContext.Provider value={{ auth, login, setAuthExternal, logout, toggleEditingActive, message, setMessage, sidebar, setSidebar, feedback, setFeedback, newDate, setNewDate }}>
            {children}
        </AppContext.Provider>
    );
};
