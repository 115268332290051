import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons';
import { MessageStatus, Role } from '../../common/appConstants';
import { User } from '../../common/interfaces';
import deleteUser from '../../services/api/users/deleteUser';
import getUsers from '../../services/api/users/getUsers';
import { AppContext } from '../../services/context/contextProvider';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Spinner from '../Spinner/Spinner';
import UserModal from '../UserModal/UserModal';
import './UsersTab.scss'

type UsersTabProps = {
    currentUser: string;
}

export default function UsersTab({ currentUser } : UsersTabProps) {
    const appContext = useContext(AppContext);
    
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
    const [userFilter, setUserFilter] = useState("");
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [userModalShow, setUserModalShow] = useState(false);
    const [selectedUser, setSelectedUser] = useState<User>();

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            let res = await getUsers();
    
            if (res.success && res.body) {
                setUsers(res.body.users);
                if (userFilter.length > 0) {
                    performFilter(userFilter, res.body.users);
                }
                else {
                    setUsersFiltered(res.body.users);
                }
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
            setLoading(false);
        },
        [userFilter, appContext]
    );
    
    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const confirmDelete = (message: string, id: number) => {
        setConfirmModalMessage(`Are you sure you want to delete User ${message}?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    }

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);
        const res = await deleteUser(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const editUser = (user: User) => {
        setSelectedUser(user);
        setUserModalShow(true);
    }

    const addUser = () => {
        setSelectedUser(undefined);
        setUserModalShow(true);
    }

    const filterOnChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setUserFilter(e.currentTarget.value);
        performFilter(e.currentTarget.value, users);
    }

    const performFilter = (val : string, list: User[]) => {
        const newList = list.filter(user => {
            return user.username.toLowerCase().includes(val.toLowerCase()) ||
                   user.firstName.toLowerCase().includes(val.toLowerCase()) ||
                   user.lastName.toLowerCase().includes(val.toLowerCase()) ||
                   (user.externalId && user.externalId.toLowerCase().includes(val.toLowerCase())) 
        });
        setUsersFiltered(newList);
    }

    return (
        <div className="users-tab-container p-3">
            <ConfirmModal
                show={confirmModalShow}
                message={confirmModalMessage}
                callback={confirmModalCallback}
                handleClose={() => setConfirmModalShow(false)}
                loading={confirmModalLoading}
            />

            <UserModal
                show={userModalShow}
                handleClose={() => { setUserModalShow(false); setSelectedUser(undefined)}}
                user={selectedUser}
                reload={apiLoad}
            />
            
            {loading ?
            <div className="spinner-container">
                <Spinner size={60}/>
            </div>
            :
            <>
            <div className="mb-2">
                Currently logged in as: <b>{currentUser}</b>
            </div>
            <div className="users-tab-top-container mb-2">
                <Form.Control 
                    value={userFilter}
                    onChange={filterOnChange}
                    type="text" 
                    placeholder="Filter for User..." 
                />
                <Button 
                    variant="outline-primary"
                    onClick={addUser}
                    className="users-tab-add-button"
                >
                    <PlusCircle className="add-button-icon"/> User
                </Button>
            </div>
            
            <div className="users-tab-table-container">
                <Table className="users-tab-table" bordered size="sm">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Azure Active Directory ID</th>
                            <th>Linked</th>
                            <th>Role</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersFiltered.length > 0 ?
                        usersFiltered.map((user, i) => 
                        <tr key={`user-${i}`}>
                            <td>{user.username}</td>
                            <td>{user.externalId}</td>
                            <td>{user.externalIdLinked ? "Yes" : "No"}</td>
                            <td>{user.role === Role.rootAdmin ? "User management" : user.role}</td>
                            <td>{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td className="users-tab-action-container">
                                <div 
                                    className="users-tab-action-icon"
                                    onClick={() => editUser(user)}
                                    title="edit User details"
                                >
                                    <PencilSquare/>
                                </div>
                                <div 
                                    className="users-tab-action-icon"
                                    onClick={() => confirmDelete(user.username, user.id)}
                                    title="delete User"
                                >
                                    <Trash/>
                                </div>
                            </td>
                        </tr>
                        )
                        :
                        <tr>
                            <td colSpan={5}>No users found...</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
            </>
            }
        </div>
    );
}