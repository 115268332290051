import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import ChannelsIcon from "../../assets/images/icons/ChannelsIcon";
import { AppError } from "../../common/appConstants";
import { ApiResponse, BusinessLine, BusinessLineCountry, Country } from "../../common/interfaces";
import postBusinessLineCountry from "../../services/api/businessLineCountries/postBusinessLineCountry";
import putBusinessLineCountry from "../../services/api/businessLineCountries/putBusinessLineCountry";
import Spinner from "../Spinner/Spinner";
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import './PrimaryMarketModal.scss';

export interface PrimaryMarketModalProps {
    show: boolean;
    handleClose: () => void;
    primaryMarket?: BusinessLineCountry;
    selectedBusinessLineId: number;
    businessLines: BusinessLine[];
    countries: Country[];
    regions: string[];
    reload: () => Promise<void>;
};

type PrimaryMarketForm = {
    valid: boolean;
    validationError: string;
    businessLineId: number;
    businessLineIdError: string;
    countryId: number;
    countryIdError: string;
    region: string;
    regionError: string;
    isActive: boolean;
}

const blankPrimaryMarket : PrimaryMarketForm = { valid: true, validationError: "", businessLineId: 0, businessLineIdError: "", countryId: 0, countryIdError: "", region: "", regionError: "", isActive: false };

export default function PrimaryMarketModal({ show, handleClose, primaryMarket, selectedBusinessLineId, businessLines, countries, regions, reload } : PrimaryMarketModalProps) {
    const [form, setForm] = useState(blankPrimaryMarket);
    const [loading, setLoading] = useState(false);

    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);

    useEffect(() => {

        if (primaryMarket) {
            setForm({
                ...form,
                businessLineId: selectedBusinessLineId,
                region: primaryMarket.region,
                countryId: primaryMarket.country.id,
                isActive: primaryMarket.isActive
            });
        }
        else {
            setForm({
                ...blankPrimaryMarket
            });
        }

    }, [primaryMarket]); // eslint-disable-line react-hooks/exhaustive-deps

    const confirmAddRegion = (e : React.FormEvent<HTMLButtonElement>) => {
        
        if (regions.includes(form.region))
            attemptSave(e, false);
        else if (form.region.trim() === '')
        {
            setConfirmModalMessage(`Are you sure you want to add a blank Region?`);
            setConfirmModalCallback(() => () => attemptSave(e, true));
            setConfirmModalShow(true);
        }
        else
        {
            setConfirmModalMessage(`Are you sure you want to add new Region ${form.region}?`);
            setConfirmModalCallback(() => () => attemptSave(e, true));
            setConfirmModalShow(true);
        }
    }

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>, confirm: boolean) => {
        e.preventDefault();

        if (confirm)
            setConfirmModalLoading(true);

        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };

        form.businessLineId = selectedBusinessLineId;
        
        if (form.businessLineId === 0) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.businessLineIdError = 'A Business Line must be selected.';
        }

        if (form.countryId === 0) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.regionError = 'A Country must be selected.';
        }

        if (form.region.length > 20) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.regionError = `Region must be no more than 20 characters. [currently ${form.region.length} characters]`
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> | ApiResponse<BusinessLineCountry>;

            if (primaryMarket?.id) { //update
                res = await putBusinessLineCountry(primaryMarket.id, {
                    businessLineId: form.businessLineId,
                    countryId: form.countryId,
                    region: form.region,
                    isActive: form.isActive,
                    moveUp: false,
                    moveDown: false
                });
            }
            else {  //create
                res = await postBusinessLineCountry({
                    businessLineId: form.businessLineId,
                    countryId: form.countryId,
                    region: form.region,
                    isActive: form.isActive,
                    moveUp: false,
                    moveDown: false
                });
            }

            if (res && res.success) {
                formUpdate = blankPrimaryMarket;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);

        if (confirm)
        {
            setConfirmModalLoading(false);
            setConfirmModalShow(false);
        }

        setLoading(false);
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        
            setForm({
                ...form,
                valid: true,
                validationError: "",
                businessLineIdError: "",
                countryIdError: "",
                regionError: "",
                [e.currentTarget.name]: e.currentTarget.value
            });

    }

    const handleSwitchChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        
            setForm({
                ...form,
                valid: true,
                validationError: "",
                businessLineIdError: "",
                countryIdError: "",
                regionError: "",
                isActive: e.currentTarget.checked
            });
    }

     const handleSelectChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            businessLineIdError: "",
            countryIdError: "",
            regionError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (


        
        <Modal
            show={show}
            onHide={() => { setForm(blankPrimaryMarket); handleClose() }}
            centered
            size="xl"
        >
            <ConfirmModal
                show={confirmModalShow}
                message={confirmModalMessage}
                callback={confirmModalCallback}
                handleClose={() => setConfirmModalShow(false)}
                loading={confirmModalLoading}
            />

            <Modal.Header className="primary-market-modal-header">
                <ChannelsIcon className="primary-market-modal-icon"/>
                <div className="primary-market-modal-title">
                    {primaryMarket ? "Edit Primary Market" : "Add new Primary Market"}
                </div>
            </Modal.Header>
            <Modal.Body className="primary-market-modal-body">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupBL">
                                <Form.Label>Business Line*</Form.Label>
                                <Form.Select 
                                    value={ selectedBusinessLineId > 0 ? selectedBusinessLineId : form.businessLineId}
                                    name="businessLineId"
                                    onChange={handleSelectChange}
                                    isInvalid={form.businessLineIdError.length > 0}
                                    disabled={ loading || selectedBusinessLineId > 0}
                                    className={selectedBusinessLineId > 0 ? "": "primary-market-modal-placeholder"}
                                >
                                    <option key="blo-0" value={"0"} disabled selected hidden>Select a Business Line</option>
                                    {businessLines.map((bl, i) =>
                                        <option className="primary-market-modal-selected" key={`blo-${i}`} value={bl.id}>{bl.name}</option>
                                    )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {form.businessLineIdError}
                                </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupCY">
                                <Form.Label>Country*</Form.Label>
                                <Form.Select 
                                    value={form.countryId}
                                    name="countryId"
                                    onChange={handleSelectChange}
                                    isInvalid={form.countryIdError.length > 0}
                                    disabled={loading}
                                    className={form.countryId > 0 ? "": "primary-market-modal-placeholder"}
                                >
                                    <option key="cyo-0" value={"0"} disabled selected hidden>Select a Country</option>
                                    {countries.map((cy, i) =>
                                        <option className="primary-market-modal-selected" key={`cyo-${i}`} value={cy.id}>{cy.name}</option>
                                    )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {form.countryIdError}
                                </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupRegion">
                        <Form.Label>Region</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter a Region (optional)"
                            value={form.region}
                            name="region"
                            onChange={handleChange}
                            isInvalid={form.regionError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.regionError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupRegion">
                        <Form.Label>Enabled</Form.Label>
                        <Form.Check  
                            type="switch"
                            checked={form.isActive}
                            name="isActive"
                            isInvalid={false}
                            onChange={handleSwitchChange}
                            disabled={loading}
                        />
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="primary-market-modal-footer">
                <div className="primary-market-modal-error">
                    {!form.valid && <><ExclamationCircle className="primary-market-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="primary-market-modal-button-container">
                    <Button
                        className="primary-market-modal-button"
                        onClick={() => { setForm(blankPrimaryMarket); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "channel-modal-button-loading" : "channel-modal-button"}
                        onClick={confirmAddRegion}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}