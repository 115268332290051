import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ClockHistory } from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { ItemType } from "../../common/interfaces";
import getRoadmapCardHistory, { GetRoadmapCardDataHistoryResponse } from "../../services/api/roadmapCard/getRoadmapCardHistory";
import { AppContext } from "../../services/context/contextProvider";
import Spinner from "../Spinner/Spinner";

import './RoadmapCardHistoryModal.scss';

export interface RoadmapCardModalProps {
    show: boolean;
    handleClose: () => void;
    roadmapCard?: ItemType;
};

export default function RoadmapCardHistoryModal({show, handleClose, roadmapCard}: RoadmapCardModalProps) {
    const appContext = useContext(AppContext);
    
    const [history, setHistory] = useState<GetRoadmapCardDataHistoryResponse>();

    const apiLoad = useCallback(
        async () => {
            if (roadmapCard?.id && roadmapCard.id > 0)
            {
                let res = await getRoadmapCardHistory(roadmapCard.id);
                if (res.success && res.body) {
                    setHistory(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }
            else
            {
                setHistory(undefined);
            }
        },
        [roadmapCard, appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [roadmapCard]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }
    
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <Modal.Header className="roadmap-card-history-modal-header">
                <ClockHistory className="roadmap-card-history-modal-icon"/>
                <div className="roadmap-card-history-modal-title">
                    {`${roadmapCard? roadmapCard.title + " History" : "Error"}`}
                </div>
            </Modal.Header>
            <Modal.Body className="roadmap-card-history-modal-body">
                <div className="users-tab-table-container">
                    <Table className="users-tab-table" bordered size="sm">
                        <thead>
                            <tr>
                                <th>Theme</th>
                                <th>Strategic Objective</th>
                                <th>Job Story</th>
                                <th>State</th>
                                <th>Tags</th>
                                <th>Updated By</th>
                                <th>Date Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history ? history.roadmapCardDataHistory.length > 0 ? history.roadmapCardDataHistory.map((rc, i) => 
                            <tr key={`Data-${i}`}>
                                <td>{rc.title}</td>
                                <td>{rc.objective}</td>
                                <td>{rc.jobStory}</td>
                                <td>{rc.state}</td>
                                <td>{rc.tags}</td>
                                <td>{rc.lastUpdatedBy}</td>
                                <td>{DateTime.fromISO(rc.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                            </tr>
                            )
                            :
                            <tr>
                                <td colSpan={7}>No history found...</td>
                            </tr>
                            :
                            <tr>
                                <td className="roadmap-card-history-modal-spinner" colSpan={7}>
                                    <Spinner/>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="roadmap-card-modal-button-container">
                    <Button
                        className="roadmap-card-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}