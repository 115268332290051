import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { ArrowDownCircle, ArrowUpCircle, PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons';
import { Channel } from '../../common/interfaces';
import deleteChannel from '../../services/api/channels/deleteChannel';
import getChannels from '../../services/api/channels/getChannels';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import ChannelModal from '../ChannelModal/ChannelModal';
import Spinner from '../Spinner/Spinner';
import './ChannelsTab.scss';
import promoteChannel from '../../services/api/channels/promoteChannel';
import demoteChannel from '../../services/api/channels/demoteChannel';
import { AppContext } from '../../services/context/contextProvider';
import { MessageStatus } from '../../common/appConstants';

export default function ChannelsTab() {
    const appContext = useContext(AppContext);
    
    const [loading, setLoading] = useState(false);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [channelsFiltered, setChannelsFiltered] = useState<Channel[]>([]);
    const [channelFilter, setChannelFilter] = useState("");
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [channelModalShow, setChannelModalShow] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState<Channel>();

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            let res = await getChannels();
    
            if (res.success && res.body) {
                setChannels(res.body.channels);
                if (channelFilter.length > 0) {
                    performFilter(channelFilter, res.body.channels);
                }
                else {
                    setChannelsFiltered(res.body.channels);
                }
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
            setLoading(false);
        },
        [channelFilter, appContext]
    );
    
    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const confirmDelete = (message: string, id: number) => {
        setConfirmModalMessage(`Are you sure you want to delete Channel ${message}?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    }

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);
        const res = await deleteChannel(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const editChannel = (channel: Channel) => {
        setSelectedChannel(channel);
        setChannelModalShow(true);
    }

    const addChannel = () => {
        setSelectedChannel(undefined);
        setChannelModalShow(true);
    }

    const filterOnChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setChannelFilter(e.currentTarget.value);
        performFilter(e.currentTarget.value, channels);
    }

    const performFilter = (val : string, list: Channel[]) => {
        const newList = list.filter(ch => {
            return ch.name.toLowerCase().includes(val.toLowerCase())
        });
        setChannelsFiltered(newList);
    }

    const moveUpChannel = async (id: number) => {
        setLoading(true);
        const res = await promoteChannel(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setLoading(false);
    }

    const moveDownChannel = async (id: number) => {
        setLoading(true);
        const res = await demoteChannel(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setLoading(false);
    }

    return (
        <div className="channels-tab-container p-3">
            <ConfirmModal
                show={confirmModalShow}
                message={confirmModalMessage}
                callback={confirmModalCallback}
                handleClose={() => setConfirmModalShow(false)}
                loading={confirmModalLoading}
            />

            <ChannelModal
                show={channelModalShow}
                handleClose={() => { setChannelModalShow(false); setSelectedChannel(undefined) }}
                channel={selectedChannel}
                reload={apiLoad}
            />
            
            {loading ?
            <div className="spinner-container">
                <Spinner size={60}/>
            </div>
            :
            <>
            <div className="channels-tab-top-container mb-2">
                <Form.Control 
                    value={channelFilter}
                    onChange={filterOnChange}
                    type="text" 
                    placeholder="Filter for Channel..." 
                />
                <Button 
                    variant="outline-primary"
                    onClick={addChannel}
                    className="channels-tab-add-button"
                >
                    <PlusCircle className="add-button-icon"/> Channel
                </Button>
            </div>
            
            <div className="channels-tab-table-container">
                <Table className="channels-tab-table" bordered size="sm">
                    <thead>
                        <tr>
                            <th>Order</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {channelsFiltered.length > 0 ?
                        channelsFiltered.map((ch, i) => 
                        <tr key={`channel-${i}`}>
                            <td>{ch.order}</td>
                            <td>{ch.name}</td>
                            <td>{ch.description}</td>
                            <td className="channels-tab-action-container">
                                {ch.order !== 1 &&
                                <div 
                                    className="channels-tab-action-icon"
                                    onClick={() => moveUpChannel(ch.id)}
                                    title="move up in order"
                                >
                                    <ArrowUpCircle/>
                                </div>
                                }
                                {ch.order !== channels.length &&
                                <div 
                                    className="channels-tab-action-icon"
                                    onClick={() => moveDownChannel(ch.id)}
                                    title="move down in order"
                                >
                                    <ArrowDownCircle/>
                                </div>
                                }
                                <div 
                                    className="channels-tab-action-icon"
                                    onClick={() => editChannel(ch)}
                                    title="edit Channel details"
                                >
                                    <PencilSquare/>
                                </div>
                                <div 
                                    className="channels-tab-action-icon"
                                    onClick={() => confirmDelete(ch.name, ch.id)}
                                    title="delete Channel"
                                >
                                    <Trash/>
                                </div>
                            </td>
                        </tr>
                        )
                        :
                        <tr>
                            <td colSpan={5}>No Channels found...</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
            </>
            }
        </div>
    );
}