import { useState, useEffect, useRef } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import useWindowSize from '../../services/utils/useWindowSize';
import "./OverflownText.test";

interface OverflowProps {
    text: string;
    className?: string;
    link?: string;
    additionalText?: string;
}

export default function OverflownText({ text, className, link, additionalText }: OverflowProps) {
    const divRef = useRef<HTMLDivElement>(null);
    const anchorRef = useRef<HTMLAnchorElement>(null);
    const { width } = useWindowSize();
    const [hasOverflow, setHasOverflow] = useState(false);

    const renderTooltip = (text?: string) => (
        <div className="tooltip-text-viewer">
            {text && text}
        </div>
    );

    useEffect(() => {
        if (divRef.current && divRef?.current.offsetWidth < divRef?.current.scrollWidth) {
            setHasOverflow(true);
        }
        else if (anchorRef.current && anchorRef?.current.offsetWidth < anchorRef?.current.scrollWidth) {
            setHasOverflow(true);
        }
        else setHasOverflow(false);

    }, [anchorRef, divRef, width]);

    if (anchorRef && link) {
        if (hasOverflow) {
            return <Link ref={anchorRef} to={link} className={`has-ellipsis ${className}`} >
                <OverlayTrigger placement={"bottom"} overlay={renderTooltip(text)} transition={true} >
                    <span>{additionalText ? additionalText + text : text}</span>
                </OverlayTrigger>
            </ Link>
        }
        else {
            return <Link ref={anchorRef} to={link} className={`has-ellipsis ${className}`}>
                <span>{additionalText ? additionalText + text : text}</span>
            </Link>
        }
    }
    else {
        if (hasOverflow) {
            return <OverlayTrigger placement={"bottom"} overlay={renderTooltip(text)} transition={true} >
                <span ref={divRef} className={`has-ellipsis ${className}`}>{text}</span>
            </OverlayTrigger>
        }
        else {
            return <span ref={divRef} className={`has-ellipsis ${className}`}>{text}</span>
        }
    }

};