// package imports
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Card, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import { ArrowDownCircle, ArrowUpCircle, PencilSquare, PlusCircle, ThreeDots, Trash, ClockHistory, Recycle } from "react-bootstrap-icons";
import { useLocation, useParams } from "react-router-dom";
import TagManager from 'react-gtm-module';

// project imports
import './OfferFamilies.scss';
import { ApiResponse, OfferFamily, Product, ProductWithOfferFamilyId } from "../../common/interfaces";
import Banner, { BannerProps } from "../../components/Banner/Banner";
import getOfferFamilies, { GetOfferFamiliesResponse } from "../../services/api/offerFamilies/getOfferFamilies";
import { AppContext } from '../../services/context/contextProvider';
import Spinner from '../../components/Spinner/Spinner';
import { EntityType, MessageStatus } from '../../common/appConstants';
import deleteOfferFamily from '../../services/api/offerFamilies/deleteOfferFamily';
import deleteProduct from '../../services/api/products/deleteProduct';
import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import promoteOfferFamily from '../../services/api/offerFamilies/promoteOfferFamily';
import demoteOfferFamily from '../../services/api/offerFamilies/demoteOfferFamily';
import promoteProduct from '../../services/api/products/promoteProduct';
import demoteProduct from '../../services/api/products/demoteProduct';
import OfferFamilyModal from '../../components/OfferFamilyModal/OfferFamilyModal';
import ProductModal from '../../components/ProductModal/ProductModal';
import BusinessLinesIcon from "../../assets/images/icons/BusinessLinesIcon";
import ProductHistoryModal from "../../components/ProductHistoryModal/ProductHistoryModal";
import OfferFamilyHistoryModal from "../../components/OfferFamilyHistoryModal/OfferFamilyHistoryModal";
import OverflownText from "../../components/OverflownText/OverflownText";
import DeletedEntitiesModal from "../../components/DeletedEntitiesModal/DeletedEntitiesModal";
import { ISidebar } from "../../components/Sidebar/Sidebar";
import getBusinessLines from "../../services/api/businessLines/getBusinessLines";

type OfferFamiliesParams = {
    businessLineLink: string
}

const tagManagerArgs = {
    dataLayer: {
        page: 'OfferFamilies'
    },
    dataLayerName: 'PageDataLayer'
}

export default function OfferFamilies() {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'OfferFamilies Views',
            value: 'OfferFamilies',
            page: {
                location: pageLocation,
                title: "OfferFamilies"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "OfferFamilies",
                value: "OfferFamilies",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    let { businessLineLink } = useParams<OfferFamiliesParams>();

    const [bannerProps, setBannerProps] = useState<BannerProps>({});
    const [details, setDetails] = useState<GetOfferFamiliesResponse>();
    const [loading, setLoading] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [offerFamilyModalShow, setOfferFamilyModalShow] = useState(false);
    const [ofHistoryModalShow, setOfHistoryModalShow] = useState(false);
    const [selectedOfferFamily, setSelectedOfferFamily] = useState<OfferFamily>();
    const [productModalShow, setProductModalShow] = useState(false);
    const [prodHistoryModalShow, setProdHistoryModalShow] = useState(false);
    const [deletedEntitiesModalShow, setDeletedEntitiesModalShow] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductWithOfferFamilyId>();
    const [selectedHistoryProduct, setSelectedHistoryProduct] = useState<ProductWithOfferFamilyId>();
    const [selectedHistoryOfferFamily, setSelectedHistoryOfferFamily] = useState<OfferFamily>();

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            const sidebar: ISidebar = {
                options: {
                    businessLines: { list: [] }
                }
            }

            let blRes = await getBusinessLines();
            if (blRes.success && blRes.body) {
                sidebar.options.businessLines = {
                    list: blRes.body.businessLines.map(bl => {
                        return {
                            link: bl.link,
                            name: bl.name,
                            isActive: bl.link === businessLineLink
                        };
                    })
                };
            }
            else if (blRes.message) {
                appContext?.setMessage({ show: true, message: blRes.message, status: blRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            let ofRes = await getOfferFamilies(businessLineLink);
            if (ofRes.success && ofRes.body) {
                sidebar.options.selectedBusinessLine = {
                    link: ofRes.body.businessLine.link,
                    name: ofRes.body.businessLine.name,
                    offerFamilies: ofRes.body.businessLine.offerFamilies.map(of => {
                        return {
                            link: of.link,
                            name: of.name
                        };
                    }),
                    offerFamiliesBoardActive: true
                };

                let bannerProps: BannerProps = {
                    titleIcon: <BusinessLinesIcon />,
                    title1: ofRes.body.businessLine.name,
                    title2: "Offer Family Board",
                    breadcrumbs: [{ link: "/", displayName: "Home" }, { link: "/business-lines", displayName: "Business Lines" }],
                    breadcrumbActive: ofRes.body.businessLine.name,
                    subtitle1: {
                        field: "Head of Product",
                        value: ofRes.body.businessLine.headOfProduct,
                    }
                }
                setBannerProps(bannerProps);

                setDetails(ofRes.body);
            }
            else if (ofRes.message) {
                appContext?.setMessage({ show: true, message: ofRes.message, status: ofRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            appContext?.setSidebar(sidebar);
            setLoading(false);
        },
        [businessLineLink, appContext]
    );

    useEffect(() => {
        setBannerProps({});
        setDetails(undefined);
        apiLoad();
    }, [businessLineLink]); // eslint-disable-line react-hooks/exhaustive-deps

    const confirmDelete = (message: string, type: string, id: number) => {
        document.body.click(); // to hide Popover
        setConfirmModalMessage(`Are you sure you want to delete ${message}?`);
        setConfirmModalCallback(() => () => performDelete(type, id));
        setConfirmModalShow(true);
    };

    const performDelete = async (type: string, id: number) => {
        setConfirmModalLoading(true);

        let res: ApiResponse<null> = { success: false, message: "Nothing assigned to be deleted!" };
        if (type === EntityType.offerFamily) {
            res = await deleteOfferFamily(id);
        }
        else if (type === EntityType.product) {
            res = await deleteProduct(id);
        }

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const editOfferFamily = (of: OfferFamily) => {
        document.body.click(); // to hide Popover
        setSelectedOfferFamily(of);
        setOfferFamilyModalShow(true);
    }

    const addOfferFamily = () => {
        setSelectedOfferFamily(undefined);
        setOfferFamilyModalShow(true);
    }

    const viewOfHistory = (of: OfferFamily) => {
        document.body.click();
        setSelectedHistoryOfferFamily(of);
        setOfHistoryModalShow(true);
    }

    const editProduct = (pr: Product, ofId: number) => {
        document.body.click(); // to hide Popover
        setSelectedProduct({
            offerFamilyId: ofId,
            id: pr.id,
            name: pr.name,
            description: pr.description,
            productManager: pr.productManager,
            lifecycleStage: pr.lifecycleStage ? pr.lifecycleStage : "",
            countries: pr.countries
        });
        setProductModalShow(true);
    }

    const addProduct = () => {
        setSelectedProduct(undefined);
        setProductModalShow(true);
    }

    const viewProdHistory = (pr: Product, of: OfferFamily) => {
        document.body.click(); // to hide Popover
        setSelectedHistoryProduct({
            offerFamilyId: of.id,
            id: pr.id,
            name: pr.name,
            description: pr.description,
            productManager: pr.productManager,
            lifecycleStage: pr.lifecycleStage ? pr.lifecycleStage : "",
            countries: pr.countries
        });
        setProdHistoryModalShow(true)
    }

    const displayDeletedEntities = () => {
        setSelectedOfferFamily(undefined);
        setSelectedProduct(undefined);
        setDeletedEntitiesModalShow(true);
    }

    const moveEntity = async (type: string, id: number, moveUp: boolean) => {
        setLoading(true);
        document.body.click(); // to hide Popover
        let res: ApiResponse<null> = { success: false, message: "Nothing assigned to be moved!" };

        if (type === EntityType.offerFamily && moveUp) {
            res = await promoteOfferFamily(id);
        }
        else if (type === EntityType.offerFamily) {
            res = await demoteOfferFamily(id);
        }
        else if (type === EntityType.product && moveUp) {
            res = await promoteProduct(id);
        }
        else if (type === EntityType.product) {
            res = await demoteProduct(id);
        }

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setLoading(false);
    }

    return (
        <div className="content-container">
            {appContext?.auth.isEditingActive &&
                <>
                    <ConfirmModal
                        show={confirmModalShow}
                        message={confirmModalMessage}
                        callback={confirmModalCallback}
                        handleClose={() => { setConfirmModalShow(false) }}
                        loading={confirmModalLoading}
                    />

                    <OfferFamilyModal
                        show={offerFamilyModalShow}
                        handleClose={() => { setOfferFamilyModalShow(false); setSelectedOfferFamily(undefined) }}
                        offerFamily={selectedOfferFamily}
                        businessLineId={details ? details.businessLine.id : 0}
                        businessLineName={details ? details.businessLine.name : ""}
                        reload={apiLoad}
                    />

                    <OfferFamilyHistoryModal
                        show={ofHistoryModalShow}
                        handleClose={() => { setOfHistoryModalShow(false); setSelectedHistoryOfferFamily(undefined) }}
                        offerFamily={selectedHistoryOfferFamily}
                    />

                    <ProductModal
                        show={productModalShow}
                        handleClose={() => { setProductModalShow(false); setSelectedProduct(undefined) }}
                        product={selectedProduct}
                        offerFamilies={details ? details.businessLine.offerFamilies : []}
                        businessLineId={ details ? details.businessLine.id : 0 }
                        businessLineName={details ? details.businessLine.name : ""}
                        reload={apiLoad}
                    />

                    <ProductHistoryModal
                        show={prodHistoryModalShow}
                        handleClose={() => { setProdHistoryModalShow(false); setSelectedHistoryProduct(undefined) }}
                        product={selectedHistoryProduct}
                    />
                    {appContext?.auth.isAdmin &&
                        <DeletedEntitiesModal
                            show={deletedEntitiesModalShow}
                            businessLineId={details ? details.businessLine.id : 0}
                            handleClose={() => setDeletedEntitiesModalShow(false)}
                            reload={apiLoad}
                        />
                    }
                </>
            }

            <Banner
                titleIcon={bannerProps.titleIcon}
                title1={bannerProps.title1}
                title2={bannerProps.title2}
                breadcrumbs={bannerProps.breadcrumbs}
                breadcrumbActive={bannerProps.breadcrumbActive}
                subtitle1={bannerProps.subtitle1}
            />

            <Container className="screen-container p-3">
                {loading ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        {appContext?.auth.isEditingActive &&
                            <Row className="pb-1">
                                <Col className="offer-families-button-container" xs={12}>
                                    {appContext?.auth.isAdmin &&
                                        <Button
                                            className="offer-families-button-spacing"
                                            variant="outline-primary"
                                            onClick={displayDeletedEntities}
                                        >
                                            <Recycle className="add-button-icon" /> Entities
                                        </Button>
                                    }
                                    <Button
                                        className="offer-families-button-spacing"
                                        variant="outline-primary"
                                        onClick={addOfferFamily}
                                    >
                                        <PlusCircle className="add-button-icon" /> Offer Family
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        onClick={addProduct}
                                    >
                                        <PlusCircle className="add-button-icon" /> Product
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <Row xs={3} className="offer-families-card-row g-1 mt-1 pb-3">
                            {details && details.businessLine.offerFamilies.map((of, i) =>
                                <Col key={`of-${i}`} className="offer-families-card-col mt-1">
                                    <Card
                                        className="offer-families-card "
                                    >
                                        <Card.Body className="offer-families-card-body">
                                            <div className="offer-families-card-header">
                                                <OverflownText className={`offer-families-of-link`} text={of.name} link={`/business-lines/${businessLineLink}/${of.link}/vision-board`} />
                                                {appContext?.auth.isEditingActive &&
                                                    <OverlayTrigger
                                                        trigger="click"
                                                        placement="right"
                                                        rootClose
                                                        overlay={
                                                            <Popover>
                                                                <PopoverBody className="offer-families-action-container">
                                                                    {i !== 0 &&
                                                                        <div
                                                                            className="offer-families-action-icon"
                                                                            onClick={() => moveEntity(EntityType.offerFamily, of.id, true)}
                                                                            title="move up in order"
                                                                        >
                                                                            <ArrowUpCircle />
                                                                        </div>
                                                                    }
                                                                    {i + 1 !== details.businessLine.offerFamilies.length &&
                                                                        <div
                                                                            className="offer-families-action-icon"
                                                                            onClick={() => moveEntity(EntityType.offerFamily, of.id, false)}
                                                                            title="move down in order"
                                                                        >
                                                                            <ArrowDownCircle />
                                                                        </div>
                                                                    }
                                                                    <div
                                                                        className="offer-families-action-icon"
                                                                        onClick={() => editOfferFamily(of)}
                                                                        title="edit Offer Family details"
                                                                    >
                                                                        <PencilSquare />
                                                                    </div>
                                                                    <div
                                                                        className="offer-families-action-icon"
                                                                        onClick={() => viewOfHistory(of)}
                                                                        title="display history"
                                                                    >
                                                                        <ClockHistory />
                                                                    </div>
                                                                    <div
                                                                        className="offer-families-action-icon"
                                                                        onClick={() => confirmDelete(of.name, EntityType.offerFamily, of.id)}
                                                                        title="delete Offer Family"
                                                                    >
                                                                        <Trash />
                                                                    </div>
                                                                </PopoverBody>
                                                            </Popover>
                                                        }
                                                    >
                                                        <ThreeDots className="offer-families-action-icon" />
                                                    </OverlayTrigger>
                                                }
                                            </div>
                                            <div>
                                                {of.products && of.products.map((prod, j) =>
                                                    <div key={`prod-${i}-${j}`} className="offer-families-product">
                                                        <OverflownText className={`offer-families-product-link`} text={prod.name} link={`/business-lines/${businessLineLink}/${of.link}/${prod.link}/vision-board`} />
                                                        {appContext?.auth.isEditingActive &&
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                placement="right"
                                                                rootClose
                                                                overlay={
                                                                    <Popover>
                                                                        <PopoverBody className="offer-families-action-container">
                                                                            {j !== 0 &&
                                                                                <div
                                                                                    className="offer-families-action-icon"
                                                                                    onClick={() => moveEntity(EntityType.product, prod.id, true)}
                                                                                    title="move up in order"
                                                                                >
                                                                                    <ArrowUpCircle />
                                                                                </div>
                                                                            }
                                                                            {j + 1 !== of.products.length &&
                                                                                <div
                                                                                    className="offer-families-action-icon"
                                                                                    onClick={() => moveEntity(EntityType.product, prod.id, false)}
                                                                                    title="move down in order"
                                                                                >
                                                                                    <ArrowDownCircle />
                                                                                </div>
                                                                            }
                                                                            <div
                                                                                className="offer-families-action-icon"
                                                                                onClick={() => editProduct(prod, of.id)}
                                                                                title="edit Product details"
                                                                            >
                                                                                <PencilSquare />
                                                                            </div>
                                                                            <div
                                                                                className="offer-families-action-icon"
                                                                                onClick={() => viewProdHistory(prod, of)}
                                                                                title="display history"
                                                                            >
                                                                                <ClockHistory />
                                                                            </div>
                                                                            <div
                                                                                className="offer-families-action-icon"
                                                                                onClick={() => confirmDelete(prod.name, EntityType.product, prod.id)}
                                                                                title="delete Product"
                                                                            >
                                                                                <Trash />
                                                                            </div>
                                                                        </PopoverBody>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <ThreeDots className="offer-families-action-icon" />
                                                            </OverlayTrigger>
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </>
                }
            </Container>
        </div>
    );
}