export abstract class Config {
    static readonly refreshUserMs = 150000;
    static readonly tokenExpiryMs = 300000;
    static readonly feedbackEmail = "PLMTool@computershare.com";
}

export abstract class MessageStatus {
    static readonly info = "info";
    static readonly warning = "warning";
    static readonly error = "error";
}

export abstract class Store {
    static readonly userDetails = "_plm.userDetails";
    static readonly accessToken = "_plm.accessToken";
    static readonly editorState = "_plm.editorState";
    static readonly returnUrl = "_plm.returnUrl";
}

export abstract class Role {
    static readonly admin = "Admin";
    static readonly editor = "Editor";
    static readonly standard = "Standard";
    static readonly rootAdmin = "RootAd";
}

export abstract class AppError {
    static readonly responseError = "The server failed to respond. Please try again, or contact an administrator if the problem persists.";
    static readonly serverError = "An internal server error has occured. Please try again, or contact an administrator if the problem persists.";
    static readonly validationError = "Not all fields are valid.";
    static readonly unknownError = "An unknown error has occurred, please try again.";
}

export abstract class EntityType {
    static readonly businessLine = "BusinessLine";
    static readonly offerFamily = "OfferFamily";
    static readonly product = "Product";
    static readonly businessLineChannel = "BusinessLineChannel";
    static readonly brandedChannel = "BrandedChannel";
}

export abstract class LifecycleStage {
    static readonly introduction = "Introduction";
    static readonly growth = "Growth";
    static readonly maturity = "Maturity";
    static readonly decline = "Decline";
}

export abstract class RoadMapConstants {
    static readonly itemType = "ITEM";
    static readonly candidates = "candidates";
    static readonly roadmap = "roadmap";
    static readonly completed = "completed";
    static readonly now = "now";
    static readonly later = "later";
    static readonly next = "next";
    static readonly buttonTypes = ["completed", "roadmap", "candidates"];
    static readonly defaultActiveButton = "roadmap";
    static readonly tabs = { candidates: ["candidates"], roadmap: ["now", "next", "later"], completed: ["completed"] };
    static readonly states = [
        {
            id: 100, status: "candidates", icon: "✨", color: "#16666F"
        },
        {
            id: 101, status: "now", icon: "⭕️", color: "#53284F"
        },
        {
            id: 102, status: "next", icon: "🔆️", color: "#93186C"
        },
        {
            id: 103, status: "later", icon: "🗯️", color: "#D7A2C6"
        },
        {
            id: 104, status: "completed", icon: "✔️", color: "#5E7B00"
        }
    ];
    static readonly jobStory = "Enter customer problem/pain point/job-to-be-done that if addressed would result in desired outcomes. Job Story format: \nWhen ____ <Situation/Pain Point>, \nI want to ____ <Motivation>, \nso I can ____ <Outcome>";
    static readonly lastThreeMonths = "lastThreeMonths";
    static readonly threeToSixMonthsAgo = "threeToSixMonthsAgo";
    static readonly moreThanSixMonthsAgo: "moreThanSixMonthsAgo";
}

export abstract class CustomerType {
    static readonly b2b = "B2B";
    static readonly b2b2c = "B2B2C";
    static readonly b2c = "B2C";
    static readonly thirdParty = "Third Party";
}