import { useState, useEffect, useContext, useCallback } from "react";
import { DateTime } from "luxon";
import { Row, Col } from "react-bootstrap";

import { RoadMapConstants } from "../../../common/appConstants";
import { GenericEntity } from "../../../common/interfaces";
import getRoadmap from "../../../services/api/roadMap/getRoadmap";
import { AppContext } from "../../../services/context/contextProvider";

type LastUpdatedContainerProps = {
    activeButton: string
    entity: GenericEntity
}

export default function LastUpdatedContainer({ activeButton, entity }: LastUpdatedContainerProps) {
    const appContext = useContext(AppContext);
    const [date, setDate] = useState("");

    const roadmapApiLoad = useCallback(async () => {
        if (entity.id > 0) {
            let response = await getRoadmap(entity.type, entity.id);
            if (response.success && response.body?.lastUpdatedAt) {
                setDate(response.body.lastUpdatedAt);
            }
            appContext?.setNewDate(false);
        }
    }, [entity, appContext])

    useEffect(() => {
        roadmapApiLoad();
    }, [entity, appContext?.newDate])

    return (activeButton !== RoadMapConstants.candidates && activeButton !== RoadMapConstants.completed) ?
        <Row className="dnd-fade-in-animation">
            <Col className="vision-board-footer" xs={12}>
                <div className="vision-board-footer-head">Last updated:</div>
                <div className="vision-board-footer-data">{date ?
                    DateTime.fromISO(date, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED) : '-'}
                </div >
            </Col>
        </Row>
        :
        <Row className="dnd-fade-out-animation">
            <Col className="vision-board-footer" xs={12}>
                <div className="vision-board-footer-head">Last updated:</div>
                <div className="vision-board-footer-data">{date ?
                    DateTime.fromISO(date, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED) : '-'}</div >
            </Col>
        </Row>
}