import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ExclamationCircle, ListColumnsReverse } from "react-bootstrap-icons";
import { AppError } from "../../common/appConstants";
import { ApiResponse, ArtefactTemplate } from "../../common/interfaces";
import putArtefactTemplate from "../../services/api/artefactTemplates/putArtefactTemplate";
import Spinner from "../Spinner/Spinner";
import './TemplateModal.scss';

export interface TemplateModalProps {
    show: boolean;
    handleClose: () => void;
    template?: ArtefactTemplate;
    reload: () => Promise<void>;
};

type TemplateForm = {
    valid: boolean;
    validationError: string;
    name: string;
    nameError: string;
    fieldHead1: string;
    fieldHead1Error: string;
    fieldDef1: string;
    fieldDef1Error: string;
    fieldHead2: string;
    fieldHead2Error: string;
    fieldDef2: string;
    fieldDef2Error: string;
    fieldHead3: string;
    fieldHead3Error: string;
    fieldDef3: string;
    fieldDef3Error: string;
    fieldHead4: string;
    fieldHead4Error: string;
    fieldDef4: string;
    fieldDef4Error: string;
    fieldHead5: string;
    fieldHead5Error: string;
    fieldDef5: string;
    fieldDef5Error: string;
    fieldHead6: string;
    fieldHead6Error: string;
    fieldDef6: string;
    fieldDef6Error: string;
    fieldHead7: string;
    fieldHead7Error: string;
    fieldDef7: string;
    fieldDef7Error: string;
    fieldHead8: string;
    fieldHead8Error: string;
    fieldDef8: string;
    fieldDef8Error: string;
    fieldHead9: string;
    fieldHead9Error: string;
    fieldDef9: string;
    fieldDef9Error: string;
}

const blankTemplate : TemplateForm = { valid: true, validationError: "", name: "", nameError: "", fieldHead1: "", fieldHead1Error: "", fieldDef1: "", fieldDef1Error: "", fieldHead2: "", fieldHead2Error: "", fieldDef2: "", fieldDef2Error: "", fieldHead3: "", fieldHead3Error: "", fieldDef3: "", fieldDef3Error: "", fieldHead4: "", fieldHead4Error: "", fieldDef4: "", fieldDef4Error: "", fieldHead5: "", fieldHead5Error: "", fieldDef5: "", fieldDef5Error: "", fieldHead6: "", fieldHead6Error: "", fieldDef6: "", fieldDef6Error: "", fieldHead7: "", fieldHead7Error: "", fieldDef7: "", fieldDef7Error: "", fieldHead8: "", fieldHead8Error: "", fieldDef8: "", fieldDef8Error: "", fieldHead9: "", fieldHead9Error: "", fieldDef9: "", fieldDef9Error: "" };

export default function TemplateModal({ show, handleClose, template, reload } : TemplateModalProps) {
    const [form, setForm] = useState(blankTemplate);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (template) {
            setForm({
                ...form,
                name: template.name,
                fieldHead1: template.fieldHead1 ? template.fieldHead1 : "",
                fieldDef1: template.fieldDef1 ? template.fieldDef1 : "",
                fieldHead2: template.fieldHead2 ? template.fieldHead2 : "",
                fieldDef2: template.fieldDef2 ? template.fieldDef2 : "",
                fieldHead3: template.fieldHead3 ? template.fieldHead3 : "",
                fieldDef3: template.fieldDef3 ? template.fieldDef3 : "",
                fieldHead4: template.fieldHead4 ? template.fieldHead4 : "",
                fieldDef4: template.fieldDef4 ? template.fieldDef4 : "",
                fieldHead5: template.fieldHead5 ? template.fieldHead5 : "",
                fieldDef5: template.fieldDef5 ? template.fieldDef5 : "",
                fieldHead6: template.fieldHead6 ? template.fieldHead6 : "",
                fieldDef6: template.fieldDef6 ? template.fieldDef6 : "",
                fieldHead7: template.fieldHead7 ? template.fieldHead7 : "",
                fieldDef7: template.fieldDef7 ? template.fieldDef7 : "",
                fieldHead8: template.fieldHead8 ? template.fieldHead8 : "",
                fieldDef8: template.fieldDef8 ? template.fieldDef8 : "",
                fieldHead9: template.fieldHead9 ? template.fieldHead9 : "",
                fieldDef9: template.fieldDef9 ? template.fieldDef9 : ""
            });
        }
        else {
            setForm(blankTemplate);
        }
    }, [template, handleClose]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };
        
        if (form.name.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = "Template name must be provided."
        }
        else if (form.name.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = `Template name must be no more than 100 characters. [currently ${form.name.length} characters]`
        }

        if (form.fieldHead1.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead1Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead1.length} characters]`
        }
        if (form.fieldDef1.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef1Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef1.length} characters]`
        }

        if (form.fieldHead2.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead2Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead2.length} characters]`
        }
        if (form.fieldDef2.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef2Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef2.length} characters]`
        }

        if (form.fieldHead3.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead3Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead3.length} characters]`
        }
        if (form.fieldDef3.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef3Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef3.length} characters]`
        }

        if (form.fieldHead4.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead4Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead4.length} characters]`
        }
        if (form.fieldDef4.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef4Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef4.length} characters]`
        }

        if (form.fieldHead5.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead5Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead5.length} characters]`
        }
        if (form.fieldDef5.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef5Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef5.length} characters]`
        }

        if (form.fieldHead6.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead6Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead6.length} characters]`
        }
        if (form.fieldDef6Error.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef6Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef6.length} characters]`
        }

        if (form.fieldHead7.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead7Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead7.length} characters]`
        }
        if (form.fieldDef7Error.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef7Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef7.length} characters]`
        }

        if (form.fieldHead8.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead8Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead8.length} characters]`
        }
        if (form.fieldDef8Error.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef8Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef8.length} characters]`
        }

        if (form.fieldHead9.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldHead9Error = `Field header must be no more than 50 characters. [currently ${form.fieldHead9.length} characters]`
        }
        if (form.fieldDef9.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.fieldDef9Error = `Field definition must be no more than 250 characters. [currently ${form.fieldDef9.length} characters]`
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> = {
                success: false,
                message: "No template id is present, please try again."
            };

            if (template?.id) {
                res = await putArtefactTemplate(template.id, {
                    name: form.name,
                    fieldHead1: form.fieldHead1,
                    fieldDef1: form.fieldDef1,
                    fieldHead2: form.fieldHead2,
                    fieldDef2: form.fieldDef2,
                    fieldHead3: form.fieldHead3,
                    fieldDef3: form.fieldDef3,
                    fieldHead4: form.fieldHead4,
                    fieldDef4: form.fieldDef4,
                    fieldHead5: form.fieldHead5,
                    fieldDef5: form.fieldDef5,
                    fieldHead6: form.fieldHead6,
                    fieldDef6: form.fieldDef6,
                    fieldHead7: form.fieldHead7,
                    fieldDef7: form.fieldDef7,
                    fieldHead8: form.fieldHead8,
                    fieldDef8: form.fieldDef8,
                    fieldHead9: form.fieldHead9,
                    fieldDef9: form.fieldDef9
                });
            }

            if (res && res.success) {
                formUpdate = blankTemplate;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            nameError: "",
            fieldHead1Error: "",
            fieldDef1Error: "",
            fieldHead2Error: "",
            fieldDef2Error: "",
            fieldHead3Error: "",
            fieldDef3Error: "",
            fieldHead4Error: "",
            fieldDef4Error: "",
            fieldHead5Error: "",
            fieldDef5Error: "",
            fieldHead6Error: "",
            fieldDef6Error: "",
            fieldHead7Error: "",
            fieldDef7Error: "",
            fieldHead8Error: "",
            fieldDef8Error: "",
            fieldHead9Error: "",
            fieldDef9Error: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankTemplate); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="template-modal-header">
                <ListColumnsReverse className="template-modal-icon"/>
                <div className="template-modal-title">
                    {`Edit Template details for "${template?.name}"`}
                </div>
            </Modal.Header>
            <Modal.Body className="template-modal-body">
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>For template</Form.Label>
                            <Form.Control
                                value={template?.forArtefact}
                                disabled={true}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>For entity</Form.Label>
                            <Form.Control
                                value={template?.forEntity}
                                disabled={true}
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <Form>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter template name"
                            value={form.name}
                            name="name"
                            onChange={handleChange}
                            isInvalid={form.nameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.nameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="formGroupHead1">
                        <Form.Label>Field 1 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 1 header"
                            value={form.fieldHead1}
                            name="fieldHead1"
                            onChange={handleChange}
                            isInvalid={form.fieldHead1Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead1Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef1">
                        <Form.Label>Field 1 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 1 definition"
                            value={form.fieldDef1}
                            name="fieldDef1"
                            onChange={handleChange}
                            isInvalid={form.fieldDef1Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef1Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead2">
                        <Form.Label>Field 2 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 2 header"
                            value={form.fieldHead2}
                            name="fieldHead2"
                            onChange={handleChange}
                            isInvalid={form.fieldHead2Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead2Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef2">
                        <Form.Label>Field 2 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 2 definition"
                            value={form.fieldDef2}
                            name="fieldDef2"
                            onChange={handleChange}
                            isInvalid={form.fieldDef2Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef2Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead3">
                        <Form.Label>Field 3 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 3 header"
                            value={form.fieldHead3}
                            name="fieldHead3"
                            onChange={handleChange}
                            isInvalid={form.fieldHead3Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead3Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef3">
                        <Form.Label>Field 3 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 3 definition"
                            value={form.fieldDef3}
                            name="fieldDef3"
                            onChange={handleChange}
                            isInvalid={form.fieldDef3Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef3Error}
                        </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="formGroupHead4">
                        <Form.Label>Field 4 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 4 header"
                            value={form.fieldHead4}
                            name="fieldHead4"
                            onChange={handleChange}
                            isInvalid={form.fieldHead4Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead4Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef4">
                        <Form.Label>Field 4 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 4 definition"
                            value={form.fieldDef4}
                            name="fieldDef4"
                            onChange={handleChange}
                            isInvalid={form.fieldDef4Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef4Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead5">
                        <Form.Label>Field 5 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 5 header"
                            value={form.fieldHead5}
                            name="fieldHead5"
                            onChange={handleChange}
                            isInvalid={form.fieldHead5Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead5Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef5">
                        <Form.Label>Field 5 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 5 definition"
                            value={form.fieldDef5}
                            name="fieldDef5"
                            onChange={handleChange}
                            isInvalid={form.fieldDef5Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef5Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead6">
                        <Form.Label>Field 6 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 6 header"
                            value={form.fieldHead6}
                            name="fieldHead6"
                            onChange={handleChange}
                            isInvalid={form.fieldHead6Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead6Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef6">
                        <Form.Label>Field 6 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 6 definition"
                            value={form.fieldDef6}
                            name="fieldDef6"
                            onChange={handleChange}
                            isInvalid={form.fieldDef6Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef6Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead7">
                        <Form.Label>Field 7 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 7 header"
                            value={form.fieldHead7}
                            name="fieldHead7"
                            onChange={handleChange}
                            isInvalid={form.fieldHead7Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead7Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef7">
                        <Form.Label>Field 7 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 7 definition"
                            value={form.fieldDef7}
                            name="fieldDef7"
                            onChange={handleChange}
                            isInvalid={form.fieldDef7Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef7Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead8">
                        <Form.Label>Field 8 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 8 header"
                            value={form.fieldHead8}
                            name="fieldHead8"
                            onChange={handleChange}
                            isInvalid={form.fieldHead8Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead8Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef8">
                        <Form.Label>Field 8 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 8 definition"
                            value={form.fieldDef8}
                            name="fieldDef8"
                            onChange={handleChange}
                            isInvalid={form.fieldDef8Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef8Error}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formGroupHead9">
                        <Form.Label>Field 9 header</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter field 9 header"
                            value={form.fieldHead9}
                            name="fieldHead9"
                            onChange={handleChange}
                            isInvalid={form.fieldHead9Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldHead9Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDef9">
                        <Form.Label>Field 9 definition</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter field 9 definition"
                            value={form.fieldDef9}
                            name="fieldDef9"
                            onChange={handleChange}
                            isInvalid={form.fieldDef9Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.fieldDef9Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="template-modal-footer">
                <div className="template-modal-error">
                    {!form.valid && <><ExclamationCircle className="template-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="template-modal-button-container">
                    <Button
                        className="template-modal-button"
                        onClick={() => { setForm(blankTemplate); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "template-modal-button-loading" : "template-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}