// package imports
import jwt, { JwtPayload } from 'jwt-decode';

// project imports
import loginReq, { LoginResponse } from '../api/auth/login';
import refreshTokenReq from '../api/auth/refreshToken';
import { Config, Role, Store } from '../../common/appConstants';

export interface AuthServiceResponse {
    loggedIn: boolean;
    id: string;
    username: string;
    isAdmin: boolean;
    isEditor: boolean;
    isRootAdmin: boolean;
    isEditingActive: boolean;
    message?: string;
    status?: string;
}

async function getCurrentUserAuth() : Promise<AuthServiceResponse> {
    console.log("Checking access token...");
    let userDetailsParsed : LoginResponse | null = null;
    let editorStateParsed : boolean = false;
    const userDetails = localStorage.getItem(Store.userDetails);
    const editorState = localStorage.getItem(Store.editorState);

    if (userDetails) {
        userDetailsParsed = JSON.parse(userDetails);

        if (editorState) {
            editorStateParsed = JSON.parse(editorState);
        }
    }
    
    if (userDetailsParsed) {
        const jwtPayload = jwt<JwtPayload>(userDetailsParsed.accessToken);
        // check for impending expiry and refresh token if required
        const exp = jwtPayload.exp ? jwtPayload.exp * 1000 : 0;
        if (exp - Date.now() < Config.tokenExpiryMs) {
            console.log("Refreshing access token...");
            return await refreshToken(userDetailsParsed.refreshToken, editorStateParsed);
        }
        else {
            console.log("Access token is still valid.")
        }

        return {
            loggedIn: true,
            id: userDetailsParsed.id,
            username: userDetailsParsed.username,
            isAdmin: userDetailsParsed.role === Role.admin,
            isEditor: userDetailsParsed.role === Role.editor || userDetailsParsed.role === Role.admin,
            isRootAdmin: userDetailsParsed.role === Role.rootAdmin,
            isEditingActive: editorStateParsed
        }
    }
    else {
        console.log("An access token could not be found. Please log in again.")
        return {
            loggedIn: false,
            id: "",
            username: "",
            isAdmin: false,
            isEditor: false,
            isRootAdmin: false,
            isEditingActive: false,
            message: "No user is logged in."
        }
    }
}

async function loginAuth() : Promise<AuthServiceResponse> {
    const res = await loginReq();

    if (res.success && res.body) {
        localStorage.setItem(Store.userDetails, JSON.stringify(res.body));
        localStorage.setItem(Store.accessToken, res.body.accessToken);

        return {
            loggedIn: true,
            id: res.body.id,
            username: res.body.username,
            isAdmin: res.body.role === Role.admin,
            isEditor: res.body.role === Role.editor || res.body.role === Role.admin,
            isRootAdmin: res.body.role === Role.rootAdmin,
            isEditingActive: false
        }
    }
    else {
        return {
            loggedIn: false,
            id: "",
            username: "",
            isAdmin: false,
            isEditor: false,
            isRootAdmin: false,
            isEditingActive: false,
            message: res.message,
            status: res.status
        }
    }
}

function externalAuth(authParams : LoginResponse) : AuthServiceResponse {
    localStorage.setItem(Store.userDetails, JSON.stringify(authParams));
    localStorage.setItem(Store.accessToken, authParams.accessToken);

    return {
        loggedIn: true,
        id: authParams.id,
        username: authParams.username,
        isAdmin: authParams.role === Role.admin,
        isEditor: authParams.role === Role.editor || authParams.role === Role.admin,
        isRootAdmin: authParams.role === Role.rootAdmin,
        isEditingActive: false
    }
}

function logoutAuth() : AuthServiceResponse {
    localStorage.removeItem(Store.userDetails);
    localStorage.removeItem(Store.accessToken);
    localStorage.removeItem(Store.editorState);

    return {
        loggedIn: false,
        id: "",
        username: "",
        isAdmin: false,
        isEditor: false,
        isRootAdmin: false,
        isEditingActive: false
    }
}

async function refreshToken(refreshToken : string, editorState : boolean) : Promise<AuthServiceResponse> {
    const res = await refreshTokenReq({ refreshToken: refreshToken });

    if (res.success && res.body) {
        console.log("Access token has been successfully refreshed.");
        localStorage.setItem(Store.userDetails, JSON.stringify(res.body));
        localStorage.setItem(Store.accessToken, res.body.accessToken);

        return {
            loggedIn: true,
            id: res.body.id,
            username: res.body.username,
            isAdmin: res.body.role === Role.admin,
            isEditor: res.body.role === Role.editor || res.body.role === Role.admin,
            isRootAdmin: res.body.role === Role.rootAdmin,
            isEditingActive: editorState
        }
    }
    else {
        console.log("An error occurred while refreshing the access token. Please log in again.");
        localStorage.removeItem(Store.userDetails);
        localStorage.removeItem(Store.accessToken);
        localStorage.removeItem(Store.editorState);

        return {
            loggedIn: false,
            id: "",
            username: "",
            isAdmin: false,
            isEditor: false,
            isRootAdmin: false,
            isEditingActive: false,
            message: res.message,
            status: res.status
        }
    }
}

function setEditorState(editorState : boolean) {
    if (editorState) {
        localStorage.setItem(Store.editorState, "true");
    }
    else {
        localStorage.removeItem(Store.editorState);
    }
}

export { getCurrentUserAuth, loginAuth, externalAuth, logoutAuth, setEditorState };