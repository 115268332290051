import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons';
import { Country } from '../../common/interfaces';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import Spinner from '../Spinner/Spinner';
import './CountriesTab.scss';
import getCountries from '../../services/api/countries/getCountries';
import deleteCountry from '../../services/api/countries/deleteCountry';
import CountryModal from '../CountryModal/CountryModal';
import { MessageStatus } from '../../common/appConstants';
import { AppContext } from '../../services/context/contextProvider';

export default function CountriesTab() {
    const appContext = useContext(AppContext);
    
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<Country[]>([]);
    const [countriesFiltered, setCountriesFiltered] = useState<Country[]>([]);
    const [countryFilter, setCountryFilter] = useState("");
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [countryModalShow, setCountryModalShow] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState<Country>();

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            let res = await getCountries();
    
            if (res.success && res.body) {
                setCountries(res.body.countries);
                if (countryFilter.length > 0) {
                    performFilter(countryFilter, res.body.countries);
                }
                else {
                    setCountriesFiltered(res.body.countries);
                }
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
            setLoading(false);
        },
        [countryFilter, appContext]
    );
    
    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    const confirmDelete = (message: string, id: number) => {
        setConfirmModalMessage(`Are you sure you want to delete Country ${message}?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    }

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);
        const res = await deleteCountry(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const editCountry = (country: Country) => {
        setSelectedCountry(country);
        setCountryModalShow(true);
    }

    const addCountry = () => {
        setSelectedCountry(undefined);
        setCountryModalShow(true);
    }

    const filterOnChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setCountryFilter(e.currentTarget.value);
        performFilter(e.currentTarget.value, countries);
    }

    const performFilter = (val : string, list: Country[]) => {
        const newList = list.filter(ch => {
            return ch.name.toLowerCase().includes(val.toLowerCase())
        });
        setCountriesFiltered(newList);
    }

    return (
        <div className="countries-tab-container p-3">
            <ConfirmModal
                show={confirmModalShow}
                message={confirmModalMessage}
                callback={confirmModalCallback}
                handleClose={() => setConfirmModalShow(false)}
                loading={confirmModalLoading}
            />

            <CountryModal
                show={countryModalShow}
                handleClose={() => { setCountryModalShow(false); setSelectedCountry(undefined); }}
                country={selectedCountry}
                reload={apiLoad}
            />
            
            {loading ?
            <div className="spinner-container">
                <Spinner size={60}/>
            </div>
            :
            <>
            <div className="countries-tab-top-container mb-2">
                <Form.Control 
                    value={countryFilter}
                    onChange={filterOnChange}
                    type="text" 
                    placeholder="Filter for Country..." 
                />
                <Button 
                    variant="outline-primary"
                    onClick={addCountry}
                    className="countries-tab-add-button"
                >
                    <PlusCircle className="add-button-icon"/> Country
                </Button>
            </div>
            
            <div className="countries-tab-table-container">
                <Table className="countries-tab-table" bordered size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>ISO2 Code</th>
                            <th>ISO3 Code</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {countriesFiltered.length > 0 ?
                        countriesFiltered.map((cn, i) => 
                        <tr key={`country-${i}`}>
                            <td>{cn.name}</td>
                            <td>{cn.iso2}</td>
                            <td>{cn.iso3}</td>
                            <td className="countries-tab-action-container">
                                <div 
                                    className="countries-tab-action-icon"
                                    onClick={() => editCountry(cn)}
                                    title="edit Country details"
                                >
                                    <PencilSquare/>
                                </div>
                                <div 
                                    className="countries-tab-action-icon"
                                    onClick={() => confirmDelete(cn.name, cn.id)}
                                    title="delete Country"
                                >
                                    <Trash/>
                                </div>
                            </td>
                        </tr>
                        )
                        :
                        <tr>
                            <td colSpan={5}>No Countries found...</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
            </>
            }
        </div>
    );
}