import { useState, useRef, useContext, useEffect } from "react";
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from "react-dnd";
import { ArrowDownCircle, ArrowUpCircle, ClockHistory, PencilSquare, ThreeDots, Trash } from "react-bootstrap-icons";

import { useRoadmapContext } from "../../../services/context/useRoadmapContext";
import { GenericEntity, ItemType, RoadmapCardCreationOrUpdateRequest, StatusType } from "../../../common/interfaces";
import DetailsWindow from "../DetailsWindow/DetailsWindow";
import ConfirmModal from "../../ConfirmModal/ConfirmModal";
import { MessageStatus, RoadMapConstants } from "../../../common/appConstants";
import TagIcon from "../../../assets/images/icons/TagIcon";
import { Col, OverlayTrigger, Popover, PopoverBody, Row } from "react-bootstrap";
import { AppContext } from '../../../services/context/contextProvider';
import deleteRoadmapCard from "../../../services/api/roadmapCard/deleteRoadmapCard";
import CardModal from "../CardModal/CardModal";
import putRoadmapCard from "../../../services/api/roadmapCard/putRoadmapCard";
import "./Item.scss";
import RoadmapCardHistoryModal from "../../RoadmapCardHistoryModal/RoadmapCardHistoryModal";

export interface ItemProps {
    item: ItemType;
    index: number;
    moveItem: (dragIndex: number, hoverIndex: number) => void;
    status: StatusType;
    entity: GenericEntity;
    itemsLength: number;
    maxOrderNumber: number;
}

const Item = ({ item, index, moveItem, status, entity, itemsLength, maxOrderNumber }: ItemProps) => {
    const appContext = useContext(AppContext);
    const { setReload } = useRoadmapContext();
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [historyModalShow, setRcHistoryModalShow] = useState(false);
    const [selectedHistoryCard, setSelectedHistoryCard] = useState<ItemType>();
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [showEditWindow, setShowEditWindow] = useState(false);
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setReload(false);
    }, []);

    const confirmDelete = (id: number) => {
        document.body.click(); // to hide Popover
        setConfirmModalMessage(`Are you sure you want to delete this Card?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    }

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);
        const res = await deleteRoadmapCard(id);

        if (res.success) {
            setReload(true);
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const [{ isDragging, }, drag] = useDrag({
        item: { type: RoadMapConstants.itemType, ...item, index },
        type: RoadMapConstants.itemType,
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    const [{ isOver }, drop] = useDrop({
        accept: RoadMapConstants.itemType,
        collect: (monitor: DropTargetMonitor) => ({
            isOver: monitor.isOver()
        }),
        hover(item: any, monitor) {
            if (!ref.current) { return; }

            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) { return; }

            const hoveredRect = ref.current.getBoundingClientRect();
            const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
            const mousePosition = monitor.getClientOffset() || { x: 0, y: 0 };
            const hoverClientY = mousePosition.y - hoveredRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) { return; }

            if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) { return; }

            moveItem(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    }, [moveItem]);

    const style = { borderLeft: `8px solid ${status.color}` }
    const [show, setShow] = useState(false);

    const onOpen = () => setShow(true);

    const onClose = () => {
        setShow(false);
        setShowEditWindow(false);
    }

    const moveUp = async () => {
        document.body.click(); // to hide Popover
        if (maxOrderNumber >= 0) {
            let roadmapUpdateRequest: RoadmapCardCreationOrUpdateRequest = {
                roadmapId: item.roadmapId,
                order: item.order - 1,
                title: item.title,
                jobStory: item.jobStory,
                objective: item.objective,
                state: item.state,
                tags: item.tags,
            }

            let res = await putRoadmapCard(item.id, roadmapUpdateRequest);
            if (res.success) {
                setReload(true);
            }
        }
    }

    const moveDown = async () => {
        document.body.click(); // to hide Popover
        if (maxOrderNumber >= 0 && item.order > 0) {
            let roadmapUpdateRequest: RoadmapCardCreationOrUpdateRequest = {
                roadmapId: item.roadmapId,
                order: item.order + 1,
                title: item.title,
                jobStory: item.jobStory,
                objective: item.objective,
                state: item.state,
                tags: item.tags,
            }

            let res = await putRoadmapCard(item.id, roadmapUpdateRequest);
            if (res.success) {
                setReload(true);
            }
        }
    }

    appContext?.auth.isEditingActive && drag(drop(ref));

    const viewCardHistory = (roadmapCard: ItemType) => {
        document.body.click(); // to hide Popover
        setSelectedHistoryCard(roadmapCard);
        setRcHistoryModalShow(true)
    }

    return (
        <Col xl={item.state === 'candidates' || item.state === 'completed' ? '4' : {}}
            lg={item.state === 'candidates' || item.state === 'completed' ? '6' : {}}
            className={`dnd-item-container${isDragging ? " dnd-dragged-card" : ""}`}>
            <div
                ref={ref}
                style={Object.assign(style, {})}
                className={`dnd-item ${appContext?.auth.isEditingActive ? 'dnd-cursor-hand' : ''}`}
            >
                {
                    appContext?.auth.isEditingActive &&
                    <>
                        <ConfirmModal
                            show={confirmModalShow}
                            message={confirmModalMessage}
                            callback={confirmModalCallback}
                            handleClose={() => setConfirmModalShow(false)}
                            loading={confirmModalLoading}
                        />
                        <Row>
                            <Col style={{ textAlign: "right" }}>
                                {appContext?.auth.isEditingActive &&
                                    <OverlayTrigger
                                        trigger="click"
                                        placement="right"
                                        rootClose
                                        overlay={
                                            <Popover>
                                                <PopoverBody className="offer-families-action-container">
                                                    <>
                                                        {(item.state !== 'candidates' && item.state !== 'completed' && index > 0 && itemsLength > 1) &&
                                                            < div
                                                                className="offer-families-action-icon"
                                                                onClick={moveUp}
                                                                title="Move up Roadmap Card"
                                                            >
                                                                <ArrowUpCircle />
                                                            </div>
                                                        }
                                                        {(item.state !== 'candidates' && item.state !== 'completed' && itemsLength > 1 && itemsLength - index > 1) &&
                                                            <div
                                                                className="offer-families-action-icon"
                                                                onClick={moveDown}
                                                                title="Move down Roadmap Card"
                                                            >
                                                                <ArrowDownCircle />
                                                            </div>
                                                        }
                                                        <div
                                                            className="offer-families-action-icon"
                                                            onClick={() => { setShowEditWindow(true); document.body.click(); }}
                                                            title="Edit Roadmap Card"
                                                        >
                                                            <PencilSquare />
                                                        </div>
                                                        <div
                                                            className="offer-families-action-icon"
                                                            onClick={() => viewCardHistory(item)}
                                                            title={`View Roadmap Card history`}
                                                        >
                                                            <ClockHistory />
                                                        </div>
                                                        <div
                                                            className="offer-families-action-icon"
                                                            onClick={() => confirmDelete(item.id)}
                                                            title="Delete Roadmap Card"
                                                        >
                                                            <Trash />
                                                        </div>
                                                    </>
                                                </PopoverBody>
                                            </Popover>
                                        }
                                    >
                                        <ThreeDots className="offer-families-action-icon" />
                                    </OverlayTrigger>
                                }
                            </Col>
                        </Row>
                    </>
                }
                <div onClick={onOpen}>
                    <Row>
                        <Col>
                            <h6 className="dnd-item-objective dnd-item-ellipsis">{item.objective}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h5 className="dnd-item-ellipsis">{item.title}</h5>
                        </Col>
                    </Row>
                    <p className={`dnd-item-content dnd-truncate`}>{item.jobStory}</p>
                    <div className={"dnd-tag-holder"}>
                        {item.tags.map((tag: string) =>
                            <div key={tag} className="dnd-item-tag">
                                <TagIcon />{tag}
                            </div>
                        )
                        }
                    </div>
                </div>
            </div >
            <DetailsWindow
                item={item}
                onClose={onClose}
                show={show}
                color={status.color}
            />
            <CardModal
                card={item}
                handleClose={onClose}
                show={showEditWindow}
                entity={entity}
            />
            {historyModalShow &&
                <RoadmapCardHistoryModal
                    show={historyModalShow}
                    handleClose={() => { setRcHistoryModalShow(false); setSelectedHistoryCard(undefined) }}
                    roadmapCard={selectedHistoryCard}
                />
            }
        </Col >
    )
}
export default Item;

