import { AppError, MessageStatus, Store } from '../../../common/appConstants';
import { ApiResponse, MessageResponse, RoadmapCardsDto } from '../../../common/interfaces';

export interface GetRoadmapResponse {
    id: number,
    belongsToType: string,
    belongsToId: number,
    roadmapCards: RoadmapCardsDto,
    lastUpdatedAt: string,
}

export default async function getRoadmap(entityType: string, entityId: number): Promise<ApiResponse<GetRoadmapResponse>> {
    try {
        const response = await fetch(`/api/roadmaps/${entityType}/${entityId}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`
            }
        });
        if (response.status === 200) {
            const data: GetRoadmapResponse = await response.json();
            return {
                success: true,
                body: data
            };
        }
        else {
            const data: MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg
            }
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}