// package imports
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// project imports
import BusinessLinesIcon from '../../assets/images/icons/BusinessLinesIcon';
import { GenericEntity } from "../../common/interfaces";
import Channels from "../../screens/Channels/Channels";
import VisionBoard from '../../screens/VisionBoard/VisionBoard';
import getOfferFamilies from "../../services/api/offerFamilies/getOfferFamilies";
import { BannerProps } from "../Banner/Banner";
import { EntityType, MessageStatus } from "../../common/appConstants";
import { AppContext } from "../../services/context/contextProvider";
import { ISidebar } from "../Sidebar/Sidebar";
import getBusinessLines from "../../services/api/businessLines/getBusinessLines";

type BusinessLineTypeSwitchParams = {
    businessLineLink: string;
    type: string;
}

export default function BusinessLineTypeSwitch() {
    const appContext = useContext(AppContext);
    let { businessLineLink, type } = useParams<BusinessLineTypeSwitchParams>();

    const [bannerProps, setBannerProps] = useState<BannerProps>({});
    const [entityProps, setEntityProps] = useState<GenericEntity>({ type: EntityType.businessLine, id: 0, name: "" });
    const [loading, setLoading] = useState(false);

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            const sidebar : ISidebar = { options: {
                businessLines: {list: []}
            }}

            let blRes = await getBusinessLines();
            if (blRes.success && blRes.body) {
                sidebar.options.businessLines = {
                    list: blRes.body.businessLines.map(bl => {
                        return {
                            link: bl.link,
                            name: bl.name,
                            isActive: bl.link === businessLineLink
                        };
                    })
                };
            }
            else if (blRes.message) {
                appContext?.setMessage({ show: true, message: blRes.message, status: blRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            let res = await getOfferFamilies(businessLineLink);
            if (res.success && res.body) {
                sidebar.options.selectedBusinessLine = {
                    link: res.body.businessLine.link,
                    name: res.body.businessLine.name,
                    offerFamilies: res.body.businessLine.offerFamilies.map(of => {
                        return {
                            link: of.link,
                            name: of.name
                        };
                    }),
                    channelBoardActive: type === "channels",
                    visionBoardActive: type === "vision-board",
                    primaryMarketsActive: type === "primary-markets"
                }
    
                let bannerProps : BannerProps = {
                    titleIcon: <BusinessLinesIcon/>,
                    title1: res.body.businessLine.name,
                    breadcrumbs: [{link: "/", displayName: "Home"},{link: "/business-lines", displayName: "Business Lines"},{link: `/business-lines/${businessLineLink}`, displayName: res.body.businessLine.name}],
                    subtitle1: {
                        field: "Head of Product",
                        value: res.body.businessLine.headOfProduct,
                    }
                }
                setBannerProps(bannerProps);
    
                setEntityProps({
                    type: EntityType.businessLine,
                    id: res.body.businessLine.id,
                    name: res.body.businessLine.name
                });
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            appContext?.setSidebar(sidebar);
            setLoading(false);
        },
        [businessLineLink, type, appContext]
    ) 

    useEffect(() => {
        setBannerProps({});
        apiLoad();
    }, [businessLineLink, type]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <>
        {type === "channels" ?
        <Channels
            loading={loading}
            bannerProps={bannerProps}
            entity={entityProps}
            businessLineLink={businessLineLink}
        />
        :
        <VisionBoard
            loading={loading}
            bannerProps={bannerProps}
            entity={entityProps}
        />
        }
        </>
    );
}