import { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { PencilSquare } from 'react-bootstrap-icons';
import { ArtefactTemplate } from '../../common/interfaces';
import getArtefactTemplates from '../../services/api/artefactTemplates/getArtefactTemplates';
import Spinner from '../Spinner/Spinner';
import './TemplatesTab.scss';
import { AppContext } from '../../services/context/contextProvider';
import { MessageStatus } from '../../common/appConstants';
import TemplateModal from '../TemplateModal/TemplateModal';

export default function TemplatesTab() {
    const appContext = useContext(AppContext);
    
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState<ArtefactTemplate[]>([]);
    const [templateModalShow, setTemplateModalShow] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<ArtefactTemplate>();

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            let res = await getArtefactTemplates();
    
            if (res.success && res.body) {
                setTemplates(res.body.artefactTemplates);
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
            setLoading(false);
        },
        [appContext]
    );
    
    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const editTemplate = (artefactTemplate: ArtefactTemplate) => {
        setSelectedTemplate(artefactTemplate);
        setTemplateModalShow(true);
    }

    return (
        <div className="templates-tab-container p-3">
            <TemplateModal
                show={templateModalShow}
                handleClose={() => { setTemplateModalShow(false); setSelectedTemplate(undefined) }}
                template={selectedTemplate}
                reload={apiLoad}
            />
            
            {loading ?
            <div className="spinner-container">
                <Spinner size={60}/>
            </div>
            :
            <>
            <div className="templates-tab-table-container">
                <Table className="templates-tab-table" bordered size="sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>For template</th>
                            <th>For entity</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.length > 0 ?
                        templates.map((at, i) => 
                        <tr key={`template-${i}`}>
                            <td>{at.name}</td>
                            <td>{at.forArtefact}</td>
                            <td>{at.forEntity}</td>
                            <td className="templates-tab-action-container">
                                <div 
                                    className="templates-tab-action-icon"
                                    onClick={() => editTemplate(at)}
                                    title="edit Template details"
                                >
                                    <PencilSquare/>
                                </div>
                            </td>
                        </tr>
                        )
                        :
                        <tr>
                            <td colSpan={5}>No templates found...</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
            </>
            }
        </div>
    );
}