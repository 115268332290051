// package imports
import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Button, Card } from "react-bootstrap";
import { ArrowDownCircle, ArrowUpCircle, ClockHistory, PencilSquare, PlusCircle, Recycle, Trash } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';

// project imports
import './BusinessLines.scss';
import { Breadcrumb, BusinessLine } from "../../common/interfaces";
import Banner from "../../components/Banner/Banner";
import { AppContext } from "../../services/context/contextProvider";
import getBusinessLines, { GetBusinessLinesResponse } from "../../services/api/businessLines/getBusinessLines";
import Spinner from "../../components/Spinner/Spinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import deleteBusinessLine from "../../services/api/businessLines/deleteBusinessLine";
import BusinessLineModal from "../../components/BusinessLineModal/BusinessLineModal";
import promoteBusinessLine from "../../services/api/businessLines/promoteBusinessLine";
import demoteBusinessLine from "../../services/api/businessLines/demoteBusinessLine";
import { MessageStatus } from "../../common/appConstants";
import GlobeIcon from "../../assets/images/icons/GlobeIcon";
import BusinessLineHistoryModal from "../../components/BusinessLineHistoryModal/BusinessLineHistoryModal";
import DeletedBusinessLinesModal from "../../components/DeletedBusinessLinesModal/DeletedBusinessLinesModal";

const tagManagerArgs = {
    dataLayer: {
        page: 'BusinessLines'
    },
    dataLayerName: 'PageDataLayer'
}

const breadcrumbs: Breadcrumb[] = [{ displayName: "Home", link: "/" }]

export default function BusinessLines() {
    const appContext = useContext(AppContext);
    var pageLocation = useLocation();
    TagManager.dataLayer(tagManagerArgs);
    useEffect(() => {
        window.dataLayer.push({
            event: 'BusinessLines Views',
            value: 'BusinessLines',
            page: {
                location: pageLocation,
                title: 'BusinessLines',
            },
            user: appContext?.auth.username,
            eventProps: {
                label: 'BusinessLines',
                value: 'BusinessLines',
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const [details, setDetails] = useState<GetBusinessLinesResponse>();
    const [loading, setLoading] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [businessLineModalShow, setBusinessLineModalShow] = useState(false);
    const [deletedBusinessLinesModalShow, setDeletedBusinessLinesModalShow] = useState(false);
    const [blHistoryModalShow, setBlHistoryModalShow] = useState(false);
    const [selectedBusinessLine, setSelectedBusinessLine] = useState<BusinessLine>();
    const [selectedHistoryBusinessLine, setSelectedHistoryBusinessLine] = useState<BusinessLine>();

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            let res = await getBusinessLines();

            if (res.success && res.body) {
                appContext?.setSidebar({
                    options: {
                        businessLines: {
                            list: res.body.businessLines.map(bl => {
                                return {
                                    link: bl.link,
                                    name: bl.name
                                };
                            }),
                            isActive: true
                        }
                    }
                });

                setDetails(res.body);
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
            setLoading(false);
        },
        [appContext]
    );

    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const confirmDelete = (message: string, id: number) => {
        setConfirmModalMessage(`Are you sure you want to delete ${message}?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    }

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);
        const res = await deleteBusinessLine(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const editBusinessLine = (bl: BusinessLine) => {
        setSelectedBusinessLine(bl);
        setBusinessLineModalShow(true);
    }

    const addBusinessLine = () => {
        setSelectedBusinessLine(undefined);
        setBusinessLineModalShow(true);
    }

    const displayDeletedBusinessLines = () => {
        setSelectedBusinessLine(undefined);
        setDeletedBusinessLinesModalShow(true);
    }

    const moveUpBusinessLine = async (id: number) => {
        setLoading(true);
        const res = await promoteBusinessLine(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setLoading(false);
    }

    const moveDownBusinessLine = async (id: number) => {
        setLoading(true);
        const res = await demoteBusinessLine(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setLoading(false);
    }

    const viewBlHistory = (bl: BusinessLine) => {
        document.body.click();
        setSelectedHistoryBusinessLine(bl);
        setBlHistoryModalShow(true);
    }

    return (
        <div className="content-container">
            {appContext?.auth.isEditingActive && appContext.auth.isAdmin &&
                <>
                    <ConfirmModal
                        show={confirmModalShow}
                        message={confirmModalMessage}
                        callback={confirmModalCallback}
                        handleClose={() => setConfirmModalShow(false)}
                        loading={confirmModalLoading}
                    />

                    <BusinessLineModal
                        show={businessLineModalShow}
                        handleClose={() => { setBusinessLineModalShow(false); setSelectedBusinessLine(undefined) }}
                        businessLine={selectedBusinessLine}
                        reload={apiLoad}
                    />

                    <BusinessLineHistoryModal
                        show={blHistoryModalShow}
                        handleClose={() => { setBlHistoryModalShow(false); setSelectedHistoryBusinessLine(undefined) }}
                        businessLine={selectedHistoryBusinessLine}
                    />

                    <DeletedBusinessLinesModal
                        show={deletedBusinessLinesModalShow}
                        handleClose={() => setDeletedBusinessLinesModalShow(false)}
                        reload={apiLoad}
                    />
                </>
            }

            <Banner
                titleIcon={<GlobeIcon />}
                title1="Computershare"
                title2="Business Lines"
                breadcrumbs={breadcrumbs}
                breadcrumbActive="Business Lines"
            />

            <Container className="screen-container p-3">
                {loading ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        {appContext?.auth.isEditingActive && appContext.auth.isAdmin &&
                            <Row className="pb-1">
                                <Col className="business-lines-button-container" xs={12}>
                                    <Button
                                        className="business-lines-button-spacing"
                                        variant="outline-primary"
                                        onClick={displayDeletedBusinessLines}
                                    >
                                        <Recycle className="add-button-icon" /> Business Lines
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        onClick={addBusinessLine}
                                    >
                                        <PlusCircle className="add-button-icon" /> Business Line
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <Row xs={3} className="business-lines-card-row g-4 mt-1 pb-2">
                            {details && details.businessLines.map((sel, i) =>
                                <Col key={`blc-${i}`} className="business-lines-card-col mt-1 pb-3">
                                    <Card className="business-lines-card">
                                        <Link to={`/business-lines/${sel.link}`}>
                                            <Card.Body className="business-lines-card-body">
                                                <div className="business-lines-card-title">{sel.name}</div>
                                                <div className="business-lines-card-desc">{sel.description}</div>
                                                <div className="business-lines-card-hop">
                                                    <hr className="business-lines-card-hop-divider" />
                                                    <div className="business-lines-card-hop-title">Head of Product:</div>
                                                    <div className="business-lines-card-hop-name">{sel.headOfProduct}</div>
                                                </div>
                                            </Card.Body>
                                        </Link>
                                        {appContext?.auth.isEditingActive && appContext.auth.isAdmin &&
                                            <Card.Footer className="business-lines-card-footer">
                                                {i !== 0 &&
                                                    <div
                                                        className="business-lines-action-icon"
                                                        onClick={() => moveUpBusinessLine(sel.id)}
                                                        title="move up in order"
                                                    >
                                                        <ArrowUpCircle />
                                                    </div>
                                                }
                                                {i + 1 !== details.businessLines.length &&
                                                    <div
                                                        className="business-lines-action-icon"
                                                        onClick={() => moveDownBusinessLine(sel.id)}
                                                        title="move down in order"
                                                    >
                                                        <ArrowDownCircle />
                                                    </div>
                                                }
                                                <div
                                                    className="business-lines-action-icon"
                                                    onClick={() => editBusinessLine(sel)}
                                                    title="edit Business Line details"
                                                >
                                                    <PencilSquare />
                                                </div>
                                                <div
                                                    className="business-lines-action-icon"
                                                    onClick={() => viewBlHistory(sel)}
                                                    title="display history"
                                                >
                                                    <ClockHistory />
                                                </div>
                                                <div
                                                    className="business-lines-action-icon"
                                                    onClick={() => confirmDelete(sel.name, sel.id)}
                                                    title="delete Business Line"
                                                >
                                                    <Trash />
                                                </div>
                                            </Card.Footer>
                                        }
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </>
                }
            </Container>
        </div>
    );
}