import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M7 32.55l24.9-21.72L57 32.56a.94.94 0 001.3-.09.91.91 0 00-.09-1.3L32.53 8.91a.94.94 0 00-1.21 0L5.82 31.16A.92.92 0 107 32.55z" />
      <path d="M35.84 55.31h14.44a.92.92 0 00.92-.92V33.51a1 1 0 00-.3-.69L32.57 16.51a.92.92 0 00-1.23 0L13.1 32.85a.93.93 0 00-.31.68v20.86a.93.93 0 00.93.92h14.44V43.45h7.68zM27.23 41.6a.93.93 0 00-.92.92v10.94H14.64V34L32 18.44l17.4 15.49v19.53H37.69V42.52a.93.93 0 00-.92-.92z" />
    </svg>
  )
}

export default SvgComponent
