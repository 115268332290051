import './Login.scss'
import Banner from "../../components/Banner/Banner";
import PyramidIcon from '../../assets/images/icons/PyramidIcon';
import { Button, Card } from 'react-bootstrap';
import { AppContext } from '../../services/context/contextProvider';
import { useContext, useEffect, useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { MessageStatus, Store } from '../../common/appConstants';
import { Link, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    dataLayer: {
        page: 'Login'
    },
    dataLayerName: 'PageDataLayer'
}

export default function Login() {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'Login Views',
            value: 'Login',
            page: {
                location: pageLocation,
                title: "Login",
            },
            eventProps: {
                label: "Login",
                value: "Login",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const [loginFormLoading, setLoginFormLoading] = useState(false);

    const attemptLogin = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoginFormLoading(true);

        const res = await appContext?.login();

        if (res && !res?.success) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else if (!res) {
            appContext?.setMessage({ show: true, message: "An unknown error occured!", status: MessageStatus.error });
        }

        setLoginFormLoading(false);
    }

    const saveReturnUrl = () => {
        localStorage.setItem(Store.returnUrl, window.location.pathname);
    }

    return (
        <div className="content-container">
            {appContext?.auth.loggingOut ?
                <>
                    <Banner
                        title1="Logging out..."
                    />

                    <div className="screen-container login-logout-message">
                        Redirecting to the external provider to complete the logout process...
                    </div>
                </>
                :
                <>
                    <Banner
                        title1="Login to Continue"
                    />

                    <div className="screen-container login-container">
                        <Card
                            className="login-card p-4"
                        >
                            <PyramidIcon className="login-icon" />
                            <div className="login-card-title mb-3">Product Lifecycle Management Tool</div>
                            <Link to="/login-external">
                                <Button
                                    className="login-button mt-3"
                                    variant="outline-primary"
                                    disabled={loginFormLoading}
                                    onClick={saveReturnUrl}
                                >
                                    Login
                                </Button>
                            </Link>
                            {process.env.NODE_ENV === "development" &&
                                <Button
                                    className="login-button mt-3"
                                    variant="outline-primary"
                                    onClick={attemptLogin}
                                    type="submit"
                                    disabled={loginFormLoading}
                                >
                                    {loginFormLoading ? <Spinner /> : "Dev Login"}
                                </Button>
                            }
                        </Card>
                    </div>
                </>
            }
        </div>
    );
}