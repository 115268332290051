import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import BusinessLinesIcon from "../../assets/images/icons/BusinessLinesIcon";
import { AppError, Role } from "../../common/appConstants";
import { ApiResponse, User } from "../../common/interfaces";
import postUser from "../../services/api/users/postUser";
import putUser from "../../services/api/users/putUser";
import Spinner from "../Spinner/Spinner";
import './UserModal.scss';

export interface UserModalProps {
    show: boolean;
    handleClose: () => void;
    user?: User;
    reload: () => Promise<void>;
};

type UserForm = {
    valid: boolean;
    validationError: string;
    username: string;
    usernameError: string;
    externalId?: string;
    externalIdError: string;
    role: string;
    roleError: string;
    firstName: string;
    firstNameError: string;
    lastName: string;
    lastNameError: string;
}

const blankUser: UserForm = { valid: true, validationError: "", username: "", usernameError: "", externalId: "", externalIdError: "", firstName: "", firstNameError: "", lastName: "", lastNameError: "", role: "", roleError: "" };

export default function UserModal({ show, handleClose, user, reload }: UserModalProps) {
    const [form, setForm] = useState(blankUser);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user) {
            setForm({
                ...form,
                username: user.username,
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                externalId: user.externalId
            });
        }
        else {
            setForm(blankUser);
        }
    }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };

        if (form.username.length < 2) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.usernameError = "Username of 2 or more characters must be provided."
        }

        if (form.firstName.length < 2) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.firstNameError = "First name of 2 or more characters must be provided."
        }

        if (form.lastName.length < 2) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.lastNameError = "Last name of 2 or more characters must be provided."
        }

        if (form.role.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.roleError = "User role must be selected."
        }

        if (formUpdate.valid) {
            let res: ApiResponse<null> | ApiResponse<User>;

            if (user?.id) {
                res = await putUser(user.id, {
                    username: form.username,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    role: form.role,
                    externalId: form.externalId
                });
            }
            else {
                res = await postUser({
                    username: form.username,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    role: form.role,
                    externalId: form.externalId
                });
            }

            if (res && res.success) {
                formUpdate = blankUser;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            usernameError: "",
            firstNameError: "",
            lastNameError: "",
            roleError: "",
            externalIdError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            usernameError: "",
            firstNameError: "",
            lastNameError: "",
            roleError: "",
            externalIdError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankUser); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="user-modal-header">
                <BusinessLinesIcon className="user-modal-icon" />
                <div className="user-modal-title">
                    {user ? `Edit user details for "${user.username}"` : "Add new user"}
                </div>
            </Modal.Header>
            <Modal.Body className="user-modal-body">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupUsername">
                        <Form.Label>Username*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter username"
                            value={form.username}
                            name="username"
                            onChange={handleChange}
                            isInvalid={form.usernameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.usernameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupExternalId">
                        <Form.Label>Azure Active Directory ID (once linked this value cannot be changed)</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Azure Active Directory ID"
                            value={form.externalId}
                            name="externalId"
                            onChange={handleChange}
                            isInvalid={form.externalIdError.length > 0}
                            disabled={loading || user?.externalIdLinked}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.externalIdError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupRole">
                        <Form.Label>Role*</Form.Label>
                        <Form.Select
                            value={form.role}
                            name="role"
                            onChange={handleSelectChange}
                            isInvalid={form.roleError.length > 0}
                            disabled={loading}
                            className={form.role ? "" : "user-modal-placeholder"}
                        >
                            <option value="" selected disabled hidden>Select a Role</option>
                            <option className="user-modal-selected" value={Role.standard}>Standard</option>
                            <option className="user-modal-selected" value={Role.editor}>Editor</option>
                            <option className="user-modal-selected" value={Role.admin}>Admin</option>
                            <option className="user-modal-selected" value={Role.rootAdmin}>User management</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            {form.roleError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupFirstName">
                        <Form.Label>First Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter first name"
                            value={form.firstName}
                            name="firstName"
                            onChange={handleChange}
                            isInvalid={form.firstNameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.firstNameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupLastName">
                        <Form.Label>Last Name*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter last name"
                            value={form.lastName}
                            name="lastName"
                            onChange={handleChange}
                            isInvalid={form.lastNameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.lastNameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="user-modal-footer">
                <div className="user-modal-error">
                    {!form.valid && <><ExclamationCircle className="user-modal-error-icon" />{form.validationError}</>}
                </div>
                <div className="user-modal-button-container">
                    <Button
                        className="user-modal-button"
                        onClick={() => { setForm(blankUser); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        className={loading ? "user-modal-button-loading" : "user-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner /> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}