import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ClockHistory } from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { ChannelWithBusinessLineChannel } from "../../common/interfaces";
import getBusinessLineChannelHistory, { GetBusinessLineChannelHistoryResponse } from "../../services/api/businessLineChannels/getBusinessLineChannelHistory";
import { AppContext } from "../../services/context/contextProvider";
import Spinner from "../Spinner/Spinner";

import './BusinessLineChannelHistoryModal.scss';

export interface BusinessLineChannelModalProps {
    show: boolean;
    handleClose: () => void;
    ch?: ChannelWithBusinessLineChannel;
};

export default function BusinessLineChannelHistoryModal({show, handleClose, ch}: BusinessLineChannelModalProps) {
    const appContext = useContext(AppContext);
    
    const [history, setHistory] = useState<GetBusinessLineChannelHistoryResponse>();

    const apiLoad = useCallback(
        async () => {
            if (ch?.businessLineChannel && ch?.businessLineChannel.id > 0)
            {
                let res = await getBusinessLineChannelHistory(ch.businessLineChannel.id);
                if (res.success && res.body) {
                    setHistory(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }
            else
            {
                setHistory(undefined);
            }
        },
        [ch, appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [ch]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }
    
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <Modal.Header className="business-line-channel-history-modal-header">
                <ClockHistory className="business-line-channel-history-modal-icon"/>
                <div className="business-line-channel-history-modal-title">
                    {`${ch? ch.name + " History" : "Error"}`}
                </div>
            </Modal.Header>
            <Modal.Body className="business-line-channel-history-modal-body">
                <div className="users-tab-table-container">
                    <Table className="users-tab-table" bordered size="sm">
                        <thead>
                            <tr>
                                <th>Customer Types</th>
                                <th>Countries</th>
                                <th>Products</th>
                                <th>Active</th>
                                <th>Updated By</th>
                                <th>Date Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history ? history.businessLineChannelHistory.length > 0 ? history.businessLineChannelHistory.map((blc, i) => 
                            <tr key={`Data-${i}`}>
                                <td>{blc.customerTypes}</td>
                                <td>{blc.countries}</td>
                                <td>{blc.products}</td>
                                <td>{blc.isActive ? "Yes" : "No"}</td>
                                <td>{blc.lastUpdatedBy}</td>
                                <td>{DateTime.fromISO(blc.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                            </tr>
                            )
                            :
                            <tr>
                                <td colSpan={5}>No history found...</td>
                            </tr>
                            :
                            <tr>
                                <td className="business-line-channel-history-modal-spinner" colSpan={5}>
                                    <Spinner/>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="business-line-channel-modal-button-container">
                    <Button
                        className="business-line-channel-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}