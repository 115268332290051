import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <polygon points="43.55 29.12 38.8 37.22 40.94 37.22 44.34 31.42 47.69 37.22 49.82 37.22 44.08 27.28 19.94 27.28 14.19 37.22 16.32 37.22 21 29.12 27.16 29.12 22.47 37.22 24.6 37.22 29.29 29.12 35.36 29.12 30.68 37.22 32.81 37.22 37.49 29.12 43.55 29.12"/>
      <path d="M24.23,23.56,32,10.1,39.8,23.56h2.13L32.82,7.79a1,1,0,0,0-1.6,0L22.09,23.56Z"/>
      <path d="M3.75,56.17a.91.91,0,0,0,.8.46h54.9a.91.91,0,0,0,.8-.46.94.94,0,0,0,0-.92h0L52,40.94H12L3.75,55.25A.94.94,0,0,0,3.75,56.17Zm54.1-1.38H51.34l3.26-5.63Zm-4.32-7.47-4.32,7.47H44.88L51.32,43.5Zm-3.91-4.55-6.86,12H38.43l6.39-11.2-1.65-.82Zm-6.46,0-6.86,12H32l6.38-11.2-1.64-.82Zm-12.9,0h6.45l-6.86,12H25.52l6.37-11.15-1.73-.7L23.4,54.79H19.07l6.39-11.2-1.64-.82Zm-17.15,0h10.7L17,54.79H12.62L19,43.64l-1.73-.7L10.49,54.79H6.15Z"/>
    </svg>
  )
}

export default SvgComponent
