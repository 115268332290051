import { useEffect } from "react";

export default function AuthRedirect() {
    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            // redirect to development API (44301) from React dev server host (3000)
            window.location.replace(`https://localhost:44301/auth/login-external`);
        }
        else {
            // this is not required when on the same host
            window.location.replace(`${window.location.protocol}//${window.location.host}/auth/login-external`);
        }
    })

    return null;
}