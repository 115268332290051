import { useContext, useState } from "react";
import { Button, Col, Collapse, Row } from "react-bootstrap";
import { DropTargetMonitor } from "react-dnd";
import { ChevronDown, ChevronRight } from "react-bootstrap-icons";

import { RoadMapConstants } from "../../../common/appConstants";
import Item from "../Item/Item";
import DropWrapper from "../DropWrapper/DropWrapper";
import Column from "../Column/Column";
import { GenericEntity, ItemType, RoadmapCard, RoadmapCardCreationOrUpdateRequest } from "../../../common/interfaces";
import putRoadmapCard from "../../../services/api/roadmapCard/putRoadmapCard";
import { AppContext } from '../../../services/context/contextProvider';
import "./TimelineSection.scss";
import NoCards from "../NoCards/NoCards";
import daysBetween from "../../../services/utils/daysBetween";

type TimelineRowProps = {
    times: string[];
    items?: RoadmapCard[];
    entity: GenericEntity;
    loading: boolean;
}

export default function TimelineSection({ times, items, entity, loading }: TimelineRowProps) {
    const appContext = useContext(AppContext);

    let rc: RoadmapCard[] = [];
    const [roadmapCards, setRoadmapCards] = useState(items || rc);
    const [open, setOpen] = useState([true, false, false]);
    const [maxCards, setMaxCards] = useState(30);

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        if (items && roadmapCards && appContext?.auth.isEditingActive) {
            const item = roadmapCards[dragIndex];
            setRoadmapCards(prevRoadmapCards => {
                const newItems = prevRoadmapCards.filter((i, idx) => idx !== dragIndex);
                newItems.splice(hoverIndex, 0, item);
                return [...newItems];
            });
        }
    };

    const onDrop = async (item: ItemType, monitor: DropTargetMonitor, newState: string) => {
        if (appContext?.auth.isEditingActive) {
            if (item && newState !== item.state) {
                var roadmapUpdateRequest: RoadmapCardCreationOrUpdateRequest = {
                    roadmapId: item.roadmapId,
                    order: item.order,
                    title: item.title,
                    jobStory: item.jobStory,
                    objective: item.objective,
                    state: newState,
                    tags: item.tags,
                }

                var res = await putRoadmapCard(item.id, roadmapUpdateRequest);
                if (res.success) {
                    /** front-end changes to RoadmapCards List based on after update */
                    setRoadmapCards(prevItems => {
                        const newItems = prevItems.filter(i => i.id !== item.id);
                        const pickedItem = prevItems.filter(i => i.id === item.id)[0];
                        pickedItem.state = newState;
                        pickedItem.order = newItems.filter(i => i.state === newState).length + 1;

                        newItems.push(pickedItem);

                        return [...newItems];
                    });
                    appContext.setNewDate(true);
                }
                else {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
            }
        }
    }

    const filterCards = (item?: RoadmapCard, state?: string, tabName?: string): boolean => {
        let output = false;

        if (tabName && state && item) {
            if (tabName === RoadMapConstants.candidates) {
                if (item.dateAdded) {
                    let days = daysBetween(item.dateAdded, Date.now())

                    if (state === RoadMapConstants.lastThreeMonths) {
                        if (item.dateAdded != null) {
                            output = (days <= 90);
                        }
                    }
                    if (state === RoadMapConstants.threeToSixMonthsAgo) {
                        if (item.dateAdded != null) {
                            output = (days > 90 && days <= 180);
                        }
                    }
                    if (state === "moreThanSixMonthsAgo") {
                        if (item.dateAdded != null) {
                            output = (days > 180);
                        }
                    }
                }
            }
            if (tabName === RoadMapConstants.completed) {
                if (item.dateCompleted) {
                    let days = daysBetween(item.dateCompleted, Date.now())

                    if (state === RoadMapConstants.lastThreeMonths) {
                        output = (days <= 90);
                    }

                    if (state === RoadMapConstants.threeToSixMonthsAgo) {
                        output = (days > 90 && days <= 180);

                    }
                    if (state === "moreThanSixMonthsAgo") {
                        output = (days > 180);
                    }
                }
            }
        }
        return output;
    }

    const showMoreItems = () => {
        setMaxCards((prevValue) => prevValue + 6);
    }

    /** It is only used for Candidates and Completed */
    const createFilteredCardRows = (items: RoadmapCard[], filter: string, by: string) => {
        return (
            <Row className={`dnd-timeline-container left`}>
                {RoadMapConstants.states
                    .filter(selectedButton => times.indexOf(selectedButton.status) !== -1)
                    .map((RoadmapFixedState, idx) => {
                        return (
                            <Row key={RoadmapFixedState + "-" + idx.toString()}>
                                <Col>
                                    <Row>
                                        {items.filter(item => ((item.state === RoadmapFixedState.status) && (filterCards(item, filter, by)))).length > 0 ?
                                            items.filter(item => ((item.state === RoadmapFixedState.status) && filterCards(item, filter, by)))
                                                .slice(0, maxCards)
                                                .map((card, idx) =>
                                                    <Item key={card.id.toString()} item={card} index={idx} moveItem={moveItem} status={RoadmapFixedState} entity={entity} itemsLength={items.filter(item => (item.state === RoadmapFixedState.status)).length} maxOrderNumber={0} />)
                                            :
                                            <NoCards />
                                        }
                                    </Row>
                                    {items.filter(item => ((item.state === RoadmapFixedState.status) && (filterCards(item, filter, by)))).length > maxCards &&
                                        <Row>
                                            <Col className="dnd-center-items">
                                                <Button onClick={showMoreItems} size={"sm"} variant="outline-primary">Show More ...</Button>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        )
                    })
                }
            </Row>
        )
    }

    const maxOrderByItemOrder = (items: RoadmapCard[]): number => {
        return Math.max.apply(Math, items.map((item) => item.order));
    }

    if (roadmapCards) { // any roadmaps?
        return (
            <div className={"dnd-board vertical-scroll run-dnd-animation"}>
                {/* BEGIN: Roadmap(Now, Next,Later)) */}
                <Row className={`dnd-timeline-container`}>
                    {times.length > 1 && <div className="dnd-fading-box-top" />}
                    {RoadMapConstants.states
                        .filter(selectedButton => times.indexOf(selectedButton.status) !== -1)
                        .map((RoadmapFixedState, idx) => {
                            return (
                                <Col key={RoadmapFixedState.status + idx.toString()} className={`dnd-col-wrapper${(times && times.length > 1) ? " small" : " large"} ${times.length > 1 ? 'dnd-full-height' : ''}`} md="4">
                                    {/* BEGIN: Roadmap Titles (Now, Next, Later) */}
                                    {RoadMapConstants.tabs.roadmap.some(el => times.includes(el)) &&
                                        <h2 className={"dnd-col-header"}>{RoadmapFixedState.status.toUpperCase()}</h2>
                                    }
                                    {/* END: Roadmap Titles (Now, Next, Later) */}
                                    <div className={`${times.length > 1 ? 'dnd-header-separator' : ''}`} style={{ background: `${RoadmapFixedState.color}` }}></div>
                                    {RoadMapConstants.tabs.roadmap.some(el => times.includes(el)) &&
                                        <DropWrapper
                                            onDrop={onDrop}
                                            status={RoadmapFixedState.status}
                                            style={{ background: "#e9e9e9" }}
                                            isEmpty={roadmapCards.filter(item => (item.state === RoadmapFixedState.status)).length === 0}
                                        >
                                            <Column>
                                                {roadmapCards.filter(item => (item.state === RoadmapFixedState.status)).length > 0 ?
                                                    roadmapCards.filter(item => (item.state === RoadmapFixedState.status))
                                                        .sort((a, b) => (a.order > b.order) ? 1 : (b.order > a.order) ? -1 : 0)
                                                        .map((card, idx) => <Item key={card.id.toString()} item={card} index={idx} moveItem={moveItem} status={RoadmapFixedState} entity={entity} itemsLength={roadmapCards.filter(item => (item.state === RoadmapFixedState.status)).length} maxOrderNumber={maxOrderByItemOrder(roadmapCards.filter(item => (item.state === RoadmapFixedState.status)))} />)
                                                    :
                                                    <NoCards />
                                                }
                                            </Column>
                                        </DropWrapper>
                                    }
                                </Col>
                            )
                        })}
                </Row>
                {/* END: Roadmap(Now, Next,Later) */}


                {/* BEGIN: Candidates && Completed */}
                {(times.includes(RoadMapConstants.candidates) || times.includes(RoadMapConstants.completed)) &&
                    <>
                        <div className="dnd-fading-box-top small" />
                        <div id="example-collapse-text1">
                            <Row className={`dnd-timeline-container left`}>
                                <Col style={{
                                    color: RoadMapConstants.states
                                        .filter(selectedButton => times.indexOf(selectedButton.status) !== -1)[0].color
                                }}>
                                    <h6 className="dnd-border-bottom dnd-clickable" onClick={() => setOpen([!open[0], open[1], open[2]])} aria-controls="example-collapse-text1"> {times[0].charAt(0).toUpperCase() + times[0].slice(1)} {times[0] === RoadMapConstants.candidates && 'created'} in the last 3 months {open[0] ? <ChevronDown className="dnd-arrow-size" /> : <ChevronRight className="dnd-arrow-size" />}</h6>
                                </Col>
                            </Row>
                            <Collapse in={open[0]}>
                                {createFilteredCardRows(roadmapCards, RoadMapConstants.lastThreeMonths, (times.includes(RoadMapConstants.candidates) ? RoadMapConstants.candidates : RoadMapConstants.completed))}
                            </Collapse>
                        </div>
                        <div id="example-collapse-text2">
                            <Row className={`dnd-timeline-container left`}>
                                <Col style={{
                                    color: RoadMapConstants.states
                                        .filter(selectedButton => times.indexOf(selectedButton.status) !== -1)[0].color
                                }}>
                                    <h6 className="dnd-border-bottom dnd-clickable" onClick={() => setOpen([open[0], !open[1], open[2]])} aria-controls="example-collapse-text2">{times[0].charAt(0).toUpperCase() + times[0].slice(1)} {times[0] === RoadMapConstants.candidates && 'created'} 3-6 months ago {open[1] ? <ChevronDown className="dnd-arrow-size" /> : <ChevronRight className="dnd-arrow-size" />}</h6>
                                </Col>
                            </Row>
                            <Collapse in={open[1]}>
                                {createFilteredCardRows(roadmapCards, RoadMapConstants.threeToSixMonthsAgo, (times.includes(RoadMapConstants.candidates) ? RoadMapConstants.candidates : RoadMapConstants.completed))}
                            </Collapse>
                        </div>
                        <div id="example-collapse-text3">
                            <Row className={`dnd-timeline-container left`}>
                                <Col style={{
                                    color: RoadMapConstants.states
                                        .filter(selectedButton => times.indexOf(selectedButton.status) !== -1)[0].color
                                }}>
                                    <h6 className="dnd-border-bottom dnd-clickable" onClick={() => setOpen([open[0], open[1], !open[2]])} aria-controls="example-collapse-text3">{times[0].charAt(0).toUpperCase() + times[0].slice(1)} {times[0] === RoadMapConstants.candidates && 'created'} more than 6 months ago {open[2] ? <ChevronDown className="dnd-arrow-size" /> : <ChevronRight className="dnd-arrow-size" />}</h6>
                                </Col>
                            </Row>
                            <Collapse in={open[2]}>
                                {createFilteredCardRows(roadmapCards, "moreThanSixMonthsAgo", (times.includes(RoadMapConstants.candidates) ? RoadMapConstants.candidates : RoadMapConstants.completed))}
                            </Collapse>
                        </div>
                    </>
                }
                {/* END: Candidates && Completed */}
            </div >
        )
    }
    else { // no roadmaps
        return (
            <div className="dnd-board vertical-scroll dnd-centered-content-div">
                <h6>No roadmap cards have been created.</h6>
            </div>
        )
    }
}