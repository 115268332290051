import './DualListbox.scss';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import { ChevronDoubleLeft, ChevronDoubleRight, ChevronLeft, ChevronRight } from 'react-bootstrap-icons';

export default function DualListbox({options, selected, setSelected}) {
    
    return (
        <DualListBox
            options={options}
            selected={selected}
            onChange={setSelected}
            icons={icons}
            canFilter={true}
            showHeaderLabels={true}
        />
    );
};

const icons = {
    moveLeft: <ChevronLeft className="duallistbox-icon" />,
    moveAllLeft: <ChevronDoubleLeft className="duallistbox-icon" />,
    moveRight: <ChevronRight className="duallistbox-icon" />,
    moveAllRight: <ChevronDoubleRight className="duallistbox-icon" />
}