import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M14,50.44l-.17,0a.82.82,0,0,1-.63-.61l-1.8-7.33,1.6-.4,1.39,5.64,4.11-5.47a.81.81,0,0,1,.68-.33.83.83,0,0,1,.66.37l2,3.05-1.37.92-1.4-2.09-4.46,5.94A.82.82,0,0,1,14,50.44Z"/>
      <path d="M40.45,25a.83.83,0,0,1-.83-.83v-18a.83.83,0,0,1,.83-.83h9.84a.83.83,0,0,1,.82.83V9.22h9.48a.82.82,0,0,1,.75.49.83.83,0,0,1-.14.89l-4,4.41,4,4.42a.83.83,0,0,1-.61,1.38H46.29a.83.83,0,0,1-.58-1.42l3.75-3.68V7H41.27V24.13A.83.83,0,0,1,40.45,25Zm7.86-5.8H58.73l-3.24-3.59a.82.82,0,0,1,0-1.11l3.24-3.59H51.11v5.19a.83.83,0,0,1-.25.59Z"/>
      <rect x="40.79" y="15.21" width="9.43" height="1.65"/>
      <path d="M40.47,49.87a.81.81,0,0,1-.75-.48l-3.66-8-8.47,4.72a.82.82,0,0,1-.8-1.44L36,39.56a.8.8,0,0,1,.66-.07.82.82,0,0,1,.49.44l3.28,7.14,3.27-7.14a.84.84,0,0,1,.5-.44.82.82,0,0,1,.65.07l9.24,5.15a.82.82,0,1,1-.8,1.44l-8.46-4.72-3.65,8A.83.83,0,0,1,40.47,49.87Z"/>
      <path d="M61.48,58.7l-21-33.1-21,33.1-1.39-.89L39.76,23.62a.87.87,0,0,1,1.4,0L62.87,57.81Z"/>
      <path d="M2.52,58.7l-1.39-.89,17.34-27.3a.82.82,0,0,1,.7-.39h0a.83.83,0,0,1,.69.38l5.86,9.11-1.39.89-5.16-8Z"/>
    </svg>
  )
}

export default SvgComponent
