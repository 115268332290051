import { ReactElement } from "react";
import { Row } from "react-bootstrap";
import "./Column.scss";

type ColumnProps = {
    isOver?: () => Boolean;
    style?: Object;
    children?: ReactElement[] | ReactElement | string;
    classProp?: string
}

const Column = ({ isOver, style, children, classProp }: ColumnProps) => {
    const className = isOver ? " dnd-highlight-region" : "";

    return (
        <Row className={`${classProp ? classProp + ' ' : ''}${className}`} style={style}>
            {children}
        </Row>
    )
}

export default Column;