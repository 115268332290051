// package imports
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// project imports
import OfferFamiliesIcon from '../../assets/images/icons/OfferFamiliesIcon';
import { GenericEntity } from "../../common/interfaces";
import Roadmap from '../../screens/Roadmap/Roadmap';
import VisionBoard from '../../screens/VisionBoard/VisionBoard';
import getProducts from "../../services/api/products/getProducts";
import { BannerProps } from "../Banner/Banner";
import { EntityType, MessageStatus } from "../../common/appConstants";
import { AppContext } from "../../services/context/contextProvider";
import { ISidebar } from "../Sidebar/Sidebar";
import getBusinessLines from "../../services/api/businessLines/getBusinessLines";

type TypeSwitchParams = {
    businessLineLink: string;
    offerFamilyLink: string;
    type: string;
}

export default function OfferFamilyTypeSwitch() {
    const appContext = useContext(AppContext);
    let { businessLineLink, offerFamilyLink, type } = useParams<TypeSwitchParams>();

    const [bannerProps, setBannerProps] = useState<BannerProps>({});
    const [entityProps, setEntityProps] = useState<GenericEntity>({ type: EntityType.offerFamily, id: 0, name: "" });
    const [loading, setLoading] = useState(false);

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            const sidebar : ISidebar = { options: {
                businessLines: {list: []}
            }}

            let blRes = await getBusinessLines();
            if (blRes.success && blRes.body) {
                sidebar.options.businessLines = {
                    list: blRes.body.businessLines.map(bl => {
                        return {
                            link: bl.link,
                            name: bl.name,
                            isActive: bl.link === businessLineLink
                        };
                    })
                };
            }
            else if (blRes.message) {
                appContext?.setMessage({ show: true, message: blRes.message, status: blRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            let prRes = await getProducts(businessLineLink, offerFamilyLink);
            if (prRes.success && prRes.body) {
                sidebar.options.selectedBusinessLine = {
                    link: prRes.body.businessLine.link,
                    name: prRes.body.businessLine.name,
                    offerFamilies: prRes.body.businessLine.offerFamilies.map(of => {
                        return {
                            link: of.link,
                            name: of.name,
                            isActive: type === "vision-board" && of.link === offerFamilyLink
                        };
                    })
                }

                sidebar.options.selectedOfferFamily = {
                    link: prRes.body.businessLine.selectedOfferFamily.link,
                    name: prRes.body.businessLine.selectedOfferFamily.name,
                    products: prRes.body.businessLine.selectedOfferFamily.products.map(pr => {
                        return {
                            link: pr.link,
                            name: pr.name
                        };
                    }),
                    roadmapActive: type === "roadmap",
                    visionBoardActive: type === "vision-board"
                }

                let bannerProps: BannerProps = {
                    titleIcon: <OfferFamiliesIcon />,
                    title1: prRes.body.businessLine.selectedOfferFamily.name,
                    breadcrumbs: [{ link: "/", displayName: "Home" }, { link: "/business-lines", displayName: "Business Lines" }, { link: `/business-lines/${businessLineLink}`, displayName: prRes.body.businessLine.name }],
                    breadcrumbActive: prRes.body.businessLine.selectedOfferFamily.name,
                    subtitle1: {
                        field: "Product Manager",
                        value: prRes.body.businessLine.selectedOfferFamily.productManager,
                    }
                }
                setBannerProps(bannerProps);

                setEntityProps({
                    type: EntityType.offerFamily,
                    id: prRes.body.businessLine.selectedOfferFamily.id,
                    name: prRes.body.businessLine.selectedOfferFamily.name
                });
            }
            else if (prRes.message) {
                appContext?.setMessage({ show: true, message: prRes.message });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            appContext?.setSidebar(sidebar);
            setLoading(false);
        },
        [businessLineLink, offerFamilyLink, type, appContext]
    ) 

    useEffect(() => {
        setBannerProps({});
        apiLoad();
    }, [businessLineLink, offerFamilyLink, type]); // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <>
            {type === "roadmap" ?
                <Roadmap
                    loading={loading}
                    bannerProps={bannerProps}
                    entity={entityProps}
                />
                :
                <VisionBoard
                    loading={loading}
                    bannerProps={bannerProps}
                    entity={entityProps}
                />
            }
        </>
    );
}