import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import Select, { ActionMeta, MultiValue } from 'react-select';
import ChannelsIcon from "../../assets/images/icons/ChannelsIcon";
import { AppError, CustomerType, MessageStatus } from "../../common/appConstants";
import { ApiResponse, BrandedChannel, BrandedChannelWithBusinessLineChannelId, ChannelWithBusinessLineChannel } from "../../common/interfaces";
import postBrandedChannel from "../../services/api/brandedChannels/postBrandedChannel";
import putBrandedChannel from "../../services/api/brandedChannels/putBrandedChannel";
//import getCountries from "../../services/api/countries/getCountries";
import getBusinessLineCountries from "../../services/api/businessLineCountries/getBusinessLineCountries";
import getProductsForBusinessLine, { GetProductsResponse } from "../../services/api/products/getProductsForBusinessLine";
import { AppContext } from "../../services/context/contextProvider";
import DualListbox from "../DualListbox/DualListbox";
import Spinner from "../Spinner/Spinner";
import './BrandedChannelModal.scss';

export interface BrandedChannelModalProps {
    show: boolean;
    handleClose: () => void;
    brandedChannel?: BrandedChannelWithBusinessLineChannelId;
    channels: ChannelWithBusinessLineChannel[];
    businessLineId: number;
    businessLineName: string;
    reload: () => Promise<void>;
};

type BrandedChannelForm = {
    valid: boolean;
    validationError: string;
    businessLineChannelId: number;
    businessLineChannelIdError: string;
    name: string;
    nameError: string;
    description: string;
    descriptionError: string;
    customerTypes: SelectOption[];
    customerTypesError: string;
    countries: string[];
    countriesError: string;
    products: string[];
    productsError: string;
}

type SelectOption = {
    value: string;
    label: string;
}

type SelectOptionGrouped = {
    label: string;
    options: SelectOption[];
}

const createOption = (label: string) => ({
    label,
    value: label
})

const createIdOption = (label: string, id: number) => ({
    label,
    value: id.toString()
});

const createGroupedOption = (response: GetProductsResponse) => {
    return response.offerFamilies.map(of => {
        return {
            label: of.name,
            options: of.products.map(pr => {
                return {
                    value: pr.id.toString(),
                    label: pr.name
                }
            })
        }
    })
}

const blankBrandedChannel : BrandedChannelForm = { valid: true, validationError: "", businessLineChannelId: 0, businessLineChannelIdError: "", name: "", nameError: "", description: "", descriptionError: "", customerTypes: [], customerTypesError: "", countries: [], countriesError: "", products: [], productsError: "" };

const customerTypeOptions : SelectOption[] = [
    createOption(CustomerType.b2b),
    createOption(CustomerType.b2b2c),
    createOption(CustomerType.b2c),
    createOption(CustomerType.thirdParty)
]

export default function BrandedChannelModal({ show, handleClose, brandedChannel, channels, businessLineId, businessLineName, reload } : BrandedChannelModalProps) {
    const appContext = useContext(AppContext);
    
    const [form, setForm] = useState(blankBrandedChannel);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<SelectOption[]>([]);
    const [products, setProducts] = useState<SelectOptionGrouped[]>([]);
    
    const apiLoad = useCallback(
        async () => {
            if (businessLineId > 0)
            {
                setLoading(true);
                
                let countriesRes = await getBusinessLineCountries(businessLineId, false);

                if (countriesRes.success && countriesRes.body) {
                    setCountries(countriesRes.body.businessLineCountries.sort((a,b) => a.country.name < b.country.name ? -1 : 1 ).map(ct => createIdOption(ct.country.name, ct.country.id)));
                }
                else if (countriesRes.message) {
                    appContext?.setMessage({ show: true, message: countriesRes.message, status: countriesRes.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }

                let productsRes = await getProductsForBusinessLine(businessLineId);
                if (productsRes.success && productsRes.body) {
                    setProducts(createGroupedOption(productsRes.body));
                }
                else if (productsRes.message) {
                    appContext?.setMessage({ show: true, message: productsRes.message, status: productsRes.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }

                setLoading(false);
            }
        },
        [businessLineId, appContext]
    );

    useEffect(() => {
        apiLoad();

        if (brandedChannel) {
            setForm({
                ...form,
                businessLineChannelId: brandedChannel.businessLineChannelId,
                name: brandedChannel.name,
                description: brandedChannel.description,
                customerTypes: brandedChannel.customerTypes.map(val => createOption(val)),
                countries: brandedChannel.countries.map(ct => ct.id.toString()),
                products: brandedChannel.products.map(pr => pr.id.toString())
            });
        }
        else {
            setForm(blankBrandedChannel);
        }
    }, [brandedChannel, businessLineId]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };

        if (form.businessLineChannelId < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.businessLineChannelIdError = "Channel must be selected.";
        }
        
        if (form.name.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = "Branded Channel name must be provided.";
        }
        else if (form.name.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = `Branded Channel name must be no more than 100 characters. [currently ${form.name.length} characters]`;
        }

        if (form.description.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.descriptionError = `Branded Channel description must be no more than 250 characters. [currently ${form.description.length} characters]`;
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> | ApiResponse<BrandedChannel> = { success: false };

            if (brandedChannel?.id) {
                res = await putBrandedChannel(brandedChannel.id, {
                    businessLineChannelId: form.businessLineChannelId,
                    name: form.name,
                    description: form.description,
                    customerTypes: form.customerTypes.map(opt => opt.value),
                    countries: form.countries.map(opt => parseInt(opt)),
                    products: form.products.map(opt => parseInt(opt))
                });
            }
            else {
                res = await postBrandedChannel({
                    businessLineChannelId: form.businessLineChannelId,
                    name: form.name,
                    description: form.description,
                    customerTypes: form.customerTypes.map(opt => opt.value),
                    countries: form.countries.map(opt => parseInt(opt)),
                    products: form.products.map(opt => parseInt(opt))
                });
            }

            if (res && res.success) {
                formUpdate = blankBrandedChannel;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleSelectChange = (newValue : MultiValue<SelectOption>, meta : ActionMeta<SelectOption>) => {
        if (meta.name) {
            setForm({
                ...form,
                valid: true,
                validationError: "",
                businessLineChannelIdError: "",
                nameError: "",
                descriptionError: "",
                customerTypesError: "",
                countriesError: "",
                productsError: "",
                [meta.name]: newValue
            });
        }
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            businessLineChannelIdError: "",
            nameError: "",
            descriptionError: "",
            customerTypesError: "",
            countriesError: "",
            productsError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleSingleSelectChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            businessLineChannelIdError: "",
            nameError: "",
            descriptionError: "",
            customerTypesError: "",
            countriesError: "",
            productsError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleCountryChange = (newValue: string[]) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            customerTypesError: "",
            countriesError: "",
            productsError: "",
            countries: newValue
        });
    }

    const handleProductChange = (newValue: string[]) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            customerTypesError: "",
            countriesError: "",
            productsError: "",
            products: newValue
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankBrandedChannel); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="branded-channel-modal-header">
                <ChannelsIcon className="branded-channel-modal-icon"/>
                <div className="branded-channel-modal-title">
                    {brandedChannel ? `Edit "${brandedChannel.name}" details` 
                    : `Add new Branded Channel`}
                </div>
            </Modal.Header>
            <Modal.Body className="branded-channel-modal-body">
                <Form>
                    <Tabs defaultActiveKey="general">
                        <Tab eventKey="general" title="General">
                            <Form.Group className="mb-3 mt-3" controlId="formGroupBL">
                                <Form.Label>Business Line*</Form.Label>
                                <Form.Control
                                    placeholder="Select Business Line"
                                    disabled
                                    value={businessLineName}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupChannel">
                                <Form.Label>Channel*</Form.Label>
                                <Form.Select 
                                    value={form.businessLineChannelId}
                                    name="businessLineChannelId"
                                    onChange={handleSingleSelectChange}
                                    isInvalid={form.businessLineChannelIdError.length > 0}
                                    disabled={loading}
                                    className={form.businessLineChannelId > 0 ? "" : "branded-channel-modal-placeholder"}
                                >
                                    <option key="ch-0" value={0} selected disabled hidden>Select a Channel</option>
                                    {channels.map((ch, i) =>
                                        <option 
                                            key={`ch-${i}`} 
                                            value={ch.businessLineChannel ? ch.businessLineChannel.id : 0}
                                            className="branded-channel-modal-selected"
                                        >
                                            {ch.name}
                                        </option>
                                    )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {form.businessLineChannelIdError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupName">
                                <Form.Label>Branded Channel Name*</Form.Label>
                                <Form.Control  
                                    type="text"
                                    placeholder="Enter Branded Channel name"
                                    value={form.name}
                                    name="name"
                                    onChange={handleChange}
                                    isInvalid={form.nameError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.nameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control  
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter Branded Channel description"
                                    value={form.description}
                                    name="description"
                                    onChange={handleChange}
                                    isInvalid={form.descriptionError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.descriptionError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupCT">
                                <Form.Label>Customer Types</Form.Label>
                                <Select
                                    styles={{ 
                                        placeholder: (base) => ({ ...base, color: '#6c757d' }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        dropdownIndicator: (base) => ({ ...base, color: '#242424', "&:hover": { color: '#242424'} }) 
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    menuPlacement="auto"
                                    placeholder="Select all applicable customer types"
                                    value={form.customerTypes}
                                    name="customerTypes"
                                    isMulti
                                    maxMenuHeight={168}
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(opt : SelectOption) => opt.label}
                                    getOptionValue={(opt : SelectOption) => opt.value}
                                    options={customerTypeOptions}
                                    onChange={handleSelectChange}
                                />
                            </Form.Group>
                        </Tab>
                        <Tab eventKey="countries" title="Countries">
                            <Form.Group className="mb-3 mt-3 branded-channel-modal-dlist" controlId="formGroupCountries">
                                <Form.Label>Countries that the Branded Channel is available in</Form.Label>
                                <DualListbox
                                    options={countries}
                                    selected={form.countries}
                                    setSelected={handleCountryChange}
                                />
                            </Form.Group>
                        </Tab>
                        <Tab eventKey="products" title="Products">
                            <Form.Group className="mb-3 mt-3 branded-channel-modal-dlist" controlId="formGroupProducts">
                                <Form.Label>Products accessed via the Branded Channel</Form.Label>
                                <DualListbox
                                    options={products}
                                    selected={form.products}
                                    setSelected={handleProductChange}
                                />
                            </Form.Group>
                        </Tab>
                    </Tabs>
                </Form>            
            </Modal.Body>
            <Modal.Footer className="branded-channel-modal-footer">
                <div className="branded-channel-modal-error">
                    {!form.valid && <><ExclamationCircle className="branded-channel-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="branded-channel-modal-button-container">
                    <Button
                        className="branded-channel-modal-button"
                        onClick={() => { setForm(blankBrandedChannel); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "branded-channel-modal-button-loading" : "branded-channel-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}