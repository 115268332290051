import { useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Card, Button, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import TagManager from 'react-gtm-module';

import "./Channels.scss";
import Banner, { BannerProps } from "../../components/Banner/Banner";
import Spinner from "../../components/Spinner/Spinner";
import { ApiResponse, BrandedChannel, BrandedChannelWithBusinessLineChannelId, ChannelWithBusinessLineChannel, GenericEntity } from "../../common/interfaces";
import { AppContext } from "../../services/context/contextProvider";
import { ArrowDownCircle, ArrowUpCircle, ClockHistory, DashCircle, PencilSquare, PlusCircle, ThreeDots, Trash } from "react-bootstrap-icons";
import getBusinessLineChannels, { GetBusinessLineChannelsResponse } from "../../services/api/businessLineChannels/getBusinessLineChannels";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { Link, useLocation } from "react-router-dom";
import deactivateBusinessLineChannel from "../../services/api/businessLineChannels/deactivateBusinessLineChannel";
import promoteBrandedChannel from "../../services/api/brandedChannels/promoteBrandedChannel";
import demoteBrandedChannel from "../../services/api/brandedChannels/demoteBrandedChannel";
import deleteBrandedChannel from "../../services/api/brandedChannels/deleteBrandedChannel";
import BusinessLineChannelModal from "../../components/BusinessLineChannelModal/BusinessLineChannelModal";
import BrandedChannelModal from "../../components/BrandedChannelModal/BrandedChannelModal";
import { MessageStatus } from "../../common/appConstants";
import BusinessLineChannelHistoryModal from "../../components/BusinessLineChannelHistoryModal/BusinessLineChannelHistoryModal";
import BrandedChannelHistoryModal from "../../components/BrandedChannelHistoryModal/BrandedChannelHistoryModal";

type ChannelsProps = {
    loading: boolean;
    bannerProps: BannerProps;
    entity: GenericEntity;
    businessLineLink: string;
}

const tagManagerArgs = {
    dataLayer: {
        page: 'Channels'
    },
    dataLayerName: 'PageDataLayer'
}

export default function Channels({ loading, bannerProps, entity, businessLineLink }: ChannelsProps) {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'Channels Views',
            value: 'Channels',
            page: {
                location: pageLocation,
                title: "Channels"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "Channels",
                value: "Channels",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const maxActiveBreadcrumbLength = 43;
    const maxChannelNameLength = 20;

    const [board, setBoard] = useState<GetBusinessLineChannelsResponse>();
    const [boardLoading, setBoardLoading] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [businessLineChannelModalShow, setBusinessLineChannelModalShow] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState<ChannelWithBusinessLineChannel>();
    const [brandedChannelModalShow, setBrandedChannelModalShow] = useState(false);
    const [selectedBrandedChannel, setSelectedBrandedChannel] = useState<BrandedChannelWithBusinessLineChannelId>();
    const [historyModalShow, setBlcHistoryModalShow] = useState(false);
    const [selectedHistoryBlc, setSelectedHistoryBlc] = useState<ChannelWithBusinessLineChannel>();
    const [bHistoryModalShow, setBrcHistoryModalShow] = useState(false);
    const [selectedHistoryBrc, setSelectedHistoryBrc] = useState<BrandedChannel>();

    const apiLoad = useCallback(
        async () => {
            if (entity.id > 0) {
                setBoardLoading(true);
                setBoard(undefined);

                let res = await getBusinessLineChannels(entity.id);

                if (res.success && res.body) {
                    setBoard(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
                setBoardLoading(false);
            }
        },
        [entity, appContext]
    );

    useEffect(() => {
        apiLoad();
    }, [entity]); // eslint-disable-line react-hooks/exhaustive-deps

    const addBrandedChannel = () => {
        setSelectedBrandedChannel(undefined);
        setBrandedChannelModalShow(true);
    }

    const editBusinessLineChannel = (ch: ChannelWithBusinessLineChannel) => {
        document.body.click(); // to hide Popover
        setSelectedChannel(ch);
        setBusinessLineChannelModalShow(true);
    }

    const deactivateChannel = async (id: number = 0) => {
        setBoardLoading(true);
        document.body.click(); // to hide Popover

        let res = await deactivateBusinessLineChannel(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setBoardLoading(false);
    }

    const activateChannel = (ch: ChannelWithBusinessLineChannel) => {
        document.body.click(); // to hide Popover
        setSelectedChannel(ch);
        setBusinessLineChannelModalShow(true);
    }

    const moveBrandedChannel = async (id: number, moveUp: boolean) => {
        setBoardLoading(true);
        document.body.click(); // to hide Popover
        let res: ApiResponse<null> = { success: false, message: "Nothing assigned to be moved!" };

        if (moveUp) {
            res = await promoteBrandedChannel(id);
        }
        else {
            res = await demoteBrandedChannel(id);
        }

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setBoardLoading(false);
    }

    const editBrandedChannel = (bc: BrandedChannel, blcId: number = 0) => {
        document.body.click(); // to hide Popover
        setSelectedBrandedChannel({
            id: bc.id,
            businessLineChannelId: blcId,
            name: bc.name,
            description: bc.description,
            customerTypes: bc.customerTypes,
            countries: bc.countries,
            products: bc.products
        });
        setBrandedChannelModalShow(true);
    }

    const confirmDeleteBrandedChannel = (message: string, id: number) => {
        document.body.click(); // to hide Popover
        setConfirmModalMessage(`Are you sure you want to delete ${message}?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    };

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);

        let res = await deleteBrandedChannel(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const renderTooltip = (text: string) => (
        <div className="tooltip-text-viewer">
            {text && text}
        </div>
    );

    const viewBusinessLineChannelHistory = (ch?: ChannelWithBusinessLineChannel) => {
        document.body.click(); // to hide Popover
        setSelectedHistoryBlc(ch);
        setBlcHistoryModalShow(true)
    }

    const viewBrandedChannelHistory = (br?: BrandedChannel) => {
        document.body.click(); // to hide Popover
        setSelectedHistoryBrc(br);
        setBrcHistoryModalShow(true)
    }

    return (
        <div className="content-container">
            {appContext?.auth.isEditingActive &&
                <>
                    <ConfirmModal
                        show={confirmModalShow}
                        message={confirmModalMessage}
                        callback={confirmModalCallback}
                        handleClose={() => { setConfirmModalShow(false) }}
                        loading={confirmModalLoading}
                    />

                    <BusinessLineChannelModal
                        show={businessLineChannelModalShow}
                        handleClose={() => { setBusinessLineChannelModalShow(false); setSelectedChannel(undefined) }}
                        channel={selectedChannel}
                        businessLineId={entity.id}
                        businessLineName={entity.name}
                        reload={apiLoad}
                    />

                    <BrandedChannelModal
                        show={brandedChannelModalShow}
                        handleClose={() => { setBrandedChannelModalShow(false); setSelectedBrandedChannel(undefined) }}
                        brandedChannel={selectedBrandedChannel}
                        channels={board ? board.channels.filter(ch => ch.businessLineChannel) : []}
                        businessLineId={entity.id}
                        businessLineName={entity.name}
                        reload={apiLoad}
                    />

                    {historyModalShow &&
                        <BusinessLineChannelHistoryModal
                            show={historyModalShow}
                            handleClose={() => { setBlcHistoryModalShow(false); setSelectedHistoryBlc(undefined) }}
                            ch={selectedHistoryBlc}
                        />
                    }

                    {bHistoryModalShow &&
                        <BrandedChannelHistoryModal
                            show={bHistoryModalShow}
                            handleClose={() => { setBrcHistoryModalShow(false); setSelectedHistoryBrc(undefined) }}
                            br={selectedHistoryBrc}
                        />
                    }
                </>
            }

            <Banner
                titleIcon={bannerProps.titleIcon}
                title1={bannerProps.title1 && bannerProps.title1}
                title2={bannerProps.title1 && "Channel Board"}
                breadcrumbs={bannerProps.breadcrumbs}
                breadcrumbActive={bannerProps.breadcrumbs && bannerProps.breadcrumbActive ?
                    `${bannerProps.breadcrumbActive.substring(0, maxActiveBreadcrumbLength) + " ..."} > Channels`
                    :
                    bannerProps.breadcrumbs && "Channels"}
                subtitle1={bannerProps.subtitle1}
                subtitle2={bannerProps.subtitle2}
            />

            <Container className="screen-container p-3">
                {(loading || boardLoading) ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        {appContext?.auth.isEditingActive &&
                            <Row className="pb-1">
                                <Col className="channels-button-container" xs={12}>
                                    <Button
                                        variant="outline-primary"
                                        onClick={addBrandedChannel}
                                    >
                                        <PlusCircle className="add-button-icon" /> Branded Channel
                                    </Button>
                                </Col>
                            </Row>
                        }
                        <Row xs={4} className="channels-card-row mt-1 pb-3">
                            {board && board.channels.map((ch, i) =>
                                <Col key={`ch-${i}`} className="channels-card-col channels-flip-box">
                                    <div className={ch.businessLineChannel ? ch.businessLineChannel.brandedChannels.length > 0 ? "channels-card-front" : "channels-card-front-noflip" : "channels-card-front-noflip"}
                                        hidden={(appContext?.auth.isEditingActive) && (ch.businessLineChannel ? ch.businessLineChannel.brandedChannels.length > 0 : false)}>
                                        <Card
                                            className={ch.businessLineChannel?.isActive ?
                                                ch.businessLineChannel.brandedChannels.length > 0 ? "channels-card-empty" : "channels-card-empty"
                                                : "channels-card"}
                                        >
                                            <Card.Body className="channels-card-body">
                                                <div className="channels-card-header">
                                                    <div>
                                                        {ch.businessLineChannel?.isActive ?
                                                            ch.name.length > maxChannelNameLength ?
                                                                <OverlayTrigger placement={"bottom"} overlay={renderTooltip(ch.name)} transition={true} >
                                                                    <Link to={`/business-lines/${businessLineLink}/channels/${ch.link}/channel-canvas`} className="channels-channel-link channels-channel-title">
                                                                        {ch.name}
                                                                    </Link>
                                                                </OverlayTrigger>
                                                                :
                                                                <Link to={`/business-lines/${businessLineLink}/channels/${ch.link}/channel-canvas`} className="channels-channel-link channels-channel-title">
                                                                    {ch.name}
                                                                </Link>
                                                            :
                                                            <span className="channels-channel-title">
                                                                {ch.name}
                                                            </span>
                                                        }
                                                        {appContext?.auth.isEditingActive &&
                                                            <OverlayTrigger
                                                                trigger="click"
                                                                placement="right"
                                                                rootClose
                                                                overlay={
                                                                    <Popover>
                                                                        <PopoverBody className="channels-action-container">
                                                                            {ch.businessLineChannel?.isActive ?
                                                                                <>
                                                                                    <div
                                                                                        className="channels-action-icon"
                                                                                        onClick={() => editBusinessLineChannel(ch)}
                                                                                        title="edit Business Line Channel details"
                                                                                    >
                                                                                        <PencilSquare />
                                                                                    </div>
                                                                                    <div
                                                                                        className="channels-action-icon"
                                                                                        onClick={() => viewBusinessLineChannelHistory(ch)}
                                                                                        title={`View Business Line Channel history`}
                                                                                    >
                                                                                        <ClockHistory />
                                                                                    </div>
                                                                                    <div
                                                                                        className="channels-action-icon"
                                                                                        onClick={() => deactivateChannel(ch.businessLineChannel?.id)}
                                                                                        title="deactivate Channel"
                                                                                    >
                                                                                        <DashCircle />
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <div
                                                                                    className="channels-action-icon"
                                                                                    onClick={() => activateChannel(ch)}
                                                                                    title="activate Channel"
                                                                                >
                                                                                    <PlusCircle />
                                                                                </div>
                                                                            }
                                                                        </PopoverBody>
                                                                    </Popover>
                                                                }
                                                            >
                                                                <ThreeDots className="channels-action-icon" />
                                                            </OverlayTrigger>
                                                        }
                                                        <div className="channels-card-ellipse" hidden={ch.businessLineChannel ? ch.businessLineChannel.brandedChannels.length === 0 : true}>...</div></div>
                                                </div >
                                            </Card.Body >
                                        </Card >
                                    </div>
                                    <div className={appContext?.auth.isEditingActive ? "channels-card-back-noflip" : "channels-card-back"}
                                        hidden={ch.businessLineChannel ? ch.businessLineChannel.brandedChannels.length === 0 : true}>
                                        <Card
                                            className={"channels-card-active"}
                                        >
                                            <Card.Body className="channels-card-body">
                                                <div className="channels-card-back-header">
                                                    {ch.businessLineChannel?.isActive ?
                                                        ch.name.length > maxChannelNameLength ?
                                                            <OverlayTrigger placement={"bottom"} overlay={renderTooltip(ch.name)} transition={true} >
                                                                <Link to={`/business-lines/${businessLineLink}/channels/${ch.link}/channel-canvas`} className="channels-channel-link channels-channel-title">
                                                                    {ch.name}
                                                                </Link>
                                                            </OverlayTrigger>
                                                            :
                                                            <Link to={`/business-lines/${businessLineLink}/channels/${ch.link}/channel-canvas`} className="channels-channel-link channels-channel-title">
                                                                {ch.name}
                                                            </Link>
                                                        :
                                                        <div className="channels-channel-title">
                                                            {ch.name}
                                                        </div>
                                                    }
                                                    {appContext?.auth.isEditingActive &&
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            placement="right"
                                                            rootClose
                                                            overlay={
                                                                <Popover>
                                                                    <PopoverBody className="channels-action-container">
                                                                        {ch.businessLineChannel?.isActive ?
                                                                            <>
                                                                                <div
                                                                                    className="channels-action-icon"
                                                                                    onClick={() => editBusinessLineChannel(ch)}
                                                                                    title="edit Business Line Channel details"
                                                                                >
                                                                                    <PencilSquare />
                                                                                </div>
                                                                                <div
                                                                                    className="channels-action-icon"
                                                                                    onClick={() => viewBusinessLineChannelHistory(ch)}
                                                                                    title={`View Business Line Channel history`}
                                                                                >
                                                                                    <ClockHistory />
                                                                                </div>
                                                                                <div
                                                                                    className="channels-action-icon"
                                                                                    onClick={() => deactivateChannel(ch.businessLineChannel?.id)}
                                                                                    title="deactivate Channel"
                                                                                >
                                                                                    <DashCircle />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <div
                                                                                className="channels-action-icon"
                                                                                onClick={() => activateChannel(ch)}
                                                                                title="activate Channel"
                                                                            >
                                                                                <PlusCircle />
                                                                            </div>
                                                                        }
                                                                    </PopoverBody>
                                                                </Popover>
                                                            }
                                                        >
                                                            <ThreeDots className="channels-action-icon" />
                                                        </OverlayTrigger>
                                                    }
                                                </div >
                                                <div>
                                                    {ch.businessLineChannel && ch.businessLineChannel.brandedChannels.map((bc, j) =>
                                                        <>
                                                            <div key={`bc-${i}-${j}`} className="channels-branded-channel">
                                                                {bc.name.length > 37 ?
                                                                    <OverlayTrigger placement={"bottom"} overlay={renderTooltip(bc.name)} transition={true} >
                                                                        <Link to={`/business-lines/${businessLineLink}/channels/${ch.link}/${bc.link}/channel-canvas`} className="channels-branded-channel-link">
                                                                            {bc.name}
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                    :
                                                                    <Link to={`/business-lines/${businessLineLink}/channels/${ch.link}/${bc.link}/channel-canvas`} className="channels-branded-channel-link">
                                                                        {bc.name}
                                                                    </Link>
                                                                }
                                                                {appContext?.auth.isEditingActive &&
                                                                    <OverlayTrigger
                                                                        trigger="click"
                                                                        placement="right"
                                                                        rootClose
                                                                        overlay={
                                                                            <Popover>
                                                                                <PopoverBody className="channels-action-container">
                                                                                    {j !== 0 &&
                                                                                        <div
                                                                                            className="channels-action-icon"
                                                                                            onClick={() => moveBrandedChannel(bc.id, true)}
                                                                                            title="move up in order"
                                                                                        >
                                                                                            <ArrowUpCircle />
                                                                                        </div>
                                                                                    }
                                                                                    {j + 1 !== ch.businessLineChannel?.brandedChannels.length &&
                                                                                        <div
                                                                                            className="channels-action-icon"
                                                                                            onClick={() => moveBrandedChannel(bc.id, false)}
                                                                                            title="move down in order"
                                                                                        >
                                                                                            <ArrowDownCircle />
                                                                                        </div>
                                                                                    }
                                                                                    <div
                                                                                        className="channels-action-icon"
                                                                                        onClick={() => editBrandedChannel(bc, ch.businessLineChannel?.id)}
                                                                                        title="edit Branded Channel details"
                                                                                    >
                                                                                        <PencilSquare />
                                                                                    </div>
                                                                                    <div
                                                                                        className="channels-action-icon"
                                                                                        onClick={() => viewBrandedChannelHistory(bc)}
                                                                                        title={`View Branded Channel history`}
                                                                                    >
                                                                                        <ClockHistory />
                                                                                    </div>
                                                                                    <div
                                                                                        className="channels-action-icon"
                                                                                        onClick={() => confirmDeleteBrandedChannel(bc.name, bc.id)}
                                                                                        title="delete Branded Channel"
                                                                                    >
                                                                                        <Trash />
                                                                                    </div>
                                                                                </PopoverBody>
                                                                            </Popover>
                                                                        }
                                                                    >
                                                                        <ThreeDots className="channels-action-icon" />
                                                                    </OverlayTrigger>
                                                                }
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </Card.Body >
                                        </Card >
                                    </div>
                                </Col >
                            )
                            }
                        </Row >
                    </>
                }
            </Container >
        </div >
    );
}