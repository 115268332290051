import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Envelope } from "react-bootstrap-icons";
import { Config } from "../../common/appConstants";
import './FeedbackModal.scss';

export interface FeedbackModalProps {
    show: boolean;
    handleClose: () => void;
};

export default function FeedbackModal({ show, handleClose } : FeedbackModalProps) {
    const [content, setContent] = useState("");
    const [convertedContent, setConvertedContent] = useState("");

    const convertContent = (value : string) => {
        setContent(value);
        var converted = value.replaceAll("\n", "%0D%0A");
        setConvertedContent(converted);
    }

    useEffect(() => {
        if (show) convertContent("");
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            size="lg"
        >
            <Modal.Header className="feedback-modal-header">
                <Envelope />
            </Modal.Header>
            <Modal.Body className="feedback-modal-body">
                <div className="feedback-modal-title">
                    We love to hear your ideas!
                </div>
                <div className="feedback-modal-subtitle">
                    What would you like that would make the PLM Tool better?
                </div>
                <Form.Group 
                    className="feedback-modal-content mb-3" 
                    controlId="formBody"
                >
                    <Form.Control  
                        as="textarea"
                        rows={4}
                        placeholder="It would be great if..."
                        value={content}
                        onChange={(e) => convertContent(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer className="feedback-modal-footer">
                <Button
                    className="feedback-modal-button"
                    onClick={handleClose}
                    variant="outline-secondary"
                >
                    
                    Cancel
                </Button>
                <a 
                    href={`mailto:${Config.feedbackEmail}?subject=PLM Tool Feedback&body=${convertedContent}`}
                >
                    <Button
                        className="feedback-modal-button"
                        variant="outline-primary"
                        onClick={handleClose}
                    >
                        Send Email
                    </Button>
                </a>
            </Modal.Footer>
        </Modal>
    );
}