import 'remirror/styles/all.css';
import './TextEditor.scss';

import { BoldExtension, HeadingExtension, UnderlineExtension, ItalicExtension, LinkExtension, BulletListExtension, OrderedListExtension, HardBreakExtension, StrikeExtension, createMarkPositioner, ShortcutHandlerProps } from 'remirror/extensions';
import { ComponentItem, EditorComponent, FloatingToolbar, FloatingWrapper, Remirror, ToolbarItemUnion, useActive, useAttrs, useChainedCommands, useCommands, useCurrentSelection, useExtension, useRemirror, useUpdateReason } from '@remirror/react';
import { Button } from 'react-bootstrap';
import { ListOl, ListUl } from 'react-bootstrap-icons';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { prosemirrorNodeToHtml } from 'remirror';
import Spinner from '../Spinner/Spinner';

interface TextEditorProps {
    content: string;
    save: (content: string) => void;
    cancel: () => void;
    loading: boolean;
}

interface MenuProps {
    save: () => void;
    cancel: () => void;
    loading: boolean;
}

export const TextEditor = ({ content, save, cancel, loading } : TextEditorProps) => {
    const { manager, state } = useRemirror({
        extensions: extensions,
        content: content,
        selection: 'start',
        stringHandler: 'html'
    });

    const saveContent = () => {
        save(prosemirrorNodeToHtml(manager.view.state.doc));
    }

    return (
        <div className='remirror-theme'>
            <Remirror manager={manager} initialContent={state}>
                <EditorComponent />
                <FloatingLinkToolbar />
                <Menu save={saveContent} cancel={cancel} loading={loading}/>
            </Remirror>
        </div>
    );
};

const Menu = ({ save, cancel, loading } : MenuProps) => {
    const commands = useCommands();
    const active = useActive();
  
    return (
        <div className='text-editor-button-container'>
            <div>
                <Button
                    onClick={() => {
                        commands.toggleBold();
                        commands.focus();
                    }}
                    className={ active.bold() ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Bold'
                >
                    <b>B</b>
                </Button>
                <Button
                    onClick={() => {
                        commands.toggleItalic();
                        commands.focus();
                    }}
                    className={ active.italic() ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Italic'
                >
                    <i>I</i>
                </Button>
                <Button
                    onClick={() => {
                        commands.toggleUnderline();
                        commands.focus();
                    }}
                    className={ active.underline() ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Underline'
                >
                    <u>U</u>
                </Button>
                <Button
                    onClick={() => {
                        commands.toggleStrike();
                        commands.focus();
                    }}
                    className={ active.strike() ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Strikethrough'
                >
                    <s>S</s>
                </Button>
                <Button
                    onClick={() => {
                        commands.toggleHeading({ level: 3 });
                        commands.focus();
                    }}
                    className={ active.heading({ level: 3 }) ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Heading'
                >
                    H
                </Button>
                <Button
                    onClick={() => {
                        commands.toggleBulletList();
                        commands.focus();
                    }}
                    className={ active.bulletList() ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Bulleted list'
                >
                    <ListUl className='text-editor-button-icon'/>
                </Button>
                <Button
                    onClick={() => {
                        commands.toggleOrderedList();
                        commands.focus();
                    }}
                    className={ active.orderedList() ? 'text-editor-button-active' : 'text-editor-button' }
                    title='Numbered list'
                >
                    <ListOl className='text-editor-button-icon'/>
                </Button>
            </div>
            <div>
                <Button
                    className="text-editor-button-big"
                    onClick={cancel}
                    variant="outline-secondary"
                >
                    Cancel
                </Button>
                <Button 
                    className={loading ? "text-editor-button-big-loading" : "text-editor-button-big"}
                    onClick={save}
                    variant="outline-primary"
                    disabled={loading}
                >
                    {loading ? <Spinner/> : "Save"}
                </Button>
            </div>
        </div>
    );
};

function useLinkShortcut() {
    const [linkShortcut, setLinkShortcut] = useState<ShortcutHandlerProps | undefined>();
    const [isEditing, setIsEditing] = useState(false);
  
    useExtension(
        LinkExtension,
        ({ addHandler }) =>
            addHandler('onShortcut', (props) => {
            if (!isEditing) {
                setIsEditing(true);
            }
    
            return setLinkShortcut(props);
            }),
        [isEditing],
    );
  
    return { linkShortcut, isEditing, setIsEditing };
}
  
function useFloatingLinkState() {
    const chain = useChainedCommands();
    const { isEditing, linkShortcut, setIsEditing } = useLinkShortcut();
    const { to, empty } = useCurrentSelection();
  
    const url = (useAttrs().link()?.href as string) ?? '';
    const [href, setHref] = useState<string>(url);
  
    // A positioner which only shows for links.
    const linkPositioner = useMemo(() => createMarkPositioner({ type: 'link' }), []);
  
    const onRemove = useCallback(() => {
        return chain.removeLink().focus().run();
    }, [chain]);
  
    const updateReason = useUpdateReason();
  
    useLayoutEffect(() => {
        if (!isEditing) {
            return;
        }
  
        if (updateReason.doc || updateReason.selection) {
            setIsEditing(false);
        }
    }, [isEditing, setIsEditing, updateReason.doc, updateReason.selection]);
  
    useEffect(() => {
        setHref(url);
    }, [url]);
  
    const submitHref = useCallback(() => {
        setIsEditing(false);
        const range = linkShortcut ?? undefined;
    
        if (href === '') {
            chain.removeLink();
        } else {
            chain.updateLink({ href, auto: false }, range);
        }
    
        chain.focus(range?.to ?? to).run();
    }, [setIsEditing, linkShortcut, chain, href, to]);
  
    const cancelHref = useCallback(() => {
        setIsEditing(false);
    }, [setIsEditing]);
  
    const clickEdit = useCallback(() => {
        if (empty) {
            chain.selectLink();
        }
    
        setIsEditing(true);
    }, [chain, empty, setIsEditing]);
  
    return useMemo(
        () => ({
            href,
            setHref,
            linkShortcut,
            linkPositioner,
            isEditing,
            clickEdit,
            onRemove,
            submitHref,
            cancelHref,
        }),
        [href, linkShortcut, linkPositioner, isEditing, clickEdit, onRemove, submitHref, cancelHref],
    );
  }
  
const FloatingLinkToolbar = () => {
    const { isEditing, linkPositioner, clickEdit, onRemove, submitHref, href, setHref, cancelHref } =
        useFloatingLinkState();
    const active = useActive();
    const activeLink = active.link();
    const { empty } = useCurrentSelection();
    const linkEditItems: ToolbarItemUnion[] = useMemo(
        () => [
            {
            type: ComponentItem.ToolbarGroup,
            label: 'Link',
            items: activeLink
                ? [
                    { type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'pencilLine' },
                    { type: ComponentItem.ToolbarButton, onClick: onRemove, icon: 'linkUnlink' },
                ]
                : [{ type: ComponentItem.ToolbarButton, onClick: () => clickEdit(), icon: 'link' }],
            },
        ],
        [clickEdit, onRemove, activeLink],
    );
  
    const items: ToolbarItemUnion[] = useMemo(() => linkEditItems, [linkEditItems]);
  
    return (
        <>
        <FloatingToolbar items={items} positioner='selection' placement='top' enabled={!isEditing} />
        <FloatingToolbar
            items={linkEditItems}
            positioner={linkPositioner}
            placement='bottom'
            enabled={!isEditing && empty}
        />
  
        <FloatingWrapper
            positioner='always'
            placement='bottom'
            enabled={isEditing}
            renderOutsideEditor
        >
            <input
                style={{ zIndex: 100 }}
                autoFocus
                placeholder='Enter link...'
                onChange={(event) => setHref(event.target.value)}
                value={href}
                onKeyPress={(event) => {
                const { code } = event;
    
                if (code === 'Enter') {
                    submitHref();
                }
    
                if (code === 'Escape') {
                    cancelHref();
                }
                }}
            />
        </FloatingWrapper>
      </>
    );
};

const extensions = () => [
    new BulletListExtension(),
    new OrderedListExtension(),
    new HeadingExtension(),
    new LinkExtension({ autoLink: false }),
    new BoldExtension(),
    new UnderlineExtension(),
    new ItalicExtension(),
    new StrikeExtension(),
    new HardBreakExtension()
];