// package imports
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { ClockHistory, PencilSquare } from "react-bootstrap-icons";
import { Col, Container, Row, Card } from "react-bootstrap";
import { DateTime } from "luxon";
import { useLocation, useParams } from "react-router-dom";
import TagManager from 'react-gtm-module';

// project imports
import "./ChannelCanvas.scss";
import Banner, { BannerProps } from "../../components/Banner/Banner";
import Spinner from "../../components/Spinner/Spinner";
import { ApiResponse, ArtefactField, ArtefactResponse, GenericEntity } from "../../common/interfaces";
import { AppContext } from "../../services/context/contextProvider";
import { HtmlRender } from "../../components/HtmlMapper/HtmlRender";
import TextEditorModal, { DataField } from "../../components/TextEditorModal/TextEditorModal";
import { EntityType, MessageStatus } from "../../common/appConstants";
import ChannelsIcon from "../../assets/images/icons/ChannelsIcon";
import getOfferFamiliesWithChannel, { GetOfferFamiliesWithChannelResponse } from "../../services/api/offerFamilies/getOfferFamiliesWithChannel";
import getOfferFamiliesWithBrandedChannel from "../../services/api/offerFamilies/getOfferFamiliesWithBrandedChannel";
import getChannelCanvas from "../../services/api/channelCanvas/getChannelCanvas";
import ChannelCanvasHistoryModal from "../../components/ChannelCanvasHistoryModal/ChannelCanvasHistoryModal";
import { ISidebar } from "../../components/Sidebar/Sidebar";
import getBusinessLines from "../../services/api/businessLines/getBusinessLines";

type ChannelCanvasParams = {
    businessLineLink: string,
    channelLink: string,
    brandedChannelLink?: string
}

const tagManagerArgs = {
    dataLayer: {
        page: 'ChannelCanvas'
    },
    dataLayerName: 'PageDataLayer'
}

export default function VisionBoard() {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'ChannelCanvas Views',
            value: 'ChannelCanvas',
            page: {
                location: pageLocation,
                title: "ChannelCanvas",
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "ChannelCanvas",
                value: "ChannelCanvas",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    let { businessLineLink, channelLink, brandedChannelLink } = useParams<ChannelCanvasParams>();

    const [bannerProps, setBannerProps] = useState<BannerProps>({});
    const [board, setBoard] = useState<ArtefactResponse>();
    const [entity, setEntity] = useState<GenericEntity>({ id: 0, name: "", type: "" });
    const [loading, setLoading] = useState(false);
    const [textEditorModalShow, setTextEditorModalShow] = useState(false);
    const [selectedDataField, setSelectedDataField] = useState<DataField>({ number: 0, name: "", content: "" });
    const [historyModalShow, setCcHistoryModalShow] = useState(false);
    const [selectedHistoryDataField, setSelectedHistoryDataField] = useState<DataField>({ number: 0, name: "", content: "" });

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            const sidebar: ISidebar = {
                options: {
                    businessLines: { list: [] }
                }
            }

            let blRes = await getBusinessLines();
            if (blRes.success && blRes.body) {
                sidebar.options.businessLines = {
                    list: blRes.body.businessLines.map(bl => {
                        return {
                            link: bl.link,
                            name: bl.name,
                            isActive: bl.link === businessLineLink
                        };
                    })
                };
            }
            else if (blRes.message) {
                appContext?.setMessage({ show: true, message: blRes.message, status: blRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            let ofRes: ApiResponse<GetOfferFamiliesWithChannelResponse>;
            if (brandedChannelLink) {
                ofRes = await getOfferFamiliesWithBrandedChannel(businessLineLink, channelLink, brandedChannelLink);
            }
            else {
                ofRes = await getOfferFamiliesWithChannel(businessLineLink, channelLink);
            }

            if (ofRes.success && ofRes.body) {
                sidebar.options.selectedBusinessLine = {
                    link: ofRes.body.businessLine.link,
                    name: ofRes.body.businessLine.name,
                    offerFamilies: ofRes.body.businessLine.offerFamilies.map(of => {
                        return {
                            link: of.link,
                            name: of.name
                        };
                    })
                }

                sidebar.options.selectedBusinessLineChannel = {
                    link: channelLink,
                    name: brandedChannelLink ? ofRes.body.channel.baseChannelName : ofRes.body.channel.name,
                    brandedChanels: ofRes.body.channel.brandedChannels.map(bc => {
                        return {
                            link: bc.link,
                            name: bc.name,
                            isActive: bc.link === brandedChannelLink
                        }
                    })
                }

                let bannerProps: BannerProps = {
                    titleIcon: <ChannelsIcon />,
                    title1: ofRes.body.channel.name,
                    title2: "Channel Canvas",
                    breadcrumbs: brandedChannelLink ?
                        [{ link: "/", displayName: "Home" }, { link: "/business-lines", displayName: "Business Lines" }, { link: `/business-lines/${ofRes.body.businessLine.link}`, displayName: ofRes.body.businessLine.name }, { link: `/business-lines/${ofRes.body.businessLine.link}/channels`, displayName: "Channels" }, { link: `/business-lines/${businessLineLink}/channels/${channelLink}/channel-canvas`, displayName: ofRes.body.channel.baseChannelName }]
                        : [{ link: "/", displayName: "Home" }, { link: "/business-lines", displayName: "Business Lines" }, { link: `/business-lines/${ofRes.body.businessLine.link}`, displayName: ofRes.body.businessLine.name }, { link: `/business-lines/${ofRes.body.businessLine.link}/channels`, displayName: "Channels" }],
                    breadcrumbActive: ofRes.body.channel.name,
                    subtitle1: {
                        field: "Head of Product",
                        value: ofRes.body.businessLine.headOfProduct,
                    }
                }
                setBannerProps(bannerProps);

                if (brandedChannelLink) {
                    setEntity({ id: ofRes.body.channel.id, name: ofRes.body.channel.name, type: EntityType.brandedChannel });
                }
                else {
                    setEntity({ id: ofRes.body.channel.id, name: ofRes.body.channel.name, type: EntityType.businessLineChannel });
                }

                if (ofRes.body.channel.id > 0) {
                    setBoard(undefined);

                    let canvasRes = await getChannelCanvas(
                        brandedChannelLink ? EntityType.brandedChannel : EntityType.businessLineChannel,
                        ofRes.body.channel.id);

                    if (canvasRes.success && canvasRes.body) {
                        setBoard(canvasRes.body);
                    }
                    else if (canvasRes.message) {
                        appContext?.setMessage({ show: true, message: canvasRes.message, status: canvasRes.status });
                    }
                    else {
                        appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                    }
                }
            }
            else if (ofRes.message) {
                appContext?.setMessage({ show: true, message: ofRes.message, status: ofRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }

            appContext?.setSidebar(sidebar);
            setLoading(false);
        },
        [brandedChannelLink, businessLineLink, channelLink, appContext]
    );

    useEffect(() => {
        setBannerProps({});
        setBoard(undefined);
        apiLoad();
    }, [brandedChannelLink, businessLineLink, channelLink]); // eslint-disable-line react-hooks/exhaustive-deps

    const editTextField = (number: number, name: string, data: string | undefined) => {
        setSelectedDataField({
            number: number,
            name: name,
            content: data ? data : ""
        });
        setTextEditorModalShow(true);
    }

    const viewDataHistory = (number: number, name: string, data: string | undefined) => {
        setSelectedHistoryDataField({
            number: number,
            name: name,
            content: data ? data : ""
        });
        setCcHistoryModalShow(true)
    }

    const renderCard = (number: number, field: ArtefactField): ReactNode => {
        return (
            <Col className="channel-canvas-card-col mt-1">
                <Card className="channel-canvas-card mt-1">
                    <Card.Header className="channel-canvas-card-header">
                        <div className="channel-canvas-title-head">{field.head}</div>
                        {appContext?.auth.isEditingActive &&
                            <div className="vision-board-title-action">
                                <div
                                    className="channel-canvas-action-icon"
                                    onClick={() => editTextField(number, field.head, field.data)}
                                    title={`edit ${field.head} details`}
                                >
                                    <PencilSquare />
                                </div>
                                <div
                                    className="channel-canvas-action-icon"
                                    onClick={() => viewDataHistory(number, field.head, field.data)}
                                    title={`view ${field.head} history`}
                                >
                                    <ClockHistory />
                                </div>
                            </div>
                        }
                    </Card.Header>
                    {field.data ?
                        <div className="channel-canvas-card-body channel-canvas-data">
                            <HtmlRender content={field.data} />
                        </div>
                        :
                        <div className="channel-canvas-card-body channel-canvas-placeholder">{field.def}</div>}
                </Card>
            </Col>
        )
    }

    return (
        <div className="content-container">
            {textEditorModalShow &&
                <TextEditorModal
                    show={textEditorModalShow}
                    handleClose={() => { setTextEditorModalShow(false) }}
                    dataField={selectedDataField}
                    board={board}
                    entity={entity}
                    reload={apiLoad}
                />
            }

            {historyModalShow &&
                <ChannelCanvasHistoryModal
                    show={historyModalShow}
                    handleClose={() => { setCcHistoryModalShow(false) }}
                    entityType={entity.type}
                    entityId={entity.id}
                    dataField={selectedHistoryDataField}
                />
            }

            <Banner
                titleIcon={bannerProps.titleIcon}
                title1={bannerProps.title1}
                title2={bannerProps.title2}
                breadcrumbs={bannerProps.breadcrumbs}
                breadcrumbActive={bannerProps.breadcrumbActive}
                subtitle1={bannerProps.subtitle1}
            />

            <Container className="screen-container p-3">
                {loading ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        {board &&
                            <>
                                <div className="channel-canvas-title">
                                    <div className="channel-canvas-title-content">
                                        <div className="channel-canvas-title-head">{board.field1.head}:</div>
                                        {board.field1.data ?
                                            <div className="channel-canvas-title-data">
                                                <HtmlRender content={board.field1.data} />
                                            </div>
                                            :
                                            <div className="channel-canvas-title-placeholder">{board.field1.def}</div>}
                                    </div>
                                    {appContext?.auth.isEditingActive &&
                                        <div className="channel-canvas-title-action">
                                            <div
                                                className="channel-canvas-action-icon"
                                                onClick={() => editTextField(1, board.field1.head, board.field1.data)}
                                                title={`edit ${board.field1.head} details`}
                                            >
                                                <PencilSquare />
                                            </div>
                                            <div
                                                className="channel-canvas-action-icon"
                                                onClick={() => viewDataHistory(1, board.field1.head, board.field1.data)}
                                                title={`view ${board.field1.head} history`}
                                            >
                                                <ClockHistory />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <Row xs={4} className="channel-canvas-card-row g-1 pb-3">
                                    {renderCard(2, board.field2)}
                                    {renderCard(3, board.field3)}
                                    {renderCard(4, board.field4)}
                                    {renderCard(5, board.field5)}
                                    {renderCard(6, board.field6)}
                                    {renderCard(7, board.field7)}
                                    {renderCard(8, board.field8)}
                                    {renderCard(9, board.field9)}
                                </Row>

                                <Row>
                                    <Col className="channel-canvas-footer" xs={12}>
                                        <div className="channel-canvas-footer-head">Last updated:</div>
                                        <div className="channel-canvas-footer-data">{board.lastUpdatedAt ?
                                            DateTime.fromISO(board.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED) : "-"}</div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                }
            </Container>
        </div>
    );
}