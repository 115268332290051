import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 172.18 172.18"
      {...props}
    >
      <defs><style>{`.cls-1{fill:none;stroke-miterlimit:10;stroke-width:4px;}`}</style></defs>
      <circle className="cls-1" cx="86.09" cy="86.09" r="84.09"/>
      <path className="cls-1" d="M115.58,381.27" transform="translate(-32.63 -211.15)"/>
      <line className="cls-1" x1="2" y1="86.09" x2="170.18" y2="86.09"/>
      <ellipse className="cls-1" cx="87.71" cy="86.06" rx="34.37" ry="84.06"/>
      <path className="cls-1" d="M58.41,237.56c11.51,19.54,114.19,16.07,120.59,0" transform="translate(-32.63 -211.15)"/>
      <path className="cls-1" d="M179,356.92c-11.52-19.55-114.2-16.07-120.59,0" transform="translate(-32.63 -211.15)"/>
    </svg>
  )
}

export default SvgComponent
