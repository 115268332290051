import { AppError, MessageStatus, Store } from '../../../common/appConstants';
import { ApiResponse, ArtefactResponse, MessageResponse } from '../../../common/interfaces';

export default async function getChannelCanvas(entityType: string, entityId: number) : Promise<ApiResponse<ArtefactResponse>> {
    try {
        const response = await fetch(`/api/channelcanvases/${entityType}/${entityId}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`
            }
        });
        if (response.status === 200) {
            const data : ArtefactResponse = await response.json();
            return {
                success: true,
                body: data
            };
        }
        else if (response.status < 500) {
            const data : MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg,
                status: MessageStatus.warning
            }
        }
        else {
            return {
                success: false,
                message: AppError.serverError,
                status: MessageStatus.error
            } 
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}