import { AppError, MessageStatus, Store } from '../../../common/appConstants';
import { ApiResponse, BusinessLineCountry, MessageResponse } from '../../../common/interfaces';

export interface GetProductCountryMatrixResponse {
    result: string;
    value: GetProductCountryMatrixResult;
}

export interface GetProductCountryMatrixResult {
    businessLineCountries: BusinessLineCountry[];
    productCountryMatrix: OfferFamilyProducts[];
}

export interface OfferFamilyProducts {
    offerFamilyId: number;
    offerFamilyName: string;
    offerFamilyLink: string;
    productCountries: ProductCountryMatrixRow[]
}

export interface ProductCountryMatrixRow {
    id: number;
    name: string;
    link: string;
    countryIndicators: boolean[];
}

export default async function getProductCountryMatrix(businessLineLink : string, offerFamilyLink : string) : Promise<ApiResponse<GetProductCountryMatrixResponse>> {
    try {

        var response;

        if (businessLineLink > '' && offerFamilyLink > '') {    
            response = await fetch(`/api/productcountries/businessline/${businessLineLink}/offerfamily/${offerFamilyLink}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`
            }
        })
        } else if (businessLineLink > '') {
            response = await fetch(`/api/productcountries/businessline/${businessLineLink}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`
            }
        })
        } else {
            return {
                success: false,
                message: 'A Business Line or Offer Family must be passed to getProductCountryMatrix',
                status: MessageStatus.error
            };
        }

        if (response.status === 200) {

            const data : GetProductCountryMatrixResponse = await response.json();

            return {
                success: true,
                body: data
            };
        }
        else if (response.status < 500) {
            const data : MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg,
                status: MessageStatus.warning
            }
        }
        else {
            return {
                success: false,
                message: AppError.serverError,
                status: MessageStatus.error
            } 
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}