import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="29" height="29" xmlns="http://www.w3.org/2000/svg" overflow="hidden">
            <defs>
                <clipPath id="clip0"><rect x="357" y="302" width="29" height="29" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip0)" transform="translate(-357 -302)">
                <path d="M372.225 322.965 371.379 322.119 377.119 316.379 377.965 317.225 372.225 322.965ZM369.265 320.004 375.004 314.265 375.85 315.11 370.11 320.85 369.265 320.004ZM367.15 317.89 372.89 312.15 373.735 312.996 367.996 318.735 367.15 317.89ZM365.458 311.667C364.794 311.667 364.25 311.123 364.25 310.458 364.25 309.794 364.794 309.25 365.458 309.25 366.123 309.25 366.667 309.794 366.667 310.458 366.667 311.123 366.123 311.667 365.458 311.667ZM380.321 316.862 371.862 308.404C371.621 308.163 371.319 308.042 371.017 308.042L365.458 308.042C364.129 308.042 363.042 309.129 363.042 310.458L363.042 315.986C363.042 316.319 363.163 316.621 363.404 316.832L371.862 325.291C372.346 325.774 373.101 325.774 373.584 325.291L380.321 318.554C380.774 318.101 380.774 317.316 380.321 316.862Z" fill="#FFFFFF" />
            </g>
        </svg>
    )
}

export default SvgComponent