import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Tab, Table, Tabs } from "react-bootstrap";
import { ClockHistory, Recycle} from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { ApiResponse, OfferFamily, Product, ProductWithOfferFamilyId } from "../../common/interfaces";
import getDeletedOfferFamilies, { GetDeletedOfferFamiliesResponse } from "../../services/api/offerFamilies/getDeletedOfferFamilies";
import getDeletedProducts, { GetDeletedProductsResponse } from "../../services/api/products/getDeletedProducts";
import restoreOfferFamily from "../../services/api/offerFamilies/restoreOfferFamily";
import restoreProduct from "../../services/api/products/restoreProduct";
import { AppContext } from "../../services/context/contextProvider";
import OfferFamilyHistoryModal from "../OfferFamilyHistoryModal/OfferFamilyHistoryModal";
import ProductHistoryModal from "../ProductHistoryModal/ProductHistoryModal";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Spinner from "../Spinner/Spinner";

import './DeletedEntitiesModal.scss';

export interface DeletedEntitiesModalProps {
    show: boolean;
    businessLineId: number;
    handleClose: () => void;
    reload: () => Promise<void>;
};

export default function DeletedEntitiesModal({show, businessLineId, handleClose, reload}: DeletedEntitiesModalProps) {
    const appContext = useContext(AppContext);

    const [offerFamilies, setOfferFamilies] = useState<GetDeletedOfferFamiliesResponse>();
    const [products, setProducts] = useState<GetDeletedProductsResponse>();
    const [ofHistoryModalShow, setOfHistoryModalShow] = useState(false);
    const [prHistoryModalShow, setPrHistoryModalShow] = useState(false);
    const [selectedOfferFamily, setSelectedOfferFamily] = useState<OfferFamily>();
    const [selectedProduct, setSelectedProduct] = useState<ProductWithOfferFamilyId>();
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);

    const apiLoad = useCallback(
        async () => {
            if (businessLineId !== 0)
            {
                let res = await getDeletedOfferFamilies(businessLineId);
                    if (res.success && res.body) {
                        setOfferFamilies(res.body);
                    }
                    else if (res.message) {
                        appContext?.setMessage({ show: true, message: res.message, status: res.status });
                    }
                    else {
                        appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                    }
                let res2 = await getDeletedProducts(businessLineId);
                    if (res2.success && res2.body) {
                        setProducts(res2.body);
                    }
                    else if (res2.message) {
                        appContext?.setMessage({ show: true, message: res2.message, status: res2.status });
                    }
                    else {
                        appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                    }
            }
        },
        [businessLineId, appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }

    const viewOfHistory = (ofId: number, ofName: string) => {
        document.body.click();
        setSelectedOfferFamily({id: ofId, link: "", order: 0, name: ofName, description: "", productManager: ""});
        setOfHistoryModalShow(true);
    }

    const viewPrHistory = (prId: number, prName: string) => {
        document.body.click();
        setSelectedProduct({id: prId, name: prName, description: "", productManager: "", lifecycleStage: "", countries: [], offerFamilyId: 0});
        setPrHistoryModalShow(true);
    }

    const confirmRestoreOf = (id: number, name: string) => {
        setConfirmModalMessage(`Are you sure you want to restore ${name}?`);
        setConfirmModalCallback(() => () => resOfferFamily(id));
        setConfirmModalShow(true);
    }

    const confirmRestorePr = (id: number, name: string) => {
        setConfirmModalMessage(`Are you sure you want to restore ${name}?`);
        setConfirmModalCallback(() => () => resProduct(id));
        setConfirmModalShow(true);
    }

    const resOfferFamily = async (ofId: number) => {
        setConfirmModalLoading(true);
        document.body.click();
        let res : ApiResponse<null> | ApiResponse<OfferFamily>;
        res = await restoreOfferFamily(ofId);
        if (res.success) {
            await reload();
            apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        handleModalClose();
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const resProduct = async (prId: number) => {
        setConfirmModalLoading(true);
        document.body.click();
        let res : ApiResponse<null> | ApiResponse<Product>;
        res = await restoreProduct(prId);
        if (res.success) {
            await reload();
            apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        handleModalClose();
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }
    
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <>
                <OfferFamilyHistoryModal
                show={ofHistoryModalShow}
                handleClose={() => { setOfHistoryModalShow(false); setSelectedOfferFamily(undefined) }}
                offerFamily={selectedOfferFamily}
                />

                <ProductHistoryModal
                show={prHistoryModalShow}
                handleClose={() => { setPrHistoryModalShow(false); setSelectedProduct(undefined) }}
                product={selectedProduct}
                />

                <ConfirmModal
                    show={confirmModalShow}
                    message={confirmModalMessage}
                    callback={confirmModalCallback}
                    handleClose={() => setConfirmModalShow(false)}
                    loading={confirmModalLoading}
                />
            </>
            <Modal.Header className="deleted-entities-modal-header">
                <ClockHistory className="deleted-entities-modal-icon"/>
                <div className="deleted-entities-modal-title">
                    {`Deleted Entities`}
                </div>
            </Modal.Header>
            <Modal.Body className="deleted-entities-modal-body">
                <Tabs defaultActiveKey="offerFamilies">
                    <Tab eventKey="offerFamilies" title="Offer Families">
                        <div className="users-tab-table-container">
                            <Table className="users-tab-table" bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Deleted By</th>
                                        <th>Date Deleted</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {offerFamilies ? offerFamilies.deletedOfferFamilies.length > 0 ? offerFamilies.deletedOfferFamilies.map((of, i) => 
                                    <tr>
                                        <td>{of.name}</td>
                                        <td>{of.lastUpdatedBy}</td>
                                        <td>{DateTime.fromISO(of.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                                        <td className="deleted-entities-icons">
                                            <div className="deleted-entities-action-icon"
                                                onClick={() => viewOfHistory(of.id, of.name)}
                                                title="display history"
                                            >
                                                <ClockHistory />
                                            </div>
                                            <div className="deleted-entities-action-icon"
                                                onClick={() => confirmRestoreOf(of.id, of.name)}
                                                title="restore Offer Family"
                                            >
                                                <Recycle />
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={5}>No deleted Offer Families found..</td>
                                    </tr>
                                    :
                                    <tr>
                                        <td className="deleted-entities-modal-spinner" colSpan={5}>
                                            <Spinner/>
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Tab>
                    <Tab eventKey="products" title="Products">
                        <div className="users-tab-table-container">
                            <Table className="users-tab-table" bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Offer Family</th>
                                        <th>Deleted By</th>
                                        <th>Date Deleted</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products ? products.deletedProducts.length > 0 ? products.deletedProducts.map((pr, i) => 
                                    <tr>
                                        <td>{pr.name}</td>
                                        <td>{pr.offerFamilyName}</td>
                                        <td>{pr.lastUpdatedBy}</td>
                                        <td>{DateTime.fromISO(pr.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                                        <td className="deleted-entities-icons">
                                            <div className="deleted-entities-action-icon"
                                                onClick={() => viewPrHistory(pr.id, pr.name)}
                                                title="display history"
                                            >
                                                <ClockHistory />
                                            </div>
                                            <div className="deleted-entities-action-icon"
                                                onClick={() => confirmRestorePr(pr.id, pr.name)}
                                                title="restore Product"
                                            >
                                                <Recycle />
                                            </div>
                                        </td>
                                    </tr>
                                    )
                                    :
                                    <tr>
                                        <td colSpan={5}>No deleted Products found..</td>
                                    </tr>
                                    :
                                    <tr>
                                        <td className="deleted-entities-modal-spinner" colSpan={5}>
                                            <Spinner/>
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <div className="deleted-entities-modal-button-container">
                    <Button
                        className="deleted-entities-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}