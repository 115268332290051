import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Tab, Tabs } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import Select, { ActionMeta, MultiValue } from 'react-select';
import ChannelsIcon from "../../assets/images/icons/ChannelsIcon";
import { AppError, CustomerType, MessageStatus } from "../../common/appConstants";
import { ApiResponse, ChannelWithBusinessLineChannel } from "../../common/interfaces";
import postBusinessLineChannel from "../../services/api/businessLineChannels/postBusinessLineChannel";
import getBusinessLineCountries from "../../services/api/businessLineCountries/getBusinessLineCountries";
import getProductsForBusinessLine, { GetProductsResponse } from "../../services/api/products/getProductsForBusinessLine";
import { AppContext } from "../../services/context/contextProvider";
import DualListbox from "../DualListbox/DualListbox";
import Spinner from "../Spinner/Spinner";
import './BusinessLineChannelModal.scss';

export interface BusinsessLineChannelModalProps {
    show: boolean;
    handleClose: () => void;
    channel?: ChannelWithBusinessLineChannel;
    businessLineId: number;
    businessLineName: string;
    reload: () => Promise<void>;
};

type BusinessLineChannelForm = {
    valid: boolean;
    validationError: string;
    customerTypes: SelectOption[];
    customerTypesError: string;
    countries: string[];
    countriesError: string;
    products: string[];
    productsError: string;
}

type SelectOption = {
    value: string;
    label: string;
}

type SelectOptionGrouped = {
    label: string;
    options: SelectOption[];
}

const createOption = (label: string) => ({
    label,
    value: label
})

const createIdOption = (label: string, id: number) => ({
    label,
    value: id.toString()
});

const createGroupedOption = (response: GetProductsResponse) => {
    return response.offerFamilies.map(of => {
        return {
            label: of.name,
            options: of.products.map(pr => {
                return {
                    value: pr.id.toString(),
                    label: pr.name
                }
            })
        }
    })
}

const blankBusinessLineChannel : BusinessLineChannelForm = { valid: true, validationError: "", customerTypes: [], customerTypesError: "", countries: [], countriesError: "", products: [], productsError: "" };

const customerTypeOptions : SelectOption[] = [
    createOption(CustomerType.b2b),
    createOption(CustomerType.b2b2c),
    createOption(CustomerType.b2c),
    createOption(CustomerType.thirdParty)
]

export default function BusinessLineChannelModal({ show, handleClose, channel, businessLineId, businessLineName, reload } : BusinsessLineChannelModalProps) {
    const appContext = useContext(AppContext);
    
    const [form, setForm] = useState(blankBusinessLineChannel);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<SelectOption[]>([]);
    const [products, setProducts] = useState<SelectOptionGrouped[]>([]);
    
    const apiLoad = useCallback(
        async () => {
            if (businessLineId > 0)
            {
                setLoading(true);
                
                let countriesRes = await getBusinessLineCountries(businessLineId, false);

                if (countriesRes.success && countriesRes.body) {
                    setCountries(countriesRes.body.businessLineCountries.sort((a,b) => a.country.name < b.country.name ? -1 : 1 ).map(ct => createIdOption(ct.country.name, ct.country.id)));
                }
                else if (countriesRes.message) {
                    appContext?.setMessage({ show: true, message: countriesRes.message, status: countriesRes.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }

                let productsRes = await getProductsForBusinessLine(businessLineId);
                if (productsRes.success && productsRes.body) {
                    setProducts(createGroupedOption(productsRes.body));
                }
                else if (productsRes.message) {
                    appContext?.setMessage({ show: true, message: productsRes.message, status: productsRes.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }

                setLoading(false);
            }
        },
        [businessLineId, appContext]
    );

    useEffect(() => {
        apiLoad();

        if (channel?.businessLineChannel) {
            setForm({
                ...form,
                customerTypes: channel.businessLineChannel.customerTypes.map(val => createOption(val)),
                countries: channel.businessLineChannel.countries.map(ct => ct.id.toString()),
                products: channel.businessLineChannel.products.map(pr => pr.id.toString())
            });
        }
        else {
            setForm(blankBusinessLineChannel);
        }
    }, [channel, businessLineId]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };

        if (formUpdate.valid) {
            let res : ApiResponse<null> = { success: false };

            if (channel) {
                res = await postBusinessLineChannel({
                    businessLineId: businessLineId,
                    channelId: channel.id,
                    customerTypes: form.customerTypes.map(opt => opt.value),
                    countries: form.countries.map(opt => parseInt(opt)),
                    products: form.products.map(opt => parseInt(opt))
                });
            }

            if (res && res.success) {
                formUpdate = blankBusinessLineChannel;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleSelectChange = (newValue : MultiValue<SelectOption>, meta : ActionMeta<SelectOption>) => {
        if (meta.name) {
            setForm({
                ...form,
                valid: true,
                validationError: "",
                customerTypesError: "",
                countriesError: "",
                productsError: "",
                [meta.name]: newValue
            });
        }
    }

    const handleCountryChange = (newValue: string[]) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            customerTypesError: "",
            countriesError: "",
            productsError: "",
            countries: newValue
        });
    }

    const handleProductChange = (newValue: string[]) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            customerTypesError: "",
            countriesError: "",
            productsError: "",
            products: newValue
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankBusinessLineChannel); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="business-line-channel-modal-header">
                <ChannelsIcon className="business-line-channel-modal-icon"/>
                <div className="business-line-channel-modal-title">
                    {channel && channel?.businessLineChannel?.isActive ? `Edit '${channel.name}' details` 
                    : `Activate '${channel?.name}'`}
                </div>
            </Modal.Header>
            <Modal.Body className="business-line-channel-modal-body">
                <Form>
                    <Tabs defaultActiveKey="general">
                        <Tab eventKey="general" title="General">
                            <Form.Group className="mb-3 mt-3" controlId="formGroupBL">
                                <Form.Label>Business Line*</Form.Label>
                                <Form.Control
                                    placeholder="Select Business Line"
                                    disabled
                                    value={businessLineName}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupChannel">
                                <Form.Label>Channel*</Form.Label>
                                <Form.Control
                                    placeholder="Select Channel"
                                    disabled
                                    value={channel?.name}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupCT">
                                <Form.Label>Customer Types</Form.Label>
                                <Select
                                    styles={{ 
                                        placeholder: (base) => ({ ...base, color: '#6c757d' }),
                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        dropdownIndicator: (base) => ({ ...base, color: '#242424', "&:hover": { color: '#242424'} }) 
                                    }}
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    menuPlacement="auto"
                                    placeholder="Select all applicable customer types"
                                    value={form.customerTypes}
                                    name="customerTypes"
                                    isMulti
                                    maxMenuHeight={168}
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(opt : SelectOption) => opt.label}
                                    getOptionValue={(opt : SelectOption) => opt.value}
                                    options={customerTypeOptions}
                                    onChange={handleSelectChange}
                                />
                            </Form.Group> 
                        </Tab>
                        <Tab eventKey="countries" title="Countries">
                            <Form.Group className="mb-3 mt-3 business-line-channel-modal-dlist" controlId="formGroupCountries">
                                <Form.Label>Countries that the Channel is available in</Form.Label>
                                <DualListbox
                                    options={countries}
                                    selected={form.countries}
                                    setSelected={handleCountryChange}
                                />
                            </Form.Group>
                        </Tab>
                        <Tab eventKey="products" title="Products">
                            <Form.Group className="mb-3 mt-3 business-line-channel-modal-dlist" controlId="formGroupProducts">
                                <Form.Label>Products accessed via the Channel</Form.Label>
                                <DualListbox
                                    options={products}
                                    selected={form.products}
                                    setSelected={handleProductChange}
                                />
                            </Form.Group>
                        </Tab>
                    </Tabs>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="business-line-channel-modal-footer">
                <div className="business-line-channel-modal-error">
                    {!form.valid && <><ExclamationCircle className="business-line-channel-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="business-line-channel-modal-button-container">
                    <Button
                        className="business-line-channel-modal-button"
                        onClick={() => { setForm(blankBusinessLineChannel); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "business-line-channel-modal-button-loading" : "business-line-channel-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}