// package imports
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Container, OverlayTrigger, Popover, PopoverBody } from "react-bootstrap";
import TagManager from 'react-gtm-module';

// project imports
import './Home.scss';
import { MessageStatus } from "../../common/appConstants";
import Banner from "../../components/Banner/Banner";
import Spinner from "../../components/Spinner/Spinner";
import getBusinessLines from "../../services/api/businessLines/getBusinessLines";
import { AppContext } from "../../services/context/contextProvider";
import PyramidIcon from "../../assets/images/icons/PyramidIcon";

// image imports
import graph from '../../assets/images/graph.png';
import pyramid from '../../assets/images/pyramid.png';
import icon1 from '../../assets/images/icon_1.svg';
import icon2 from '../../assets/images/icon_2.svg';
import icon3 from '../../assets/images/icon_3.svg';
import icon4 from '../../assets/images/icon_4.svg';
import { Link, useLocation } from "react-router-dom";

const tagManagerArgs = {
    dataLayer: {
        page: 'Home'
    },
    dataLayerName: 'PageDataLayer'
}

export default function Home() {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'Home Views',
            value: 'Home',
            page: {
                location: pageLocation,
                title: "Home"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "Home",
                value: "Home",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const [loading, setLoading] = useState(false);

    const apiLoad = useCallback(
        async () => {
            setLoading(true);
            let res = await getBusinessLines();

            if (res.success && res.body) {
                appContext?.setSidebar({
                    options: {
                        businessLines: {
                            list: res.body.businessLines.map(bl => {
                                return {
                                    link: bl.link,
                                    name: bl.name,
                                };
                            })
                        },
                        homeActive: true
                    }
                });
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
            setLoading(false);
        },
        [appContext]
    );

    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-container">
            <Banner
                titleIcon={<PyramidIcon />}
                title2="Computershare's Product Lifecycle Management Framework"
                breadcrumbActive="Home"
            />

            {loading ?
                <Container className="screen-container p-3">
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                </Container>
                :
                <div className="home-main-container">
                    <Container className="p-3">
                        <div className="home-content-container">
                            <div className="home-graph-container">
                                <img
                                    src={graph}
                                    alt="Graph of Product Lifecycle curve"
                                    className="home-graph-image"
                                />
                            </div>
                            <div className="home-caption-container">
                                <div className="home-caption-title">
                                    The Product Lifecycle Curve
                                </div>
                                <div className="home-caption-content">
                                    The Customer Driven Innovation and Product Management processes integrate end to end to
                                    provide support for actively managing our products and services through the product lifecycle.
                                </div>
                            </div>

                            <div className="home-paragraph-container">
                                <div className="home-paragraph-title">
                                    Product Lifecycle Management
                                </div>
                                <div className="home-paragraph-content">
                                    Product Lifecycle Management (PLM) helps us to make better decisions, both locally and globally,
                                    about investment strategies that align with the goals of our businesses.
                                </div>
                                <div className="home-paragraph-content">
                                    This PLM Tool brings to life the classification of our business offers into logical groups of
                                    products and services, and the channels our customers use to interact with them. It provides the
                                    foundation for a standard enterprise approach to managing products anywhere in Computershare.
                                </div>
                                <div className="home-paragraph-content">
                                    Centralising the vision, strategy and roadmap for every product we take to market across business
                                    lines makes it easier for people to understand our business, identify opportunities and see the
                                    current portfolio of investment.
                                </div>
                            </div>

                            <div className="home-pyramid-container">
                                <img
                                    src={pyramid}
                                    alt="Product Strategy pyramid"
                                    className="home-pyramid-image"
                                />
                            </div>
                            <div className="home-caption-container">
                                <div className="home-caption-title">
                                    Product Strategy Pyramid
                                </div>
                                <div className="home-caption-content">
                                    The product strategy pyramid provides a top down approach to elevate our thinking from a project
                                    mindset that is output focused to a product mindset which is outcome focused.
                                </div>
                            </div>

                            <div className="home-paragraph-container">
                                <div className="home-paragraph-content">
                                    The PLM Tool can be used to explore the layers of the classification model that under pins our new 
                                    Computershare Product Management Framework, and see the primary markets we offer our products and services in. 
                                </div>
                                <div className="home-paragraph-content">
                                    To capture the vision and core product strategies, 
                                    Vision Boards and Strategic Roadmaps can be created at the Offer Family and/or Product level. 
                                    Channel Canvases are used to capture Channel strategies. 
                                </div>
                            </div>

                            <div className="home-classification-container">
                                <div className="home-classification-image-container">
                                    <img
                                        src={icon1}
                                        alt="Business Lines"
                                        className="home-classification-image"
                                    />
                                    <img
                                        src={icon2}
                                        alt="Offer Families"
                                        className="home-classification-image"
                                    />
                                    <img
                                        src={icon3}
                                        alt="Products and Services"
                                        className="home-classification-image"
                                    />
                                    <img
                                        src={icon4}
                                        alt="Channels"
                                        className="home-classification-image"
                                    />
                                </div>

                                <div className="home-classification-text-container">
                                    <div className="home-classification-text">
                                        Global
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <PopoverBody className="home-classification-popover">
                                                        A corporate subdivision of Computershare focused on a set of related products
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <span className="home-classification-text-highlight">Business Lines</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="home-classification-text">
                                        Have one or many
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <PopoverBody className="home-classification-popover">
                                                        A logical grouping of complementary products & services that fulfil the same customer needs
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <span className="home-classification-text-highlight">Offer Families</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="home-classification-text">
                                        Which offer one or many
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <PopoverBody className="home-classification-popover">
                                                        A tangible offering that can be sold to a customer to perform functions or intangible
                                                        offering of work done by a person that can be sold to a customer
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <span className="home-classification-text-highlight">Products & Services</span>
                                        </OverlayTrigger>
                                    </div>
                                    <div className="home-classification-text">
                                        Which are delivered via one or many
                                        <OverlayTrigger
                                            trigger={["hover", "focus"]}
                                            placement="top"
                                            overlay={
                                                <Popover>
                                                    <PopoverBody className="home-classification-popover">
                                                        A physical or digital touchpoint where a customer interacts with a product or service
                                                    </PopoverBody>
                                                </Popover>
                                            }
                                        >
                                            <span className="home-classification-text-highlight">Channels</span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </div>

                            <div className="home-button-container">
                                <div>
                                    <Link to="/business-lines">
                                        <Button
                                            className="login-button mt-3"
                                            variant="outline-primary"
                                        >
                                            View Business Lines
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            }
        </div>
    );
}