import './HtmlRender.scss';
import HtmlMapper from 'react-html-map';

interface HtmlRenderProps {
    content: string;
}

export const HtmlRender = ({ content } : HtmlRenderProps) => {

    return (
        <HtmlMapper html={content} decodeEntities={{}}>
            {{
                p: null,
                h3: null,
                s: null,
                strong: null,
                i: null,
                u: null,
                ol: null,
                ul: null,
                li: null,
                em: null,
                a: ({ href, children }) =>
                    <a href={href} rel='noreferrer' target='_blank'>
                        {children}
                    </a>
            }}
        </HtmlMapper>
    );
};