import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <rect x="42.54" y="9.52" width="1.65" height="10.1" transform="translate(9.04 44.82) rotate(-59.96)"/>
      <rect x="15.59" y="13.75" width="10.1" height="1.65" transform="translate(-4.52 12.28) rotate(-30.02)"/>
      <path d="M32,12.65A4.7,4.7,0,1,1,36.7,8,4.71,4.71,0,0,1,32,12.65ZM32,4.9a3.05,3.05,0,1,0,3,3.05A3.06,3.06,0,0,0,32,4.9Z"/>
      <rect x="31.18" y="12.25" width="1.65" height="11.6"/>
      <rect x="8.42" y="29.24" width="1.65" height="10.12"/>
      <rect x="53.93" y="29.24" width="1.65" height="10.12"/>
      <path d="M44.4,60.75H42.75V52c0-4-3.58-4.77-6-4.84l-4.13,4.92a.83.83,0,0,1-.62.29.79.79,0,0,1-.63-.27l-4.48-4.94c-3.75.1-5.65,1.73-5.65,4.84v8.76H19.61V52c0-4.13,2.79-6.49,7.66-6.49a.82.82,0,0,1,.61.27L32,50.28l3.78-4.49a.85.85,0,0,1,.63-.29c5,0,8,2.42,8,6.49Z"/>
      <path d="M32,42.77a6.52,6.52,0,0,1-6.47-6.55V34.09a6.47,6.47,0,1,1,12.94,0v2.13A6.52,6.52,0,0,1,32,42.77Zm0-13.58a4.87,4.87,0,0,0-4.82,4.9v2.13a4.82,4.82,0,1,0,9.64,0V34.09A4.87,4.87,0,0,0,32,29.19Z"/>
      <path d="M54.81,25.78a4.59,4.59,0,0,1-1.22-.16,4.67,4.67,0,1,1,1.22.16Zm0-7.75a3,3,0,1,0,1.53,5.68h0a3,3,0,0,0-.75-5.58A3,3,0,0,0,54.8,18Z"/>
      <rect x="40.43" y="25.28" width="11.42" height="1.65" transform="matrix(0.86, -0.5, 0.5, 0.86, -6.87, 26.68)"/>
      <path d="M9.2,25.78a4.73,4.73,0,0,1-2.36-.64h0a4.71,4.71,0,1,1,3.57.48A4.57,4.57,0,0,1,9.2,25.78ZM7.67,23.71a3,3,0,1,0,3.06-5.27,3,3,0,0,0-3.06,5.27Z"/>
      <rect x="17.04" y="20.4" width="1.65" height="11.42" transform="translate(-13.68 28.44) rotate(-59.84)"/>
      <path d="M54.79,52.22a4.7,4.7,0,1,1,2.37-8.77h0a4.7,4.7,0,0,1-2.37,8.77Zm0-7.75A3,3,0,0,0,52.17,46a3,3,0,1,0,4.16-1.11h0A3,3,0,0,0,54.81,44.47Z"/>
      <rect x="45.45" y="37.01" width="1.65" height="11.12" transform="translate(-13.78 61.19) rotate(-59.84)"/>
      <path d="M9.21,52.22A4.59,4.59,0,0,1,8,52.06a4.7,4.7,0,0,1-1.15-8.61h0A4.74,4.74,0,0,1,10.41,43a4.7,4.7,0,0,1-1.2,9.24Zm0-7.75a3.08,3.08,0,0,0-1.53.41h0a3.06,3.06,0,0,0-1.42,1.86,3,3,0,0,0,.31,2.31,3.06,3.06,0,0,0,1.86,1.42,3.11,3.11,0,0,0,2.31-.31A3.05,3.05,0,0,0,10,44.57,3,3,0,0,0,9.2,44.47Z"/>
      <rect x="12.17" y="41.74" width="11.12" height="1.65" transform="translate(-18.97 14.64) rotate(-30.12)"/>
    </svg>
  )
}

export default SvgComponent
