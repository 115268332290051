import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import BusinessLinesIcon from "../../assets/images/icons/BusinessLinesIcon";
import { AppError } from "../../common/appConstants";
import { ApiResponse, BusinessLine, BusinessLineCreationOrUpdateRequest } from "../../common/interfaces";
import postBusinessLine from "../../services/api/businessLines/postBusinessLine";
import putBusinessLine from "../../services/api/businessLines/putBusinessLine";
import Spinner from "../Spinner/Spinner";
import './BusinessLineModal.scss';

export interface BusinsessLineModalProps {
    show: boolean;
    handleClose: () => void;
    businessLine?: BusinessLine;
    reload: () => Promise<void>;
};

type BusinessLineForm = {
    valid: boolean;
    validationError: string;
    name: string;
    nameError: string;
    description: string;
    descriptionError: string;
    headOfProduct: string;
    headOfProductError: string;
}

const blankBusinessLine : BusinessLineForm = { valid: true, validationError: "", name: "", nameError: "", description: "", descriptionError: "", headOfProduct: "", headOfProductError: "" };

export default function BusinessLineModal({ show, handleClose, businessLine, reload } : BusinsessLineModalProps) {
    const [form, setForm] = useState(blankBusinessLine);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (businessLine) {
            setForm({
                ...form,
                name: businessLine.name,
                description: businessLine.description,
                headOfProduct: businessLine.headOfProduct
            });
        }
        else {
            setForm(blankBusinessLine);
        }
    }, [businessLine]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };
        
        if (form.name.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = "Business Line name must be provided."
        }
        else if (form.name.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = `Business Line name must be no more than 100 characters. [currently ${form.name.length} characters]`
        }

        if (form.description.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.descriptionError = `Business Line description must be no more than 250 characters. [currently ${form.description.length} characters]`
        }

        if (form.headOfProduct.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.headOfProductError = `Head of Product must be no more than 100 characters. [currently ${form.headOfProduct.length} characters]`
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> | ApiResponse<BusinessLine>;
            const businessLineReq : BusinessLineCreationOrUpdateRequest = {
                name: form.name,
                description: form.description,
                headOfProduct: form.headOfProduct
            };

            if (businessLine?.id) {
                res = await putBusinessLine(businessLine.id, businessLineReq);
            }
            else {
                res = await postBusinessLine(businessLineReq);
            }

            if (res && res.success) {
                formUpdate = blankBusinessLine;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            nameError: "",
            descriptionError: "",
            headOfProductError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankBusinessLine); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="business-line-modal-header">
                <BusinessLinesIcon className="business-line-modal-icon"/>
                <div className="business-line-modal-title">
                    {businessLine ? `Edit "${businessLine.name}" details` : "Add new Business Line"}
                </div>
            </Modal.Header>
            <Modal.Body className="business-line-modal-body">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Business Line Name*</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Business Line name"
                            value={form.name}
                            name="name"
                            onChange={handleChange}
                            isInvalid={form.nameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.nameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter Business Line description"
                            value={form.description}
                            name="description"
                            onChange={handleChange}
                            isInvalid={form.descriptionError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.descriptionError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupHeadOfProduct">
                        <Form.Label>Head of Product</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Business Line Head of Product"
                            value={form.headOfProduct}
                            name="headOfProduct"
                            onChange={handleChange}
                            isInvalid={form.headOfProductError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.headOfProductError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="business-line-modal-footer">
                <div className="business-line-modal-error">
                    {!form.valid && <><ExclamationCircle className="business-line-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="business-line-modal-button-container">
                    <Button
                        className="business-line-modal-button"
                        onClick={() => { setForm(blankBusinessLine); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "business-line-modal-button-loading" : "business-line-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}