import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M56.5,43.16V15.48L32.23,6.54,7.5,16.48v27l25.93,14Zm-1.85-1-.27.17-20,12.42V28.23l9.42-4.91V31.1l1.85-1V22.35l9-4.71ZM32.26,8.51l21,7.75-8.74,4.57L23.4,12.08Zm.25,46.36L9.35,42.34V18.49l23.16,9.77Zm.66-28.33L10.84,17.12,21,13.06l21.46,8.88-9,4.7Z"/>
    </svg>
  )
}

export default SvgComponent
