import { ReactNode, useContext, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BoxArrowRight, Gear, Toggle2Off, Toggle2On } from "react-bootstrap-icons";
import { Link } from 'react-router-dom';

import './Banner.scss';
import { Breadcrumb } from "../../common/interfaces";
import { AppContext } from "../../services/context/contextProvider";
import OverflownText from "../OverflownText/OverflownText";

export interface BannerProps {
    titleIcon?: ReactNode;
    title1?: string;
    title2?: string;
    breadcrumbs?: Breadcrumb[];
    breadcrumbActive?: string;
    subtitle1?: {
        field: string;
        value: string;
    }
    subtitle2?: {
        field: string;
        value: string;
    }
};

export default function Banner({ titleIcon, title1, title2, breadcrumbs, breadcrumbActive, subtitle1, subtitle2 }: BannerProps) {
    const appContext = useContext(AppContext);
    const navRef = useRef<HTMLDivElement>(null);

    return (
        <Container fluid className="banner-container">
            <Row className="banner-breadcrumb-row">
                <Col ref={navRef} className="banner-breadcrumbs" md={11}>
                    {breadcrumbs?.map((bc, i) =>
                        (i > 1) ? <OverflownText key={bc.link} className={`banner-breadcrumb-link ${i === 2 && 'banner-businessline-max-width'} ${i === 3 && 'banner-offerfamily-max-width'}`} text={bc.displayName} link={bc.link} additionalText={"> "} /> :
                            i === 1 ?
                                <Link key={bc.link} to={bc.link} className="banner-breadcrumb-link banner-businessline-max-width banner-businessline-min-width">
                                    <span>{bc.displayName}</span>
                                </Link> :
                                <>
                                    <Link key={bc.link} to={bc.link} className="banner-breadcrumb-link">
                                        <span>{bc.displayName}</span>
                                    </Link>
                                    <span key={`static-${i}`} className="banner-breadcrumb-static" > {">"}</span>
                                </>
                    )}
                    {breadcrumbs && breadcrumbs?.length > 1 && <span className="banner-breadcrumb-static" > {">"}</span>}
                    {
                        (breadcrumbActive) &&
                        <OverflownText className="banner-breadcrumb-active align-items-end" text={breadcrumbActive} />
                    }
                </Col>
                {
                    appContext?.auth.loggedIn &&
                    <Col className="banner-controls" md={1}>
                        {appContext.auth.isEditor &&
                            <>
                                {appContext.auth.isEditingActive ?
                                    <div
                                        className="banner-control"
                                        onClick={appContext.toggleEditingActive}
                                        title="disable editing"
                                    >
                                        <Toggle2On />
                                    </div>
                                    :
                                    <div
                                        className="banner-control"
                                        onClick={appContext.toggleEditingActive}
                                        title="enable editing"
                                    >
                                        <Toggle2Off />
                                    </div>
                                }
                            </>
                        }
                        {(appContext.auth.isAdmin || appContext.auth.isRootAdmin) &&
                            <Link
                                className="banner-control"
                                title="admin settings"
                                to="/adminsettings"
                            >
                                <Gear />
                            </Link>
                        }
                        <Link
                            className="banner-control"
                            to="/logout-external"
                            title="logout"
                        >
                            <BoxArrowRight />
                        </Link>
                    </Col>
                }
            </Row >
            <Row className="banner-title-row d-flex align-items-end">
                <Col xxl={(subtitle1 || subtitle2) ? 8 : 12} lg={(subtitle1 || subtitle2) ? 8 : 12} className="align-items-center">
                    {titleIcon && <span className="banner-title-icon" >{titleIcon}</span>}
                    {
                        (title1) &&
                        <OverflownText className="banner-title-text1 align-items-end" text={title1} />
                    }

                    {title2 &&
                        <span className="banner-title-text2">{title2}</span>
                    }
                </Col>
                {(subtitle1 || subtitle2) &&
                    <Col xxl={(subtitle1 || subtitle2) ? 4 : 12} lg={(subtitle1 || subtitle2) ? 4 : 12} className="banner-subtitles">
                        <div className="banner-subtitle-fields">
                            {subtitle1 && <OverflownText className="banner-subtitle-field" text={subtitle1.field + ":"} />}
                            {subtitle2 && <OverflownText className="banner-subtitle-field" text={subtitle2.field + ":"} />}
                        </div>
                        <div className="banner-subtitle-values">
                            {subtitle1 && <OverflownText className="banner-subtitle-value max-width-subtitle" text={subtitle1.value} />}
                            {subtitle2 && <OverflownText className="banner-subtitle-value max-width-subtitle" text={subtitle2.value} />}
                        </div>
                    </Col>
                }
            </Row>
        </Container >
    );
}