import { AppError, MessageStatus } from '../../../common/appConstants';
import { ApiResponse, MessageResponse } from '../../../common/interfaces';

export interface LoginResponse {
    id: string;
    username: string;
    role: string;
    accessToken: string;
    refreshToken: string;
}

export default async function loginRequest() : Promise<ApiResponse<LoginResponse>> {
    try {
        const response = await fetch('/auth/login', {
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (response.status === 200) {
            const data : LoginResponse = await response.json();
            return {
                success: true,
                body: data
            };
        }
        else if (response.status < 500) {
            const data : MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg,
                status: MessageStatus.warning
            }
        }
        else {
            return {
                success: false,
                message: AppError.serverError,
                status: MessageStatus.error
            } 
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}