import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, Modal, Tabs, Tab } from "react-bootstrap";
import { ApiResponse, OfferFamily, Product, ProductWithOfferFamilyId } from "../../common/interfaces";
import Spinner from "../Spinner/Spinner";
import './ProductModal.scss';
import ProductsIcon from "../../assets/images/icons/ProductsIcon";
import putProduct from "../../services/api/products/putProduct";
import postProduct from "../../services/api/products/postProduct";
import { AppError, LifecycleStage, MessageStatus } from "../../common/appConstants";
import { ExclamationCircle } from "react-bootstrap-icons";
import getBusinessLineCountries from "../../services/api/businessLineCountries/getBusinessLineCountries";
import { AppContext } from "../../services/context/contextProvider";
import DualListbox from "../DualListbox/DualListbox";

export interface ProductModalProps {
    show: boolean;
    handleClose: () => void;
    product?: ProductWithOfferFamilyId;
    offerFamilies: OfferFamily[];
    businessLineId: number;
    businessLineName: string;
    reload: () => Promise<void>;
};

type ProductForm = {
    valid: boolean;
    validationError: string;
    offerFamilyId: number;
    offerFamilyIdError: string;
    name: string;
    nameError: string;
    description: string;
    descriptionError: string;
    productManager: string;
    productManagerError: string;
    lifecycleStage: string;
    lifecycleStageError: string;
    countries: string[];
    countriesError: string;
}

type SelectOption = {
    value: string;
    label: string;
}

const createIdOption = (label: string, id: number) => ({
    label,
    value: id.toString()
});

const blankProduct : ProductForm = { valid: true, validationError: "", offerFamilyId: 0, offerFamilyIdError: "", name: "", nameError: "", description: "", descriptionError: "", productManager: "", productManagerError: "", lifecycleStage: "", lifecycleStageError: "", countries: [], countriesError: ""};

export default function ProductModal({ show, handleClose, product, offerFamilies, businessLineId, businessLineName, reload } : ProductModalProps) {
    const appContext = useContext(AppContext);
    
    const [form, setForm] = useState(blankProduct);
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState<SelectOption[]>([]);

    const apiLoad = useCallback(
        async () => {

            if (businessLineId > 0)
            {
                setLoading(true);

                let countriesRes = await getBusinessLineCountries(businessLineId, false);
                if (countriesRes.success && countriesRes.body) {
                    setCountries(countriesRes.body.businessLineCountries.sort((a,b) => a.country.name < b.country.name ? -1 : 1 ).map(ct => createIdOption(ct.country.name, ct.country.id)));
                }
                else if (countriesRes.message) {
                    appContext?.setMessage({ show: true, message: countriesRes.message, status: countriesRes.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }

                setLoading(false); 
            }
        },
        [businessLineId, appContext] 
    );    

    useEffect(() => {
        apiLoad();

        if (product) {
            setForm({
                ...form,
                offerFamilyId: product.offerFamilyId,
                name: product.name,
                description: product.description,
                productManager: product.productManager,
                lifecycleStage: product.lifecycleStage,
                countries: product.countries.map(ct => ct.id.toString()),
            });
        }
        else {
            setForm(blankProduct);
        }
    }, [product, businessLineId]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };
        
        if (form.name.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = "Product name must be provided."
        }
        else if (form.name.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = `Product name must be no more than 100 characters. [currently ${form.name.length} characters]`
        }

        if (form.description.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.descriptionError = `Product description must be no more than 250 characters. [currently ${form.description.length} characters]`
        }

        if (form.productManager.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.productManagerError = `Product manager must be no more than 100 characters. [currently ${form.productManager.length} characters]`
        }

        if (form.offerFamilyId < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.offerFamilyIdError = "Offer Family must be selected."
        }

        if (form.lifecycleStage === "") {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.lifecycleStageError = "Product lifecycle stage must be selected."
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> | ApiResponse<Product>;

            if (product?.id) {
                res = await putProduct(product.id, {
                    offerFamilyId: form.offerFamilyId,
                    name: form.name,
                    description: form.description,
                    productManager: form.productManager,
                    lifecycleStage: form.lifecycleStage,
                    countries: form.countries.map(opt => parseInt(opt))
                });
            }
            else {
                res = await postProduct({
                    offerFamilyId: form.offerFamilyId,
                    name: form.name,
                    description: form.description,
                    productManager: form.productManager,
                    lifecycleStage: form.lifecycleStage,
                    countries: form.countries.map(opt => parseInt(opt)),
                });
            }

            if (res && res.success) {
                formUpdate = blankProduct;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            offerFamilyIdError: "",
            nameError: "",
            descriptionError: "",
            productManagerError: "",
            lifecycleStageError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleSelectChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            offerFamilyIdError: "",
            nameError: "",
            descriptionError: "",
            productManagerError: "",
            lifecycleStageError: "",
            countriesError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleCountryChange = (newValue: string[]) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            countriesError: "",
            countries: newValue
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankProduct); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="product-modal-header">
                <ProductsIcon className="product-modal-icon"/>
                <div className="product-modal-title">
                    {product ? `Edit "${product.name}" details` : "Add new Product"}
                </div>
            </Modal.Header>
            <Modal.Body className="product-modal-body">
                <Form>
                    <Tabs defaultActiveKey="general">
                        <Tab eventKey="general" title="General">
                            <Form.Group className="mb-3 mt-3" controlId="formGroupBL">
                                <Form.Label>Business Line*</Form.Label>
                                <Form.Control
                                    placeholder="Select Business Line name"
                                    disabled
                                    value={businessLineName}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupOF">
                                <Form.Label>Offer Family*</Form.Label>
                                <Form.Select 
                                    value={form.offerFamilyId}
                                    name="offerFamilyId"
                                    onChange={handleSelectChange}
                                    isInvalid={form.offerFamilyIdError.length > 0}
                                    disabled={loading}
                                    className={form.offerFamilyId > 0 ? "": "product-modal-placeholder"}
                                >
                                    <option key="ofo-0" value={"0"} disabled selected hidden>Select an Offer Family</option>
                                    {offerFamilies.map((of, i) =>
                                        <option className="product-modal-selected" key={`ofo-${i}`} value={of.id}>{of.name}</option>
                                    )}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {form.offerFamilyIdError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupName">
                                <Form.Label>Product Name*</Form.Label>
                                <Form.Control  
                                    type="text"
                                    placeholder="Enter Product name"
                                    value={form.name}
                                    name="name"
                                    onChange={handleChange}
                                    isInvalid={form.nameError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.nameError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control  
                                    as="textarea"
                                    rows={2}
                                    placeholder="Enter Product description"
                                    value={form.description}
                                    name="description"
                                    onChange={handleChange}
                                    isInvalid={form.descriptionError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.descriptionError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupProductManager">
                                <Form.Label>Product Manager</Form.Label>
                                <Form.Control  
                                    type="text"
                                    placeholder="Enter Product Manager"
                                    value={form.productManager}
                                    name="productManager"
                                    onChange={handleChange}
                                    isInvalid={form.productManagerError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.productManagerError}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGroupLifecycleStage">
                                <Form.Label>Product Lifecycle Stage*</Form.Label>
                                <Form.Select 
                                    value={form.lifecycleStage}
                                    name="lifecycleStage"
                                    onChange={handleSelectChange}
                                    isInvalid={form.lifecycleStageError.length > 0}
                                    disabled={loading}
                                    className={form.lifecycleStage ? "" : "product-modal-placeholder"}
                                >
                                    <option value="" selected disabled hidden>Select a Product Lifecycle Stage</option>
                                    <option className="product-modal-selected" value={LifecycleStage.introduction}>Introduction</option>
                                    <option className="product-modal-selected" value={LifecycleStage.growth}>Growth</option>
                                    <option className="product-modal-selected" value={LifecycleStage.maturity}>Maturity</option>
                                    <option className="product-modal-selected" value={LifecycleStage.decline}>Decline</option>
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {form.lifecycleStageError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Tab>
                        <Tab eventKey="countries" title="Countries">
                            <Form.Group className="mb-3 mt-3 business-line-channel-modal-dlist" controlId="formGroupCountries">
                                <Form.Label>The primary market where the product or service is sold</Form.Label>
                                <DualListbox
                                    options={countries}
                                    selected={form.countries}
                                    setSelected={handleCountryChange}
                                />
                            </Form.Group>
                        </Tab>
                    </Tabs>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="product-modal-footer">
                <div className="product-modal-error">
                    {!form.valid && <><ExclamationCircle className="product-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="product-modal-button-container">
                    <Button
                        className="product-modal-button"
                        onClick={() => { setForm(blankProduct); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "product-modal-button-loading" : "product-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}