import { useCallback, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TagManager from 'react-gtm-module';

import "./Roadmap.scss";
import Banner, { BannerProps } from "../../components/Banner/Banner";
import Spinner from "../../components/Spinner/Spinner";
import { MessageStatus, RoadMapConstants } from "../../common/appConstants"
import { useToggleGroupButtons } from "../../components/DnD/ToggleGroupButtons/ToggleGroupButtons";
import { ArtefactResponse, GenericEntity, RoadmapCard } from "../../common/interfaces";
import getRoadmap, { GetRoadmapResponse } from "../../services/api/roadMap/getRoadmap";
import TimelineSection from "../../components/DnD/TimelineRow/TimelineSection";
import { RoadmapContext } from "../../services/context/useRoadmapContext";
import { AppContext } from "../../services/context/contextProvider";
import CardModal from "../../components/DnD/CardModal/CardModal";
import getVisionBoard from "../../services/api/visionBoard/getVisionBoard";
import { HtmlRender } from "../../components/HtmlMapper/HtmlRender";
import LastUpdatedContainer from "../../components/DnD/LastUpdated/LastUpdatedContainer";
import { useLocation } from "react-router-dom";

type RoadmapProps = {
    loading: boolean;
    bannerProps: BannerProps;
    entity: GenericEntity;
}

const tagManagerArgs = {
    dataLayer: {
        page: 'Roadmap'
    },
    dataLayerName: 'PageDataLayer'
}

export default function Roadmap({ loading, bannerProps, entity }: RoadmapProps) {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'Roadmap Views',
            value: 'Roadmap',
            page: {
                location: pageLocation,
                title: "Roadmap"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "Roadmap",
                value: "Roadmap",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const [reload, setReload] = useState<boolean>(false);
    const [roadmapResponse, setRoadmapResponse] = useState<GetRoadmapResponse>();
    const [roadmapLoading, setRoadmapLoading] = useState(false);
    const { toggleButtons, activeButton, displayState, setDisplayState } = useToggleGroupButtons();
    const [cardModalShow, setCardModalShow] = useState(displayState);
    const [selectedCard, setSelectedCard] = useState<RoadmapCard>();
    const [board, setBoard] = useState<ArtefactResponse>();

    const apiLoad = useCallback(
        async () => {
            try {
                if (entity.id > 0) {
                    setRoadmapLoading(true);
                    setRoadmapResponse(undefined);
                    setBoard(undefined);

                    let boardResponse = await getVisionBoard(entity.type, entity.id);

                    let getRoadmapResponse = await getRoadmap(entity.type, entity.id);

                    if (boardResponse.success && boardResponse.body) {
                        setBoard(boardResponse.body);
                    }
                    else if (boardResponse.message) {
                        appContext?.setMessage({ show: true, message: boardResponse.message });
                    }
                    else {
                        appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                    }

                    if (getRoadmapResponse.success && getRoadmapResponse.body) {
                        setRoadmapResponse(getRoadmapResponse.body);
                    }
                    else if (getRoadmapResponse.message) {
                        appContext?.setMessage({ show: true, message: getRoadmapResponse.message, status: getRoadmapResponse.status });
                    }
                    else {
                        appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                    }
                    setRoadmapLoading(false);
                }
            } catch (error) {
                if (error === "AbortError") {
                    appContext?.setMessage({ show: true, message: "AbortError", status: MessageStatus.error });
                }
                setRoadmapLoading(false);
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
        },
        [entity, appContext]
    );

    useEffect(() => {
        apiLoad();
    }, [entity, reload]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setCardModalShow(displayState);
    }, [displayState])

    const handleClose = () => {
        setCardModalShow(false);
        setDisplayState(false);
        setSelectedCard(undefined);
    }

    return (
        <div className="content-container">
            <Banner
                titleIcon={bannerProps.titleIcon}
                title1={bannerProps.title1 && bannerProps.title1}
                title2="Strategic Roadmap"
                breadcrumbs={bannerProps.breadcrumbs}
                breadcrumbActive={bannerProps.breadcrumbs && bannerProps.breadcrumbActive ?
                    `${bannerProps.breadcrumbActive} > Roadmap` :
                    bannerProps.breadcrumbs && "Roadmap"}
                subtitle1={bannerProps.subtitle1}
            />
            <Container className="screen-container p-3">
                {roadmapLoading || loading ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        {/* BEGIN: Vision Title Section */}
                        {board &&
                            <div className="vision-board-title">
                                <div className="vision-board-title-content">
                                    <div className="vision-board-title-head">{board.field1.head}:</div>
                                    {board.field1.data ?
                                        <div className="vision-board-title-data">
                                            <HtmlRender content={board.field1.data} />
                                        </div>
                                        :
                                        <div className="vision-board-title-placeholder">{board.field1.def}</div>}
                                </div>
                            </div>}
                        {/* END: Vision Title Section */}
                        <DndProvider backend={HTML5Backend}>
                            <RoadmapContext.Provider value={{ reload, setReload }}>
                                {toggleButtons}
                                {/* BEGIN: Timelines */}
                                {
                                    activeButton === RoadMapConstants.completed &&
                                    <TimelineSection loading={roadmapLoading} times={RoadMapConstants.tabs.completed} items={roadmapResponse?.roadmapCards.completed} entity={entity} />
                                }
                                {
                                    activeButton === RoadMapConstants.roadmap &&
                                    <TimelineSection loading={roadmapLoading} times={RoadMapConstants.tabs.roadmap} items={roadmapResponse?.roadmapCards.now
                                        .concat(roadmapResponse?.roadmapCards.next)
                                        .concat(roadmapResponse?.roadmapCards.later)} entity={entity} />
                                }
                                {
                                    activeButton === RoadMapConstants.candidates &&
                                    <TimelineSection loading={roadmapLoading} times={RoadMapConstants.tabs.candidates} items={roadmapResponse?.roadmapCards.candidates} entity={entity} />
                                }

                                {/* END: Timelines */}
                                <CardModal
                                    show={cardModalShow}
                                    handleClose={handleClose}
                                    card={selectedCard}
                                    entity={entity} />
                                <LastUpdatedContainer activeButton={activeButton} entity={entity} />
                            </RoadmapContext.Provider>
                        </DndProvider>
                    </>
                }
            </Container >
        </div >
    );
}
