import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M11.22,37.81a.92.92,0,0,0,.92-.92v-4H31.08v4.06a.92.92,0,1,0,1.84,0V32.86H51.86v4a.92.92,0,0,0,1.84,0V32a.92.92,0,0,0-.56-.85A1,1,0,0,0,52.7,31H32.92V27.28a.92.92,0,1,0-1.84,0V31H11.3a1,1,0,0,0-.4.1.93.93,0,0,0-.6.87v4.91A.92.92,0,0,0,11.22,37.81Z"/>
      <path d="M26.71,8.84a.92.92,0,0,0-.92.92V20.34a.93.93,0,0,0,.92.93H37.29a.93.93,0,0,0,.92-.93V9.76a.92.92,0,0,0-.92-.92Zm9.66,10.58H27.63V10.68h8.74Z"/>
      <path d="M37.29,55.16a.92.92,0,0,0,.92-.92V43.66a.92.92,0,0,0-.92-.92H26.71a.92.92,0,0,0-.92.92V54.24a.92.92,0,0,0,.92.92ZM27.63,44.58h8.74v8.74H27.63Z"/>
      <path d="M5.78,55.16H16.36a.92.92,0,0,0,.92-.92V43.66a.92.92,0,0,0-.92-.92H5.78a.92.92,0,0,0-.92.92V54.24A.92.92,0,0,0,5.78,55.16ZM6.7,44.58h8.74v8.74H6.7Z"/>
      <path d="M47.64,42.74a.92.92,0,0,0-.92.92V54.24a.92.92,0,0,0,.92.92H58.22a.92.92,0,0,0,.92-.92V43.66a.92.92,0,0,0-.92-.92ZM57.3,53.32H48.56V44.58H57.3Z"/>
    </svg>
  )
}

export default SvgComponent
