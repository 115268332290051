import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import ChannelsIcon from "../../assets/images/icons/ChannelsIcon";
import { AppError } from "../../common/appConstants";
import { ApiResponse, Country } from "../../common/interfaces";
import postCountry from "../../services/api/countries/postCountry";
import putCountry from "../../services/api/countries/putCountry";
import Spinner from "../Spinner/Spinner";
import './CountryModal.scss';

export interface CountryModalProps {
    show: boolean;
    handleClose: () => void;
    country?: Country;
    reload: () => Promise<void>;
};

type CountryForm = {
    valid: boolean;
    validationError: string;
    name: string;
    nameError: string;
    iso2: string;
    iso2Error: string;
    iso3: string;
    iso3Error: string;
}

const blankCountry : CountryForm = { valid: true, validationError: "", name: "", nameError: "", iso2: "", iso2Error: "", iso3: "", iso3Error: "" };

export default function CountryModal({ show, handleClose, country, reload } : CountryModalProps) {
    const [form, setForm] = useState(blankCountry);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (country) {
            setForm({
                ...form,
                name: country.name,
                iso2: country.iso2,
                iso3: country.iso3
            });
        }
        else {
            setForm(blankCountry);
        }
    }, [country]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };
        
        if (form.name.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = "Country name must be provided."
        }
        else if (form.name.length > 50) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = `Country name must be no more than 50 characters. [currently ${form.name.length} characters]`
        }

        if (form.iso2.length !== 2) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.iso2Error = `An ISO2 code of 2 characters must be provided.`
        }

        if (form.iso3.length !== 3) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.iso3Error = `An ISO3 code of 3 characters must be provided.`
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> | ApiResponse<Country>;

            if (country?.id) {
                res = await putCountry(country.id, {
                    name: form.name,
                    iso2: form.iso2,
                    iso3: form.iso3
                });
            }
            else {
                res = await postCountry({
                    name: form.name,
                    iso2: form.iso2,
                    iso3: form.iso3
                });
            }

            if (res && res.success) {
                formUpdate = blankCountry;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            nameError: "",
            iso2Error: "",
            iso3Error: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankCountry); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="country-modal-header">
                <ChannelsIcon className="country-modal-icon"/>
                <div className="country-modal-title">
                    {country ? `Edit Country details for "${country.name}"` : "Add new Country"}
                </div>
            </Modal.Header>
            <Modal.Body className="country-modal-body">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Name*</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Country name"
                            value={form.name}
                            name="name"
                            onChange={handleChange}
                            isInvalid={form.nameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.nameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupISO2">
                        <Form.Label>ISO2 code*</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Country ISO2 code"
                            value={form.iso2}
                            name="iso2"
                            onChange={handleChange}
                            isInvalid={form.iso2Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.iso2Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupISO3">
                        <Form.Label>ISO3 code*</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Country ISO3 code"
                            value={form.iso3}
                            name="iso3"
                            onChange={handleChange}
                            isInvalid={form.iso3Error.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.iso3Error}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="country-modal-footer">
                <div className="country-modal-error">
                    {!form.valid && <><ExclamationCircle className="country-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="country-modal-button-container">
                    <Button
                        className="country-modal-button"
                        onClick={() => { setForm(blankCountry); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "country-modal-button-loading" : "country-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}