import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ClockHistory } from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { BusinessLine } from "../../common/interfaces";
import getBusinessLineHistory, { GetBusinessLineHistoryResponse } from "../../services/api/businessLines/getBusinessLineHistory";
import { AppContext } from "../../services/context/contextProvider";
import Spinner from "../Spinner/Spinner";

import './BusinessLineHistoryModal.scss';

export interface BusinessLineHistoryModalProps {
    show: boolean;
    handleClose: () => void;
    businessLine?: BusinessLine;
};

export default function BusinessLineHistoryModal({show, handleClose, businessLine}: BusinessLineHistoryModalProps) {
    const appContext = useContext(AppContext);
    
    const [history, setHistory] = useState<GetBusinessLineHistoryResponse>();

    const apiLoad = useCallback(
        async () => {
            if (businessLine?.id && businessLine.id > 0)
            {
                let res = await getBusinessLineHistory(businessLine.id);
                if (res.success && res.body) {
                    setHistory(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }
            else
            {
                setHistory(undefined);
            }
        },
        [businessLine, appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [businessLine]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }
    
    return (
        <Modal
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <Modal.Header className="business-line-history-modal-header">
                <ClockHistory className="business-line-history-modal-icon"/>
                <div className="business-line-history-modal-title">
                    {`${businessLine? businessLine.name + " History" : "Error"}`}
                </div>
            </Modal.Header>
            <Modal.Body className="business-line-history-modal-body">
                <div className="users-tab-table-container">
                    <Table className="users-tab-table" bordered size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Head of Product</th>
                                <th>Updated By</th>
                                <th>Date Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {history ? history.businessLineHistory.length > 0 ? history.businessLineHistory.map((bl, i) => 
                            <tr key={`bl-${i}`}>
                                <td>{bl.name}</td>
                                <td>{bl.description}</td>
                                <td>{bl.headOfProduct}</td>
                                <td>{bl.lastUpdatedBy}</td>
                                <td>{DateTime.fromISO(bl.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                            </tr>
                            )
                            :
                            <tr>
                                <td colSpan={5}>No history found...</td>
                            </tr>
                            :
                            <tr>
                                <td className="business-line-history-modal-spinner" colSpan={5}>
                                    <Spinner/>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="business-line-history-modal-button-container">
                    <Button
                        className="business-line-history-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}