import { useContext, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Store } from "../../common/appConstants";
import { LoginResponse } from "../../services/api/auth/login";
import { AppContext } from "../../services/context/contextProvider";

function useQuery() {
    const { search } = useLocation();
  
    return useMemo(() => new URLSearchParams(search), [search]);
}

export default function PostAuthRedirect() {
    const appContext = useContext(AppContext);
    const query = useQuery();

    useEffect(() => {
        const id = query.get("id");
        const username = query.get("username");
        const role = query.get("role");
        const accessToken = query.get("accessToken");
        const refreshToken = query.get("refreshToken");
        const returnUrl = localStorage.getItem(Store.returnUrl);

        if (id !== null && username !== null && role !== null && accessToken !== null && refreshToken !== null) {
            const loginDetails : LoginResponse = {
                id: id,
                username: username,
                role: role,
                accessToken: accessToken,
                refreshToken: refreshToken
            }
            appContext?.setAuthExternal(loginDetails);
        }
        else {
            alert("Some required auth parameters are null, cannot complete login!");
        }

        window.location.replace(`${window.location.protocol}//${window.location.host}${returnUrl}`);
    });

    return null;
}