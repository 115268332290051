import { Button, Modal } from "react-bootstrap";
import { QuestionOctagon } from "react-bootstrap-icons";
import Spinner from "../Spinner/Spinner";
import './ConfirmModal.scss';

export interface ConfirmModalProps {
    show: boolean;
    message: string;
    callback: () => void;
    handleClose: () => void;
    loading: boolean;
};

export default function ConfirmModal({ message, show, callback, handleClose, loading }: ConfirmModalProps) {

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            style={{ zIndex: 99999 }}
        >
            <Modal.Header className="confirm-modal-header">
                <div className="confirm-modal-icon"><QuestionOctagon /></div>
            </Modal.Header>
            <Modal.Body className="confirm-modal-body">
                <div className="confirm-modal-message">{message}</div>
            </Modal.Body>
            <Modal.Footer className="confirm-modal-footer">
                <Button
                    className="confirm-modal-button"
                    onClick={handleClose}
                    variant="outline-secondary"
                >
                    Cancel
                </Button>
                <Button
                    className={loading ? "confirm-modal-button-loading" : "confirm-modal-button"}
                    onClick={callback}
                    variant="outline-primary"
                    disabled={loading}
                >
                    {loading ? <Spinner /> : "Confirm"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}