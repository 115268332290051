import { DateTime } from "luxon";
import { Col, Modal, Row } from "react-bootstrap";
import TagIcon from "../../../assets/images/icons/TagIcon";

import { RoadMapConstants } from "../../../common/appConstants";
import { ItemType } from "../../../common/interfaces"
import "./DetailsWindow.scss";

type DetailsWindowProps = {
    show: boolean;
    onClose: () => void;
    item: ItemType;
    color: string;
}

const DetailsWindow = ({ show, onClose, item, color }: DetailsWindowProps) => {
    return (
        <Modal
            show={show}
            onHide={onClose}
            centered
            size="lg"
        >
            <Modal.Body className="dnd-window-border-bottom" style={{ borderLeftColor: color }}>
                <Row>
                    <Col>
                        <div className={"dnd-modal-header dnd-word-break"}>
                            <p className={"dnd-close-btn-header"}>{item.objective}</p>
                            <button className={"dnd-close-btn"} onClick={onClose}>X</button>
                        </div>
                        <>
                            <p className={`dnd-window-card-title dnd-word-break`}>{item.title}</p></>
                        <>
                            <p className={`dnd-word-break`}>{item.jobStory}</p>
                        </>
                        <>
                            <Row>
                                {item.tags.map(tag => (
                                    <Col key={tag} className="dnd-item-tag" lg={2}>
                                        <TagIcon />{tag}
                                    </Col>)
                                )}
                            </Row>
                        </>
                        <>
                            {(item.dateCompleted && item.state === RoadMapConstants.completed) &&
                                <Row>
                                    <Col>
                                        <div className="dnd-date-completed">
                                            Completed on {DateTime.fromISO(item.dateCompleted, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}
                                        </div>
                                    </Col>
                                </Row>}
                        </>
                    </Col>
                </Row>
            </Modal.Body>
            {/* <Modal.Footer className="dnd-centered-content-div">
                <div>
                    <span style={{ color: color }}>
                        <b>{`${item.state.charAt(0).toUpperCase()}${item.state.slice(1)}`}</b>
                    </span>
                </div>
            </Modal.Footer> */}
        </Modal>
    )
}

export default DetailsWindow;