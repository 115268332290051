export default function daysBetween(date1: string, date2: number) {
    //Get 1 day in milliseconds
    let oneDay = 1000 * 60 * 60 * 24;

    let firstDate = new Date(date1)
    let secondDate = new Date(date2) // now

    let differenceInDays = Math.floor((secondDate.getTime() - firstDate.getTime()) / oneDay);

    return differenceInDays;
}