import { DateTime } from "luxon";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ClockHistory, Recycle} from "react-bootstrap-icons";
import { MessageStatus } from "../../common/appConstants";
import { ApiResponse, BusinessLine } from "../../common/interfaces";
import getDeletedBusinessLines, { GetDeletedBusinessLinesResponse } from "../../services/api/businessLines/getDeletedBusinessLines";
import restoreBusinessLine from "../../services/api/businessLines/restoreBusinessLine";
import { AppContext } from "../../services/context/contextProvider";
import BusinessLineHistoryModal from "../BusinessLineHistoryModal/BusinessLineHistoryModal";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import Spinner from "../Spinner/Spinner";

import './DeletedBusinessLinesModal.scss';

export interface DeletedBusinessLinesModalProps {
    show: boolean;
    handleClose: () => void;
    reload: () => Promise<void>;
};

export default function DeletedBusinessLinesModal({show, handleClose, reload}: DeletedBusinessLinesModalProps) {
    const appContext = useContext(AppContext);

    const [businessLines, setBusinessLines] = useState<GetDeletedBusinessLinesResponse>();
    const [blHistoryModalShow, setBlHistoryModalShow] = useState(false);
    const [selectedBusinessLine, setSelectedBusinessLine] = useState<BusinessLine>();
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);

    const apiLoad = useCallback(
        async () => {
            let res = await getDeletedBusinessLines();
                if (res.success && res.body) {
                    setBusinessLines(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
        },
        [appContext]
    );   
    
    useEffect(() => {
        apiLoad();
    }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        handleClose();
    }

    const viewBlHistory = (blId: number, blName: string) => {
        document.body.click();
        setSelectedBusinessLine({id: blId, link: "", order: 0, name: blName, description: "", headOfProduct: ""});
        setBlHistoryModalShow(true);
    }

    const confirmRestore = (id: number, name: string) => {
        setConfirmModalMessage(`Are you sure you want to restore ${name}?`);
        setConfirmModalCallback(() => () => resBusinessLine(id));
        setConfirmModalShow(true);
    }

    const resBusinessLine = async (blId: number) => {
        setConfirmModalLoading(true);
        document.body.click();
        let res : ApiResponse<null> | ApiResponse<BusinessLine>;
        res = await restoreBusinessLine(blId);
        if (res.success) {
            await reload();
            apiLoad();
        }
        else if (res.message) {
            appContext?.setMessage({ show: true, message: res.message, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        handleModalClose();
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }
    
    return (
        <Modal
            className="deleted-business-lines-modal"
            show={show}
            onHide={handleModalClose}
            centered
            size="xl"
        >
            <>
                <BusinessLineHistoryModal
                show={blHistoryModalShow}
                handleClose={() => { setBlHistoryModalShow(false); setSelectedBusinessLine(undefined) }}
                businessLine={selectedBusinessLine}
                />

                <ConfirmModal
                    show={confirmModalShow}
                    message={confirmModalMessage}
                    callback={confirmModalCallback}
                    handleClose={() => setConfirmModalShow(false)}
                    loading={confirmModalLoading}
                />
            </>
            <Modal.Header className="deleted-business-lines-modal-header">
                <ClockHistory className="deleted-business-lines-modal-icon"/>
                <div className="deleted-business-lines-modal-title">
                    {`Deleted Business Lines`}
                </div>
            </Modal.Header>
            <Modal.Body className="deleted-business-lines-modal-body">
                <div className="users-tab-table-container">
                    <Table className="users-tab-table" bordered size="sm">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Deleted By</th>
                                <th>Date Deleted</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {businessLines ? businessLines.deletedBusinessLines.length > 0 ? businessLines.deletedBusinessLines.map((bl, i) => 
                            <tr className="deleted-business-lines-tr">
                                <td>{bl.name}</td>
                                <td>{bl.lastUpdatedBy}</td>
                                <td>{DateTime.fromISO(bl.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED)}</td>
                                <td className="deleted-business-lines-icons">
                                    <div className="deleted-business-lines-action-icon"
                                        onClick={() => viewBlHistory(bl.id, bl.name)}
                                        title="display history"
                                    >
                                        <ClockHistory />
                                    </div>
                                    <div className="deleted-business-lines-action-icon"
                                        onClick={() => confirmRestore(bl.id, bl.name)}
                                        title="restore Business Line"
                                    >
                                        <Recycle />
                                    </div>
                                </td>
                            </tr>
                            )
                            :
                            <tr>
                                <td colSpan={5}>No deleted Business Lines found..</td>
                            </tr>
                            :
                            <tr>
                                <td className="deleted-business-lines-modal-spinner" colSpan={5}>
                                    <Spinner/>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="deleted-business-lines-modal-button-container">
                    <Button
                        className="deleted-business-lines-modal-button"
                        onClick={handleModalClose}
                        variant="outline-secondary"
                    >
                        Close
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}