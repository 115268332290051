import { createContext, useContext } from "react"

export type GlobalRoadmapContext = {
    reload: boolean,
    setReload: (b: boolean) => void
}

export const RoadmapContext = createContext<GlobalRoadmapContext>({
    reload: false,
    setReload: () => { }
})

export const useRoadmapContext = () => useContext(RoadmapContext);
