import { AppError, MessageStatus, Store } from '../../../common/appConstants';
import { ApiResponse, MessageResponse } from '../../../common/interfaces';

export interface GetProductHistoryResponse {
    productHistory: ProductHistory[];
}

export interface ProductHistory {
    name: string;
    description: string;
    offerFamily: string;
    productManager: string;
    lifecycleStage: string;
    countries: string;
    lastUpdatedBy: string;
    lastUpdatedAt: string;
}

export default async function getProductHistory(productId : number) : Promise<ApiResponse<GetProductHistoryResponse>> {
    try {
        const response = await fetch(`/api/products/${productId}/history`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`
            }
        });
        if (response.status === 200) {
            const data : GetProductHistoryResponse = await response.json();
            return {
                success: true,
                body: data
            };
        }
        else if (response.status < 500) {
            const data : MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg,
                status: MessageStatus.warning
            }
        }
        else {
            return {
                success: false,
                message: AppError.serverError,
                status: MessageStatus.error
            } 
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}