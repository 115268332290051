// package imports
import { useCallback, useContext, useEffect } from 'react';
import { Gear } from 'react-bootstrap-icons';
import { Tab, Tabs } from 'react-bootstrap';
import TagManager from 'react-gtm-module';

// project imports
import './Settings.scss';
import Banner from "../../components/Banner/Banner";
import UsersTab from '../../components/UsersTab/UsersTab';
import getBusinessLines from '../../services/api/businessLines/getBusinessLines';
import { AppContext } from '../../services/context/contextProvider';
import ChannelsTab from '../../components/ChannelsTab/ChannelsTab';
import CountriesTab from '../../components/CountriesTab/CountriesTab';
import PrimaryMarketsTab from '../../components/PrimaryMarketsTab/PrimaryMarketsTab';
import { MessageStatus } from '../../common/appConstants';
import TemplatesTab from '../../components/TemplatesTab/TemplatesTab';
import { useLocation } from 'react-router-dom';

const tagManagerArgs = {
    dataLayer: {
        page: 'App'
    },
    dataLayerName: 'Setting'
}

export default function Settings() {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'Setting Views',
            value: 'Setting',
            page: {
                location: pageLocation,
                title: "Setting"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "Setting",
                value: "Setting",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const apiLoad = useCallback(
        async () => {
            let res = await getBusinessLines();

            if (res.success && res.body) {
                appContext?.setSidebar({
                    options: {
                        businessLines: {
                            list: res.body.businessLines.map(bl => {
                                return {
                                    link: bl.link,
                                    name: bl.name,
                                };
                            })
                        }
                    }
                });
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
        },
        [appContext]
    );

    useEffect(() => {
        apiLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="content-container">
            <Banner
                titleIcon={<Gear />}
                title1="Administrator Settings"
            />
            {(appContext?.auth.isAdmin || appContext?.auth.isRootAdmin) ?
                <div className="screen-container p-3">
                    {appContext.auth.isAdmin ?
                        <Tabs defaultActiveKey="users" id="settings-tabs">
                            <Tab eventKey="users" title="Users">
                                <UsersTab
                                    currentUser={appContext.auth.username}
                                />
                            </Tab>
                            <Tab eventKey="channels" title="Channels">
                                <ChannelsTab />
                            </Tab>
                            <Tab eventKey="countries" title="Countries">
                                <CountriesTab />
                            </Tab>
                            <Tab eventKey="primaryMarkets" title="Primary Markets">
                                <PrimaryMarketsTab />
                            </Tab>
                            <Tab eventKey="templates" title="Templates">
                                <TemplatesTab />
                            </Tab>
                        </Tabs>
                        :
                        <UsersTab
                            currentUser={appContext.auth.username}
                        />
                    }

                </div>
                :
                <div className="p-3">You do not have permission to view these settings.</div>
            }
        </div>
    );
}