import "./VisionBoard.scss";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import Banner, { BannerProps } from "../../components/Banner/Banner";
import getVisionBoard from "../../services/api/visionBoard/getVisionBoard";
import Spinner from "../../components/Spinner/Spinner";
import { DateTime } from "luxon";
import { ArtefactField, ArtefactResponse, GenericEntity } from "../../common/interfaces";
import { AppContext } from "../../services/context/contextProvider";
import { ClockHistory, PencilSquare } from "react-bootstrap-icons";
import { HtmlRender } from "../../components/HtmlMapper/HtmlRender";
import TextEditorModal, { DataField } from "../../components/TextEditorModal/TextEditorModal";
import { MessageStatus } from "../../common/appConstants";
import VisionBoardHistoryModal from "../../components/VisionBoardHistoryModal/VisionBoardHistoryModal";
import OverflownText from "../../components/OverflownText/OverflownText";
import TagManager from 'react-gtm-module';
import { useLocation } from "react-router-dom";

type VisionBoardProps = {
    loading: boolean;
    bannerProps: BannerProps;
    entity: GenericEntity;
}

const tagManagerArgs = {
    dataLayer: {
        page: 'VisionBoard'
    },
    dataLayerName: 'PageDataLayer'
}

export default function VisionBoard({ loading, bannerProps, entity }: VisionBoardProps) {
    const appContext = useContext(AppContext);
    TagManager.dataLayer(tagManagerArgs);
    var pageLocation = useLocation();

    useEffect(() => {
        window.dataLayer.push({
            event: 'VisionBoard Views',
            value: 'VisionBoard',
            page: {
                location: pageLocation,
                title: "VisionBoard"
            },
            user: appContext?.auth.username,
            eventProps: {
                label: "VisionBoard",
                value: "VisionBoard",
            }
        });
    }, [pageLocation, appContext?.auth.username])

    const [board, setBoard] = useState<ArtefactResponse>();
    const [boardLoading, setBoardLoading] = useState(false);
    const [textEditorModalShow, setTextEditorModalShow] = useState(false);
    const [historyModalShow, setVbHistoryModalShow] = useState(false);
    const [selectedDataField, setSelectedDataField] = useState<DataField>({ number: 0, name: "", content: "" });
    const [selectedHistoryDataField, setSelectedHistoryDataField] = useState<DataField>({ number: 0, name: "", content: "" });

    const apiLoad = useCallback(
        async () => {
            if (entity.id > 0) {
                setBoardLoading(true);
                setBoard(undefined);

                let res = await getVisionBoard(entity.type, entity.id);

                if (res.success && res.body) {
                    setBoard(res.body);
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
                setBoardLoading(false);
            }
        },
        [entity, appContext]
    );

    useEffect(() => {
        apiLoad();
    }, [entity]); // eslint-disable-line react-hooks/exhaustive-deps

    const editTextField = (number: number, name: string, data: string | undefined) => {
        setSelectedDataField({
            number: number,
            name: name,
            content: data ? data : ""
        });
        setTextEditorModalShow(true);
    }

    const viewDataHistory = (number: number, name: string, data: string | undefined) => {
        setSelectedHistoryDataField({
            number: number,
            name: name,
            content: data ? data : ""
        });
        setVbHistoryModalShow(true)
    }

    const renderCard = (number: number, field: ArtefactField): ReactNode => {
        return (
            <Col className="vision-board-card-col mt-1">
                <Card className="vision-board-card mt-1">
                    <Card.Header className="vision-board-card-header">
                        <OverflownText className="vision-board-title-head" text={field.head} />
                        {appContext?.auth.isEditingActive &&
                            <div className="vision-board-title-action">
                                <div
                                    className="vision-board-action-icon"
                                    onClick={() => editTextField(number, field.head, field.data)}
                                    title={`edit ${field.head} details`}
                                >
                                    <PencilSquare />
                                </div>
                                <div
                                    className="vision-board-action-icon"
                                    onClick={() => viewDataHistory(number, field.head, field.data)}
                                    title={`view ${field.head} history`}
                                >
                                    <ClockHistory />
                                </div>
                            </div>
                        }
                    </Card.Header>
                    {field.data ?
                        <div className="vision-board-card-body vision-board-data">
                            <HtmlRender content={field.data} />
                        </div>
                        :
                        <div className="vision-board-card-body vision-board-placeholder">{field.def}</div>
                    }
                </Card>
            </Col>
        )
    }

    return (
        <div className="content-container">
            {textEditorModalShow &&
                <TextEditorModal
                    show={textEditorModalShow}
                    handleClose={() => { setTextEditorModalShow(false); setSelectedDataField({ number: 0, name: "", content: "" }) }}
                    dataField={selectedDataField}
                    board={board}
                    entity={entity}
                    reload={apiLoad}
                />
            }

            {historyModalShow &&
                <VisionBoardHistoryModal
                    show={historyModalShow}
                    handleClose={() => { setVbHistoryModalShow(false); setSelectedHistoryDataField({ number: 0, name: "", content: "" }) }}
                    entityType={entity.type}
                    entityId={entity.id}
                    dataField={selectedHistoryDataField}
                />
            }

            <Banner
                titleIcon={bannerProps.titleIcon}
                title1={bannerProps.title1 && bannerProps.title1}
                title2={bannerProps.title1 && "Vision Board"}
                breadcrumbs={bannerProps.breadcrumbs}
                breadcrumbActive={bannerProps.breadcrumbs && bannerProps.breadcrumbActive ?
                    `${bannerProps.breadcrumbActive} > Vision Board` :
                    bannerProps.breadcrumbs && "Vision Board"}
                subtitle1={bannerProps.subtitle1}
                subtitle2={bannerProps.subtitle2}
            />

            <Container className="screen-container p-3">
                {(loading || boardLoading) ?
                    <div className="spinner-container">
                        <Spinner size={60} />
                    </div>
                    :
                    <>
                        {board &&
                            <>
                                <div className="vision-board-title">
                                    <div className="vision-board-title-content">
                                        <div className="vision-board-title-head">{board.field1.head}:</div>
                                        {board.field1.data ?
                                            <div className="vision-board-title-data">
                                                <HtmlRender content={board.field1.data} />
                                            </div>
                                            :
                                            <div className="vision-board-title-placeholder">{board.field1.def}</div>}
                                    </div>
                                    {appContext?.auth.isEditingActive &&
                                        <div className="vision-board-title-action">
                                            <div
                                                className="vision-board-action-icon"
                                                onClick={() => editTextField(1, board.field1.head, board.field1.data)}
                                                title={`edit ${board.field1.head} details`}
                                            >
                                                <PencilSquare />
                                            </div>
                                            <div
                                                className="vision-board-action-icon"
                                                onClick={() => viewDataHistory(1, board.field1.head, board.field1.data)}
                                                title={`view ${board.field1.head} history`}
                                            >
                                                <ClockHistory />
                                            </div>
                                        </div>
                                    }
                                </div>

                                <Row xs={4} className="vision-board-card-row g-1 pb-3">
                                    {renderCard(2, board.field2)}
                                    {renderCard(3, board.field3)}
                                    {renderCard(4, board.field4)}
                                    {renderCard(5, board.field5)}
                                    {renderCard(6, board.field6)}
                                    {renderCard(7, board.field7)}
                                    {renderCard(8, board.field8)}
                                    {renderCard(9, board.field9)}
                                </Row>

                                <Row>
                                    <Col className="vision-board-footer" xs={12}>
                                        <div className="vision-board-footer-head">Last updated:</div>
                                        <div className="vision-board-footer-data">{board.lastUpdatedAt ?
                                            DateTime.fromISO(board.lastUpdatedAt, { zone: 'utc' }).toLocal().toLocaleString(DateTime.DATETIME_MED) : "-"}</div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                }
            </Container>
        </div>
    );
}