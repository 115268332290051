// package imports
import { useContext } from 'react';
import { Switch, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';


// project imports
import Sidebar from './components/Sidebar/Sidebar';
import Home from './screens/Home/Home';
import BusinessLines from './screens/BusinessLines/BusinessLines';
import OfferFamilies from './screens/OfferFamilies/OfferFamilies';
import PrimaryMarkets from './screens/PrimaryMarkets/PrimaryMarkets';
import BusinessLineTypeSwitch from './components/TypeSwitches/BusinessLineTypeSwitch';
import OfferFamilyTypeSwitch from './components/TypeSwitches/OfferFamilyTypeSwitch';
import ProductTypeSwitch from './components/TypeSwitches/ProductTypeSwitch';
import { AppContext } from './services/context/contextProvider';
import Login from './screens/Login/Login';
import Settings from './screens/Settings/Settings';
import MessageModal from './components/MessageModal/MessageModal';
import ChannelCanvas from './screens/ChannelCanvas/ChannelCanvas';
import AuthRedirect from './components/Auth/AuthRedirect';
import PostAuthRedirect from './components/Auth/PostAuthRedirect';
import LogoutRedirect from './components/Auth/LogoutRedirect';
import FeedbackModal from './components/FeedbackModal/FeedbackModal';

declare global {
    interface Window { dataLayer: any; }
}

const location = window.location.hostname;
let configValue: string;
if (location.includes("localhost") || location.includes("plm-dev")) configValue = "GTM-W7B6FZZ";
else if (location.includes("plm-uat")) configValue = "GTM-P4K97SC";
else if (location.includes("plm-tool") || location.includes("plm-prod")) configValue = "GTM-KGH65TV";
else configValue = "";

const tagManagerArgs = {
    gtmId: configValue
}

TagManager.initialize(tagManagerArgs);

export default function App() {
    const appContext = useContext(AppContext);

    return (
        <div className="app-container">
            {appContext &&
                <>
                    <MessageModal
                        show={appContext.message.show}
                        handleClose={() => appContext.setMessage({ ...appContext.message, show: false })}
                        message={appContext.message.message}
                        status={appContext.message.status}
                    />

                    <FeedbackModal
                        show={appContext.feedback}
                        handleClose={() => appContext.setFeedback(false)}
                    />
                </>
            }
            {appContext?.auth.loggedIn ?
                <>
                    <Sidebar
                        options={appContext.sidebar.options}
                    />

                    <Switch>
                        <Route path="/business-lines/:businessLineLink/channels/:channelLink/:brandedChannelLink/channel-canvas">
                            <ChannelCanvas />
                        </Route>
                        <Route path="/business-lines/:businessLineLink/channels/:channelLink/channel-canvas">
                            <ChannelCanvas />
                        </Route>
                        <Route path="/business-lines/:businessLineLink/primary-markets">
                            <PrimaryMarkets />
                        </Route>
                        <Route path="/business-lines/:businessLineLink/:offerFamilyLink/:productLink/:type">
                            <ProductTypeSwitch />
                        </Route>
                        <Route path="/business-lines/:businessLineLink/:offerFamilyLink/:type">
                            <OfferFamilyTypeSwitch />
                        </Route>
                        <Route path="/business-lines/:businessLineLink/:type">
                            <BusinessLineTypeSwitch />
                        </Route>
                        <Route path="/business-lines/:businessLineLink">
                            <OfferFamilies />
                        </Route>
                        <Route path="/business-lines">
                            <BusinessLines />
                        </Route>
                        <Route path="/adminsettings">
                            <Settings />
                        </Route>
                        <Route path="/logout-external">
                            <LogoutRedirect />
                        </Route>
                        <Route path="/">
                            <Home />
                        </Route>
                    </Switch>
                </>
                :
                <Switch>
                    <Route path="/login-external">
                        <AuthRedirect />
                    </Route>
                    <Route path="/login-external-complete">
                        <PostAuthRedirect />
                    </Route>
                    <Login />
                </Switch>
            }
        </div >
    );
}