import { useContext, useEffect } from "react";
import { AppContext } from "../../services/context/contextProvider";

export default function LogoutRedirect() {
    const appContext = useContext(AppContext);
    
    useEffect(() => {
        var uniqueIdentifier = appContext?.auth.id;
        appContext?.logout();

        if (process.env.NODE_ENV === "development") {
            // redirect to development API (44301) from React dev server host (3000)
            window.location.replace(`https://localhost:44301/auth/logout?id=${uniqueIdentifier}`);
        }
        else {
            // this is not required when on the same host
            window.location.replace(`${window.location.protocol}//${window.location.host}/auth/logout?id=${uniqueIdentifier}`);
        }
    })

    return null;
}