import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { ApiResponse, OfferFamily } from "../../common/interfaces";
import putOfferFamily from "../../services/api/offerFamilies/putOfferFamily";
import postOfferFamily from "../../services/api/offerFamilies/postOfferFamily";
import Spinner from "../Spinner/Spinner";
import './OfferFamilyModal.scss';
import OfferFamiliesIcon from "../../assets/images/icons/OfferFamiliesIcon";
import { ExclamationCircle } from "react-bootstrap-icons";
import { AppError } from "../../common/appConstants";

export interface OfferFamilyModalProps {
    show: boolean;
    handleClose: () => void;
    offerFamily?: OfferFamily;
    businessLineId: number;
    businessLineName: string;
    reload: () => Promise<void>;
};

type OfferFamilyForm = {
    valid: boolean;
    validationError: string;
    name: string;
    nameError: string;
    description: string;
    descriptionError: string;
    productManager: string;
    productManagerError: string;
}

const blankOfferFamily : OfferFamilyForm = { valid: true, validationError: "", name: "", nameError: "", description: "", descriptionError: "", productManager: "", productManagerError: "" };

export default function OfferFamilyModal({ show, handleClose, offerFamily, businessLineId, businessLineName, reload } : OfferFamilyModalProps) {
    const [form, setForm] = useState(blankOfferFamily);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (offerFamily) {
            setForm({
                ...form,
                name: offerFamily.name,
                description: offerFamily.description,
                productManager: offerFamily.productManager
            });
        }
        else {
            setForm(blankOfferFamily);
        }
    }, [offerFamily]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e : React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };
        
        if (form.name.length < 1) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = "Offer Family name must be provided."
        }
        else if (form.name.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.nameError = `Offer Family name must be no more than 100 characters. [currently ${form.name.length} characters]`
        }

        if (form.description.length > 250) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.descriptionError = `Offer Family description must be no more than 250 characters. [currently ${form.description.length} characters]`
        }

        if (form.productManager.length > 100) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.productManagerError = `Offer Family Product manager must be no more than 100 characters. [currently ${form.productManager.length} characters]`
        }

        if (formUpdate.valid) {
            let res : ApiResponse<null> | ApiResponse<OfferFamily>;

            if (offerFamily?.id) {
                res = await putOfferFamily(offerFamily.id, {
                    businessLineId: businessLineId,
                    name: form.name,
                    description: form.description,
                    productManager: form.productManager
                });
            }
            else {
                res = await postOfferFamily({
                    businessLineId: businessLineId,
                    name: form.name,
                    description: form.description,
                    productManager: form.productManager
                });
            }

            if (res && res.success) {
                formUpdate = blankOfferFamily;
                handleClose();
                await reload();
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.validationError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.validationError = AppError.unknownError;
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e : React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            nameError: "",
            descriptionError: "",
            productManagerError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankOfferFamily); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="offer-family-modal-header">
                <OfferFamiliesIcon className="offer-family-modal-icon"/>
                <div className="offer-family-modal-title">
                    {offerFamily ? `Edit "${offerFamily.name}" details` : "Add new Offer Family"}
                </div>
            </Modal.Header>
            <Modal.Body className="offer-family-modal-body">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupBL">
                        <Form.Label>Business Line*</Form.Label>
                        <Form.Control
                            placeholder="Select Business Line name"
                            disabled
                            value={businessLineName}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Offer Family Name*</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Offer Family name"
                            value={form.name}
                            name="name"
                            onChange={handleChange}
                            isInvalid={form.nameError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.nameError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control  
                            as="textarea"
                            rows={2}
                            placeholder="Enter Offer Family description"
                            value={form.description}
                            name="description"
                            onChange={handleChange}
                            isInvalid={form.descriptionError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.descriptionError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupProductManager">
                        <Form.Label>Product Manager</Form.Label>
                        <Form.Control  
                            type="text"
                            placeholder="Enter Offer Family Product Manager"
                            value={form.productManager}
                            name="productManager"
                            onChange={handleChange}
                            isInvalid={form.productManagerError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.productManagerError}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>                
            </Modal.Body>
            <Modal.Footer className="offer-family-modal-footer">
                <div className="offer-family-modal-error">
                    {!form.valid && <><ExclamationCircle className="offer-family-modal-error-icon"/>{form.validationError}</>}
                </div>
                <div className="offer-family-modal-button-container">
                    <Button
                        className="offer-family-modal-button"
                        onClick={() => { setForm(blankOfferFamily); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button 
                        className={loading ? "offer-family-modal-button-loading" : "offer-family-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner/> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}