import { AppError, MessageStatus, Store } from '../../../common/appConstants';
import { ApiResponse, MessageResponse } from '../../../common/interfaces';

export interface GetProductResponse {
    businessLine: SelectedBusinessLine;
};

interface SelectedBusinessLine {
    link: string;
    name: string;
    headOfProduct: string;
    offerFamilies: OfferFamily[];
    selectedOfferFamily: SelectedOfferFamily;
}

interface OfferFamily {
    id: number;
    link: string;
    name: string;
}

interface SelectedOfferFamily {
    id: number;
    link: string;
    order: number;
    name: string;
    description: string;
    productManager: string;
    products: Product[];
    selectedProduct: Product;
}

interface Product {
    id: number;
    link: string;
    order: number;
    name: string;
    description: string;
    productManager: string;
    lifecycleStage: string;
}

export default async function getProduct(businessLineLink : string, offerFamilyLink : string, productLink : string) : Promise<ApiResponse<GetProductResponse>> {
    try {
        const response = await fetch(`/api/products/businessline/${businessLineLink}/offerfamily/${offerFamilyLink}/product/${productLink}`, {
            method: 'GET',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`
            }
        });
        if (response.status === 200) {
            const data : GetProductResponse = await response.json();
            return {
                success: true,
                body: data
            };
        }
        else if (response.status < 500) {
            const data : MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg,
                status: MessageStatus.warning
            }
        }
        else {
            return {
                success: false,
                message: AppError.serverError,
                status: MessageStatus.error
            } 
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}