import { AppError, MessageStatus, Store } from '../../../common/appConstants';
import { ApiResponse, BrandedChannelCreationOrUpdateRequest, MessageResponse } from '../../../common/interfaces';

export default async function putBrandedChannel(brandedChannelId: number, req : BrandedChannelCreationOrUpdateRequest) : Promise<ApiResponse<null>> {
    try {
        const response = await fetch(`/api/brandedchannels/${brandedChannelId}`, {
            method: 'PUT',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem(Store.accessToken)}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(req)
        });
        if (response.status === 204) {
            return {
                success: true
            };
        }
        else if (response.status < 500) {
            const data : MessageResponse = await response.json();
            return {
                success: false,
                message: data.msg,
                status: MessageStatus.warning
            }
        }
        else {
            return {
                success: false,
                message: AppError.serverError,
                status: MessageStatus.error
            } 
        }
    }
    catch (err) {
        console.log(err);
        return {
            success: false,
            message: AppError.responseError,
            status: MessageStatus.error
        }
    }
}