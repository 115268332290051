// package imports
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { ChevronDoubleRight, ChevronDoubleLeft, Envelope } from 'react-bootstrap-icons';
import {  useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

// project imports
import './Sidebar.scss';
import logo from '../../assets/images/logo.png';
import HomeIcon from '../../assets/images/icons/HomeIcon';
import BusinessLinesIcon from '../../assets/images/icons/BusinessLinesIcon';
import OfferFamiliesIcon from '../../assets/images/icons/OfferFamiliesIcon';
import ProductsIcon from '../../assets/images/icons/ProductsIcon';
import GlobeIcon from '../../assets/images/icons/GlobeIcon';
import ChannelsIcon from '../../assets/images/icons/ChannelsIcon';
import { AppContext } from '../../services/context/contextProvider';

export interface ISidebar {
    options: SidebarOptions;
}

type SidebarOptions = {
    homeActive?: boolean;
    businessLines: SidebarBLs;
    selectedBusinessLine?: SidebarSelBL;
    selectedOfferFamily?: SidebarSelOF;
    selectedProduct?: SidebarSelPR;
    selectedBusinessLineChannel?: SidebarSelBLC;
}

type SidebarBLs = {
    isActive?: boolean;
    list: SidebarSelection[];
}

type SidebarSelBL = {
    name: string;
    link: string;
    offerFamilies: SidebarSelection[];
    offerFamiliesBoardActive?: boolean;
    visionBoardActive?: boolean;
    channelBoardActive?: boolean;
    primaryMarketsActive?: boolean;
}

type SidebarSelOF = {
    name: string;
    link: string;
    products: SidebarSelection[];
    visionBoardActive?: boolean;
    roadmapActive?: boolean;
}

type SidebarSelPR = {
    name: string;
    link: string;
    visionBoardActive?: boolean;
    roadmapActive?: boolean;
}

type SidebarSelBLC = {
    name: string;
    link: string;
    brandedChanels: SidebarSelection[];
}

type SidebarSelection = {
    link: string;
    name: string;
    isActive?: boolean;
}

const bLinesLink = "/business-lines";
const cBoardLink = "/channels";
const vBoardLink = "/vision-board";
const rdMapLink  = "/roadmap";
const chCanvLink = "/channel-canvas";
const prMarketsLink = "/primary-markets";

export default function Sidebar({ options }: ISidebar) {
    const appContext = useContext(AppContext);
    const ref = useRef<HTMLDivElement>(null);

    const [collapsed, setCollapsed] = useState(true);
    const [blOpen, setBlOpen] = useState(false);
    const [ofOpen, setOfOpen] = useState(false);
    const [prOpen, setPrOpen] = useState(false);
    const [bcOpen, setBcOpen] = useState(false);

    useEffect(() => {
        scrollToBottom();
    }, [options])

    const scrollToBottom = () => {
        setTimeout(() => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: "smooth" });
            }
        }, 200)
    }

    return (
        <div className="sidebar-container">
            <ProSidebar
                collapsed={collapsed}
            >
                <SidebarHeader>
                    <div className={`sidebar-logo-container-${collapsed ? "collapsed" : "expanded"}`}>
                        {collapsed ?
                        <ChevronDoubleRight 
                            className="sidebar-icon-small"
                            onClick={() => setCollapsed(false)}
                        />
                        :
                        <>
                        <img 
                            src={logo} 
                            alt="Logo"
                            className="sidebar-logo"
                        />
                        <ChevronDoubleLeft 
                            className="sidebar-icon-small"
                            onClick={() => setCollapsed(true)}
                        />
                        </>
                        }
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <Menu>
                        <MenuItem 
                            className={options.homeActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                            icon={<HomeIcon className="sidebar-icon"/>}
                        >
                            Home
                            {!options.homeActive &&
                            <Link to="/"/>}
                        </MenuItem>
                        
                        {/* BusinessLines section*/}
                        <SubMenu
                            title="Business Lines" 
                            icon={<GlobeIcon className="sidebar-icon"/>}
                            className="sidebar-menuitem"
                            open={blOpen}
                            onClick={() => setBlOpen(!blOpen)}
                        >
                            {collapsed &&
                            <div className="sidebar-subtitle">Business Lines</div>
                            }
                            <MenuItem
                                className={options.businessLines.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                View all
                                {!options.businessLines.isActive &&
                                <Link to={bLinesLink}/>}
                            </MenuItem>
                            <hr className="sidebar-subdivider"/>
                            {options.businessLines.list.length > 0 ?
                            <>
                            {options.businessLines.list.map((bl, i) => 
                            <MenuItem 
                                key={`sb-bl-${i}`}
                                className={bl.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {bl.name}
                                {!bl.isActive &&
                                <Link to={`${bLinesLink}/${bl.link}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Business Lines
                            </MenuItem>
                            }
                        </SubMenu>
                        
                        {/* Selected BusinessLine section*/}
                        <hr className={options.selectedBusinessLine ? "sidebar-divider" : "sidebar-hidden"} />
                        {collapsed ?
                        <SubMenu 
                            icon={<><BusinessLinesIcon className="sidebar-icon"/><div className="sidebar-menuitem-collapsed-title">{options.selectedBusinessLine?.name}</div></>}
                            className={!options.selectedBusinessLine ? "sidebar-hidden" : "sidebar-menuitem-collapsed"}
                        >
                            <div className="sidebar-subtitle">Offer Families</div>
                            <MenuItem
                                className={options.selectedBusinessLine?.offerFamiliesBoardActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                View all
                                {!options.selectedBusinessLine?.offerFamiliesBoardActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}`}/>}
                            </MenuItem>
                            <hr className="sidebar-subdivider"/>
                            {options.selectedBusinessLine && options.selectedBusinessLine?.offerFamilies.length > 0 ?
                            <>
                            {options.selectedBusinessLine?.offerFamilies.map((of, i) => 
                            <MenuItem 
                                key={`sb-of-${i}`}
                                className={of.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {of.name}
                                {!of.isActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${of.link}${vBoardLink}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Offer Families
                            </MenuItem>
                            }
                            <MenuItem
                                className={options.selectedBusinessLine?.visionBoardActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"} 
                            >
                                Vision Board
                                {!options.selectedBusinessLine?.visionBoardActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${vBoardLink}`}/>}
                            </MenuItem>
                            <MenuItem
                                className={options.selectedBusinessLine?.channelBoardActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"}
                            >
                                Channel Board
                                {!options.selectedBusinessLine?.channelBoardActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${cBoardLink}`}/>}
                            </MenuItem>
                            <MenuItem
                                className={options.selectedBusinessLine?.primaryMarketsActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"} 
                            >
                                Primary Markets
                                {!options.selectedBusinessLine?.primaryMarketsActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${prMarketsLink}`}/>}
                            </MenuItem>
                        </SubMenu>
                        :
                        <div className={!options.selectedBusinessLine ? "sidebar-hidden" : ""}>
                        <div className="sidebar-title">
                            <BusinessLinesIcon className="sidebar-icon"/>
                            {options.selectedBusinessLine?.name}
                        </div>
                        <SubMenu
                            title="Offer Families"
                            className="sidebar-menuitem"
                            open={ofOpen}
                            onClick={() => setOfOpen(!ofOpen)}
                        >
                            <MenuItem
                                className={options.selectedBusinessLine?.offerFamiliesBoardActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                View all
                                {!options.selectedBusinessLine?.offerFamiliesBoardActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}`}/>}
                            </MenuItem>
                            <hr className="sidebar-subdivider"/>
                            {options.selectedBusinessLine && options.selectedBusinessLine?.offerFamilies.length > 0 ?
                            <>
                            {options.selectedBusinessLine?.offerFamilies.map((of, i) => 
                            <MenuItem 
                                key={`sb-of-${i}`}
                                className={of.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {of.name}
                                {!of.isActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${of.link}${vBoardLink}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Offer Families
                            </MenuItem>
                            }
                        </SubMenu>
                        <MenuItem
                            className={options.selectedBusinessLine?.visionBoardActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                        >
                            Vision Board
                            {!options.selectedBusinessLine?.visionBoardActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${vBoardLink}`}/>}
                        </MenuItem>
                        <MenuItem
                            className={options.selectedBusinessLine?.channelBoardActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"}
                        >
                            Channel Board
                            {!options.selectedBusinessLine?.channelBoardActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${cBoardLink}`}/>}
                        </MenuItem>
                        <MenuItem
                            className={options.selectedBusinessLine?.primaryMarketsActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                        >
                            Primary Markets
                            {!options.selectedBusinessLine?.primaryMarketsActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${prMarketsLink}`}/>}
                        </MenuItem>
                        </div>
                        }

                        {/* Selected OfferFamily section*/}
                        <hr className={options.selectedOfferFamily ? "sidebar-divider" : "sidebar-hidden"} />
                        {collapsed ?
                        <SubMenu 
                            icon={<><OfferFamiliesIcon className="sidebar-icon"/><div className="sidebar-menuitem-collapsed-title">{options.selectedOfferFamily?.name}</div></>}
                            className={!options.selectedOfferFamily ? "sidebar-hidden" : "sidebar-menuitem-collapsed"}
                        >
                            <div className="sidebar-subtitle">Products</div>
                            {options.selectedOfferFamily && options.selectedOfferFamily?.products.length > 0 ?
                            <>
                            {options.selectedOfferFamily?.products.map((pr, i) => 
                            <MenuItem 
                                key={`sb-pr-${i}`}
                                className={pr.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {pr.name}
                                {!pr.isActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}/${pr.link}${vBoardLink}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Products
                            </MenuItem>
                            }
                            <MenuItem
                                className={options.selectedOfferFamily?.visionBoardActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"} 
                            >
                                Vision Board
                                {!options.selectedOfferFamily?.visionBoardActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}${vBoardLink}`}/>}
                            </MenuItem>
                            <MenuItem
                                className={options.selectedOfferFamily?.roadmapActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"} 
                            >
                                Roadmap
                                {!options.selectedOfferFamily?.roadmapActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}${rdMapLink}`}/>}
                            </MenuItem>
                        </SubMenu>
                        :
                        <div className={!options.selectedOfferFamily ? "sidebar-hidden" : ""}>
                        <div className="sidebar-title">
                            <OfferFamiliesIcon className="sidebar-icon"/>
                            {options.selectedOfferFamily?.name}
                        </div>
                        <SubMenu
                            title="Products"
                            className="sidebar-menuitem"
                            open={prOpen}
                            onClick={() => setPrOpen(!prOpen)}
                        >
                            {options.selectedOfferFamily && options.selectedOfferFamily?.products.length > 0 ?
                            <>
                            {options.selectedOfferFamily?.products.map((pr, i) => 
                            <MenuItem 
                                key={`sb-pr-${i}`}
                                className={pr.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {pr.name}
                                {!pr.isActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}/${pr.link}${vBoardLink}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Products
                            </MenuItem>
                            }
                        </SubMenu>
                        <MenuItem
                            className={options.selectedOfferFamily?.visionBoardActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                        >
                            Vision Board
                            {!options.selectedOfferFamily?.visionBoardActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}${vBoardLink}`}/>}
                        </MenuItem>
                        <MenuItem
                            className={options.selectedOfferFamily?.roadmapActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                        >
                            Roadmap
                            {!options.selectedOfferFamily?.roadmapActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}${rdMapLink}`}/>}
                        </MenuItem>
                        </div>
                        }
                        
                        {/* Selected Product section*/}
                        <hr className={options.selectedProduct ? "sidebar-divider" : "sidebar-hidden"} />
                        {collapsed ?
                        <SubMenu 
                            icon={<><ProductsIcon className="sidebar-icon"/><div className="sidebar-menuitem-collapsed-title">{options.selectedProduct?.name}</div></>}
                            className={!options.selectedProduct ? "sidebar-hidden" : "sidebar-menuitem-collapsed"}
                        >
                            <MenuItem
                                className={options.selectedProduct?.visionBoardActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"} 
                            >
                                Vision Board
                                {!options.selectedProduct?.visionBoardActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}/${options.selectedProduct?.link}${vBoardLink}`}/>}
                            </MenuItem>
                            <MenuItem
                                className={options.selectedProduct?.roadmapActive ? "sidebar-submenuitem-main sidebar-menuitem-active" : "sidebar-submenuitem-main"} 
                            >
                                Roadmap
                                {!options.selectedProduct?.roadmapActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}/${options.selectedProduct?.link}${rdMapLink}`}/>}
                            </MenuItem>
                        </SubMenu>
                        :
                        <div className={!options.selectedProduct ? "sidebar-hidden" : ""}>
                        <div className="sidebar-title">
                            <ProductsIcon className="sidebar-icon"/>
                            {options.selectedProduct?.name}
                        </div>
                        <MenuItem
                            className={options.selectedProduct?.visionBoardActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                        >
                            Vision Board
                            {!options.selectedProduct?.visionBoardActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}/${options.selectedProduct?.link}${vBoardLink}`}/>}
                        </MenuItem>
                        <MenuItem
                            className={options.selectedProduct?.roadmapActive ? "sidebar-menuitem sidebar-menuitem-active" : "sidebar-menuitem"} 
                        >
                            Roadmap
                            {!options.selectedProduct?.roadmapActive &&
                            <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}/${options.selectedOfferFamily?.link}/${options.selectedProduct?.link}${rdMapLink}`}/>}
                        </MenuItem>
                        </div>
                        }

                        {/* Selected BusinessLineChannel section*/}
                        <hr className={options.selectedBusinessLineChannel ? "sidebar-divider" : "sidebar-hidden"} />
                        {collapsed ?
                        <SubMenu 
                            icon={<><ChannelsIcon className="sidebar-icon"/><div className="sidebar-menuitem-collapsed-title">{options.selectedBusinessLineChannel?.name}</div></>}
                            className={!options.selectedBusinessLineChannel ? "sidebar-hidden" : "sidebar-menuitem-collapsed"}
                        >
                            <div className="sidebar-subtitle">Branded Channels</div>
                            {options.selectedBusinessLineChannel && options.selectedBusinessLineChannel?.brandedChanels.length > 0 ?
                            <>
                            {options.selectedBusinessLineChannel.brandedChanels.map((bc, i) => 
                            <MenuItem 
                                key={`sb-bc-${i}`}
                                className={bc.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {bc.name}
                                {!bc.isActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${cBoardLink}/${options.selectedBusinessLineChannel?.link}/${bc.link}${chCanvLink}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Branded Channels
                            </MenuItem>
                            }
                        </SubMenu>
                        :
                        <div className={!options.selectedBusinessLineChannel ? "sidebar-hidden" : ""}>
                        <div className="sidebar-title">
                            <ChannelsIcon className="sidebar-icon"/>
                            {options.selectedBusinessLineChannel?.name}
                        </div>
                        <SubMenu
                            title="Branded Channels"
                            className="sidebar-menuitem"
                            open={bcOpen}
                            onClick={() => setBcOpen(!bcOpen)}
                        >
                            {options.selectedBusinessLineChannel && options.selectedBusinessLineChannel?.brandedChanels.length > 0 ?
                            <>
                            {options.selectedBusinessLineChannel.brandedChanels.map((bc, i) => 
                            <MenuItem 
                                key={`sb-bc-${i}`}
                                className={bc.isActive ? "sidebar-submenuitem sidebar-submenuitem-active" : "sidebar-submenuitem"} 
                            >
                                {bc.name}
                                {!bc.isActive &&
                                <Link to={`${bLinesLink}/${options.selectedBusinessLine?.link}${cBoardLink}/${options.selectedBusinessLineChannel?.link}/${bc.link}${chCanvLink}`}/>}
                            </MenuItem>
                            )}
                            </>
                            :
                            <MenuItem
                                className="sidebar-submenuitem-disabled"
                            >
                                No Branded Channels
                            </MenuItem>
                            }
                        </SubMenu>
                        </div>
                        }
                    </Menu>
                </SidebarContent>
                <SidebarFooter className="sidebar-footer">
                    <div 
                        onClick={() => appContext?.setFeedback(true)}
                        className="sidebar-footer-feedback"
                        ref={ref}
                    >
                        <Envelope className="sidebar-footer-icon"/> Feedback
                    </div>
                </SidebarFooter>
            </ProSidebar>
        </div>
    );
}