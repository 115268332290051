import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import ChannelsIcon from "../../assets/images/icons/ChannelsIcon";
import VisionBoardIcon from "../../assets/images/icons/VisionBoardIcon";
import { EntityType, MessageStatus } from "../../common/appConstants";
import { ApiResponse, GenericEntity, ArtefactResponse, ArtefactUpdateRequest, ArtefactField } from "../../common/interfaces";
import postChannelCanvasDataField from "../../services/api/channelCanvas/postChannelCanvasDataField";
import postVisionBoardDataField from "../../services/api/visionBoard/postVisionBoardDataField";
import { AppContext } from "../../services/context/contextProvider";
import { TextEditor } from "../TextEditor/TextEditor";
import './TextEditorModal.scss';

export interface TextEditorModalProps {
    show: boolean;
    handleClose: () => void;
    board?: ArtefactResponse;
    dataField: DataField;
    entity: GenericEntity;
    reload: () => Promise<void>;
};

export interface DataField {
    number: number;
    name: string;
    content: string;
}

export default function TextEditorModal({ show, handleClose, board, dataField, entity, reload } : TextEditorModalProps) {
    const appContext = useContext(AppContext);
    
    const [loading, setLoading] = useState(false);

    const attemptSave = async (content : string) => {
        setLoading(true);
        
        if (board) {
            if (content === "<p></p>") content = "";

            if (content.length > 8000) {
                appContext?.setMessage({ show: true, message: `The formatted content length (${content.length}) is greater than the allowed length (8000)`, status: MessageStatus.warning });
            }
            else {
                let originalData = "";
                const field = `field${dataField.number}` as keyof ArtefactResponse;
                if (board) {
                    const originalDataField = board[field] as ArtefactField;
                    originalData = originalDataField.data ? originalDataField.data : "";
                }
                const req : ArtefactUpdateRequest = {
                    dataField: dataField.number,
                    originalData: originalData,
                    newData: content
                };
                
                let res : ApiResponse<null>;
    
                if (entity.type === EntityType.businessLineChannel || entity.type === EntityType.brandedChannel) {
                    res = await postChannelCanvasDataField(entity.type, entity.id, req);
                }
                else {
                    res = await postVisionBoardDataField(entity.type, entity.id, req);
                }
    
                if (res && res.success) {
                    await reload();
                    handleClose();
                }
                else if (res && res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }
        }
        
        setLoading(false);
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            size="lg"
        >
            <Modal.Header className="text-editor-modal-header">
                {entity.type === EntityType.businessLineChannel || entity.type === EntityType.brandedChannel ?
                <ChannelsIcon className="text-editor-modal-icon"/>
                :
                <VisionBoardIcon className="text-editor-modal-icon"/>
                }
                <div className="text-editor-modal-title">
                    {`Edit "${entity.name}" ${entity.type === EntityType.businessLineChannel || entity.type === EntityType.brandedChannel ? "Channel Canvas" : "Vision Board"} "${dataField.name}"`}
                </div>
            </Modal.Header>
            <Modal.Body className="text-editor-modal-body">
                <TextEditor
                    save={(content) => {
                        attemptSave(content);
                    }}
                    cancel={handleClose}
                    content={dataField.content}
                    loading={loading}
                />
            </Modal.Body>
        </Modal>
    );
}