import { useState, useContext } from "react";
import styled from "styled-components";

import "./ToggleGroupButtons.scss"
import { RoadMapConstants } from "../../../common/appConstants"
import { Button, Col, Row } from "react-bootstrap";
import { PlusCircle } from "react-bootstrap-icons";
import { AppContext } from "../../../services/context/contextProvider";

const ButtonToggle = styled.button`
  border: 1px solid #93186C;
  color: #93186C;
  padding-left: 15px;
  padding-right: 15px;
  ${({ disabled }) => disabled && `
    opacity: 1;
    color: white;
    background-color: #93186C;
  `}
`;

export function useToggleGroupButtons() {
    const appContext = useContext(AppContext);

    const [activeButton, setActiveButton] = useState(RoadMapConstants.defaultActiveButton);
    const [displayState, setDisplayState] = useState(false);

    const handleCardDisplayState = () => setDisplayState(true)

    return {
        activeButton,
        displayState,
        setDisplayState,
        toggleButtons: (
            <Row className={"container-content-right roadmap-button-holder mb-1"}>
                <Col className={"add-button-holder left"} xl={8} lg={9} sm={8}>
                    {RoadMapConstants.buttonTypes.map((type) => (
                        <ButtonToggle key={type} disabled={activeButton === type} onClick={() => setActiveButton(type)}>
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </ButtonToggle>
                    ))}
                </Col>
                <Col className={"add-button-holder right"} xl={4} lg={3} sm={4}>
                    {appContext?.auth.isEditingActive &&
                        <Button variant="outline-primary" onClick={handleCardDisplayState}>
                            <PlusCircle className="add-button-icon" /> Roadmap Card
                        </Button>
                    }
                </Col>
            </ Row>
        )
    }
}
