import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { ExclamationCircle } from "react-bootstrap-icons";
import RoadmapIcon from "../../../assets/images/icons/RoadmapIcon";
import { AppError } from "../../../common/appConstants";
import { ApiResponse, GenericEntity, RoadmapCard } from "../../../common/interfaces";
import postRoadmapCard from "../../../services/api/roadmapCard/postRoadmapCard";
import putRoadmapCard from "../../../services/api/roadmapCard/putRoadmapCard";
import { useRoadmapContext } from "../../../services/context/useRoadmapContext";
import Spinner from "../../Spinner/Spinner";
import './CardModal.scss';
import { RoadMapConstants } from "../../../common/appConstants";
import { ActionMeta, MultiValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';


export interface CardModalProps {
    show: boolean;
    handleClose: () => void;
    card?: RoadmapCard;
    entity: GenericEntity;
};

type CardForm = {
    valid: boolean;
    validationError: string;
    roadmapId: number;
    order: number;
    title: string;
    titleError: string;
    themeError: string;
    objective: string;
    objectiveError: string;
    jobStory: string;
    jobStoryError: string;
    state: string;
    stateError: string;
    tags: SelectOption[];
    tagsError: string;
}

type SelectOption = {
    value: string;
    label: string;
}

const createOption = (label: string) => ({
    label,
    value: label
});

const blankCard: CardForm = { valid: true, validationError: "", roadmapId: 0, order: 0, title: "", titleError: "", themeError: "", objective: "", objectiveError: "", jobStory: "", jobStoryError: "", state: "", stateError: "", tags: [], tagsError: "" };

export default function CardModal({ show, handleClose, card, entity }: CardModalProps) {
    const { setReload } = useRoadmapContext();
    const [form, setForm] = useState(blankCard);
    const [loading, setLoading] = useState(false);

    const times = RoadMapConstants.tabs.roadmap.concat(RoadMapConstants.tabs.candidates, RoadMapConstants.tabs.completed);

    useEffect(() => {
        if (card) {
            setForm({
                ...form,
                title: card.title,
                objective: card.objective,
                jobStory: card.jobStory,
                state: card.state,
                tags: card.tags.map(val => createOption(val)),
            });
        }
        else {
            setForm(blankCard);
        }
    }, [card]); // eslint-disable-line react-hooks/exhaustive-deps

    const attemptSave = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setLoading(true);

        let formUpdate = {
            ...form,
            valid: true
        };

        if (form.state.length === 0) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.stateError = "State must be selected."
        }

        if ((form.title.length < 1)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.titleError = "Theme must be provided."
        }

        if ((form.title.length > 100 || form.title.length < 2)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.titleError = `Theme must be between 2 and 100 characters in length. Currently ${formUpdate.title.length}`
        }

        if ((form.jobStory.length < 1)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.jobStoryError = `Job Story must be provided.`
        }

        if ((form.jobStory.length > 1000 || form.jobStory.length < 2)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.jobStoryError = `Job Story must be at least 2 characters in length and less than 1000. Currently ${formUpdate.jobStory.length}`
        }

        if ((form.objective.length < 1)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.objectiveError = `Strategic Objective must be provided.`
        }

        if ((form.objective.length > 50 || form.objective.length < 2)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.objectiveError = `Strategic Objective must be between 2 and 50 characters in length. Currently ${formUpdate.objective.length}`
        }

        if ((form.tags.length > 2)) {
            formUpdate.valid = false;
            formUpdate.validationError = AppError.validationError;
            formUpdate.tagsError = "You can only add two tags."
        }

        if (formUpdate.valid) {
            let res: ApiResponse<null> | ApiResponse<RoadmapCard>;

            if (card?.id) {
                res = await putRoadmapCard(card.id, {
                    roadmapId: card.roadmapId,
                    order: card.order,
                    title: form.title,
                    objective: form.objective,
                    jobStory: form.jobStory,
                    state: form.state,
                    tags: form.tags.map(opt => opt.value)
                });
            }
            else {
                res = await postRoadmapCard({
                    roadmapId: form.roadmapId,
                    order: form.order,
                    title: form.title,
                    objective: form.objective,
                    jobStory: form.jobStory,
                    state: form.state,
                    tags: form.tags.map(opt => opt.value),
                }, entity);
            }

            if (res && res.success) {
                formUpdate = blankCard;
                handleClose();
                setReload(true);
            }
            else if (res && res.message) {
                formUpdate.valid = false;
                formUpdate.titleError = res.message;
            }
            else {
                formUpdate.valid = false;
                formUpdate.titleError = "An unknown error occurred, please try saving again";
            }
        }

        setForm(formUpdate);
        setLoading(false);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            titleError: "",
            objectiveError: "",
            jobStoryError: "",
            stateError: "",
            tagsError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    const handleSelectChange = (newValue: MultiValue<SelectOption>, meta: ActionMeta<SelectOption>) => {
        if (meta.name) {
            setForm({
                ...form,
                valid: true,
                validationError: "",
                titleError: "",
                objectiveError: "",
                jobStoryError: "",
                stateError: "",
                tagsError: "",
                [meta.name]: newValue
            });
        }
    }

    const handleSelectStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setForm({
            ...form,
            valid: true,
            validationError: "",
            titleError: "",
            objectiveError: "",
            jobStoryError: "",
            stateError: "",
            tagsError: "",
            [e.currentTarget.name]: e.currentTarget.value
        });
    }

    return (
        <Modal
            show={show}
            onHide={() => { setForm(blankCard); handleClose() }}
            centered
            size="xl"
        >
            <Modal.Header className="card-modal-header">
                <RoadmapIcon className="card-modal-icon" />
                <div className="card-modal-title">
                    {card ? `Edit card details for "${card.title}"` : "Add a new Roadmap card"}
                </div>
            </Modal.Header>
            <Modal.Body className="card-modal-body">
                <Form>
                    <Form.Group className="mb-3" controlId="formGroupTitle">
                        <Form.Label>Theme*</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter a short phrase capturing the essence of a need, want or problem the product will solve for to add customer value"
                            value={form.title}
                            name="title"
                            onChange={handleChange}
                            isInvalid={form.titleError.length > 0}
                            disabled={loading}
                        />
                        <Form.Control.Feedback type="invalid">
                            {form.titleError}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formGroupObjective">
                                <Form.Label>Strategic Objective*</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Strategic objective"
                                    value={form.objective}
                                    name="objective"
                                    onChange={handleChange}
                                    isInvalid={form.objectiveError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.objectiveError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formGroupJobStory">
                                <Form.Label>Job Story*</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={4}
                                    placeholder={RoadMapConstants.jobStory}
                                    value={form.jobStory}
                                    name="jobStory"
                                    onChange={handleChange}
                                    isInvalid={form.jobStoryError.length > 0}
                                    disabled={loading}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {form.jobStoryError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="formGroupState">
                                <Form.Label>State*</Form.Label>
                                <Form.Select
                                    value={form.state}
                                    name="state"
                                    onChange={handleSelectStateChange}
                                    isInvalid={form.stateError.length > 0}
                                    disabled={loading}
                                    className={form.state ? "" : "card-modal-placeholder"}
                                >
                                    <option value={""}>Select a time horizon</option>
                                    {times.map(option => (
                                        <option key={option.toLowerCase()} value={option} className="card-modal-selected">
                                            {option.charAt(0).toUpperCase() + option.slice(1)}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    {form.stateError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group controlId="formGroupTags">
                                <Form.Label>Tags</Form.Label>
                                <CreatableSelect
                                    className={`mb-3 ${form.tagsError.length > 0 ? 'dnd-warning-sign' : ''}`}
                                    styles={{
                                        placeholder: (base) => ({ ...base, color: '#6c757d' }),
                                        menuPortal: (base) => ({ ...base, zIndex: 999999 }),
                                        indicatorsContainer: () => ({ display: 'none' })
                                    }}
                                    backspaceRemovesValue
                                    menuPortalTarget={document.body}
                                    menuShouldBlockScroll={true}
                                    menuPlacement="auto"
                                    placeholder="Enter optional tags"
                                    value={form.tags}
                                    name="tags"
                                    isMulti
                                    maxMenuHeight={168}
                                    closeMenuOnSelect={true}
                                    getOptionLabel={(opt: SelectOption) => opt.label}
                                    getOptionValue={(opt: SelectOption) => opt.value}
                                    onChange={handleSelectChange}
                                    options={undefined}
                                ></CreatableSelect>
                                <Form.Control.Feedback type="invalid" className={`${form.tagsError.length > 0 ? 'dnd-tags-error' : ''}`}>
                                    {form.tagsError}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                </Form>
            </Modal.Body>
            <Modal.Footer className="card-modal-footer">
                <div className="card-modal-error">
                    {!form.valid && <><ExclamationCircle className="card-modal-error-icon" />{form.validationError}</>}
                </div>
                <div className="card-modal-button-container">
                    <Button
                        className="card-modal-button"
                        onClick={() => { setForm(blankCard); handleClose() }}
                        variant="outline-secondary"
                    >
                        Cancel
                    </Button>
                    <Button
                        className={loading ? "card-modal-button-loading" : "card-modal-button"}
                        onClick={attemptSave}
                        variant="outline-primary"
                        disabled={loading}
                    >
                        {loading ? <Spinner /> : "Save"}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}