import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { ArrowDownCircle, ArrowUpCircle, PencilSquare, PlusCircle, Trash } from 'react-bootstrap-icons';
import { BusinessLine, Country, BusinessLineCountry, BusinessLineCountryCreationOrUpdateRequest } from '../../common/interfaces';
import deleteBusinessLineCountry from '../../services/api/businessLineCountries/deleteBusinessLineCountry';
import getBusinessLineCountries from '../../services/api/businessLineCountries/getBusinessLineCountries';
import putBusinessLineCountry from '../../services/api/businessLineCountries/putBusinessLineCountry';
import ConfirmModal from '../ConfirmModal/ConfirmModal';
import PrimaryMarketModal from '../PrimaryMarketModal/PrimaryMarketModal';
import Spinner from '../Spinner/Spinner';
import './PrimaryMarketsTab.scss';
import { AppContext } from '../../services/context/contextProvider';
import { MessageStatus } from '../../common/appConstants';
import getBusinessLines from '../../services/api/businessLines/getBusinessLines';
import getCountries from '../../services/api/countries/getCountries';

export default function PrimaryMarketsTab() {
    const appContext = useContext(AppContext);
    
    const [loading, setLoading] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmModalMessage, setConfirmModalMessage] = useState("");
    const [confirmModalCallback, setConfirmModalCallback] = useState<() => void>(() => () => alert("Callback not set!"));
    const [confirmModalLoading, setConfirmModalLoading] = useState(false);
    const [businessLineId, setBusinessLineId] = useState(0);
    //const [selectedBusinessLine, setSelectedBusinessLine] = useState<BusinessLine>();
    const [primaryMarkets, setPrimaryMarkets] = useState<BusinessLineCountry[]>([]);
    const [primaryMarketModalShow, setPrimaryMarketModalShow] = useState(false);
    const [selectedPrimaryMarket, setSelectedPrimaryMarket] = useState<BusinessLineCountry>();
    const [businessLines, setBusinessLines] = useState<BusinessLine[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);
    const [regions, setRegions] = useState<string[]>([]);

    const apiLoad = useCallback(
        async () => {
            setLoading(true);

            let businessLinesRes = await getBusinessLines();
            if (businessLinesRes.success && businessLinesRes.body) {
                setBusinessLines(businessLinesRes.body.businessLines);
            }
            else if (businessLinesRes.message) {
                appContext?.setMessage({ show: true, message: businessLinesRes.message, status: businessLinesRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error retrieving Business Lines occurred!", status: MessageStatus.error });
            }

            let countriesRes = await getCountries();
            if (countriesRes.success && countriesRes.body) {
                setCountries(countriesRes.body.countries);
            }
            else if (countriesRes.message) {
                appContext?.setMessage({ show: true, message: countriesRes.message, status: countriesRes.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error retrieving Countries occurred!", status: MessageStatus.error });
            }

            if (businessLineId > 0)
            {
                let res = await getBusinessLineCountries(businessLineId, false);

                if (res.success && res.body) {
                    setPrimaryMarkets(res.body.businessLineCountries);
                    setRegions(res.body.businessLineCountries.map((blc,i)=> blc.region));
                }
                else if (res.message) {
                    appContext?.setMessage({ show: true, message: res.message, status: res.status });
                }
                else {
                    appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
                }
            }

            setLoading(false);
        },
        [businessLineId, appContext]
    );
    
    useEffect(() => {
        apiLoad();
    }, [businessLineId]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const confirmDelete = (message: string, id: number) => {
        setConfirmModalMessage(`Are you sure you want to delete Primary Market ${message}?`);
        setConfirmModalCallback(() => () => performDelete(id));
        setConfirmModalShow(true);
    }

    const performDelete = async (id: number) => {
        setConfirmModalLoading(true);
        const res = await deleteBusinessLineCountry(id);

        if (res.success) {
            await apiLoad();
        }
        else if (res.message) {
            var target = primaryMarkets.find((pm) => pm.id === id);
            var errMsg = 'Cannot delete ' + target?.country.name + ' from Business Line: ' + res.message;

            appContext?.setMessage({ show: true, message: errMsg, status: res.status });
        }
        else {
            appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
        }
        
        setConfirmModalShow(false);
        setConfirmModalLoading(false);
    }

    const editPrimaryMarket = (primaryMarket: BusinessLineCountry) => {
        setSelectedPrimaryMarket(primaryMarket);
        setPrimaryMarketModalShow(true);
    }

    const addPrimaryMarket = () => {
        if (businessLineId > 0)
        {
            setSelectedPrimaryMarket(undefined);
            setPrimaryMarketModalShow(true);
        }
        else
            appContext?.setMessage({ show: true, message: "Please select a Business Line first.", status: MessageStatus.info });
    }

    const handleSelectChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
        setBusinessLineId(parseInt(e.currentTarget.value));
    }

    const moveUpOrDown = async (id: number, moveUp: boolean) => {
        setLoading(true);

        var target = primaryMarkets.find((pm) => pm.id === id);

        if (target)
        {
            var res = await putBusinessLineCountry(target.id, {
                businessLineId: businessLineId,
                countryId: target.country.id,
                region: target.region,
                isActive: target.isActive,
                moveUp: moveUp,
                moveDown: !moveUp
            });
        
            if (res.success) {
                await apiLoad();
            }
            else if (res.message) {
                appContext?.setMessage({ show: true, message: res.message, status: res.status });
            }
            else {
                appContext?.setMessage({ show: true, message: "An unknown error occurred!", status: MessageStatus.error });
            }
        }
        
        setLoading(false);
    }

    return (
        <div className="primary-markets-tab-container p-3">
            <ConfirmModal
                show={confirmModalShow}
                message={confirmModalMessage}
                callback={confirmModalCallback}
                handleClose={() => setConfirmModalShow(false)}
                loading={confirmModalLoading}
            />

            <PrimaryMarketModal
                show={primaryMarketModalShow}
                handleClose={() => { setPrimaryMarketModalShow(false); setSelectedPrimaryMarket(undefined) }}
                primaryMarket={selectedPrimaryMarket}
                selectedBusinessLineId={businessLineId}
                businessLines={businessLines}
                countries={countries}
                regions={regions}
                reload={apiLoad}
                //onNameChange=
            />
            
            {loading ?
            <div className="spinner-container">
                <Spinner size={60}/>
            </div>
            :
            <>
            <div className="primary-markets-tab-top-container mb-2">
                <Form.Select 
                    value={businessLineId}
                    name="businessLineId"
                    onChange={handleSelectChange}
                    disabled={loading}
                    className={businessLineId > 0 ? "": "product-modal-placeholder"}
                >
                    <option key="ofo-0" value={"0"} disabled selected hidden>Select a Business Line</option>
                    {businessLines.map((bl, i) =>
                        <option className="product-modal-selected" key={`ofo-${i}`} value={bl.id}>{bl.name}</option>
                    )}
                </Form.Select>
                {/* <Form.Control.Feedback type="invalid">
                    {form.offerFamilyIdError}
                </Form.Control.Feedback> */}
                <Button 
                    variant="outline-primary"
                    onClick={addPrimaryMarket}
                    className="primary-markets-tab-add-button"
                >
                    <PlusCircle className="add-button-icon"/> Primary Market
                </Button>
            </div>
            
            <div className="primary-markets-tab-table-container">
                <Table className="primary-markets-tab-table" bordered size="sm">
                    <thead>
                        <tr>
                            <th>Region</th>
                            <th>Country</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {primaryMarkets.length > 0 ?
                        primaryMarkets.map((pm, i) => 
                        <tr key={`primary-market-${i}`}>
                            <td>{pm.region}</td>
                            <td>{pm.country.name}</td>
                            {pm.isActive ? <td className='active-tick'>&#10004;</td> : <td className='active-cross'>&#8212;</td> }
                            <td className="primary-markets-tab-action-container">
                                { i > 0 &&
                                <div 
                                    className="primary-markets-tab-action-icon"
                                    onClick={() => moveUpOrDown(pm.id, true)}
                                    title="move up in order"
                                >
                                    <ArrowUpCircle/>
                                </div>
                                }
                                { i < primaryMarkets.length - 1 &&
                                <div 
                                    className="primary-markets-tab-action-icon"
                                    onClick={() => moveUpOrDown(pm.id, false)}
                                    title="move down in order"
                                >
                                    <ArrowDownCircle/>
                                </div>
                                }
                                <div 
                                    className="primary-markets-tab-action-icon"
                                    onClick={() => editPrimaryMarket(pm)}
                                    title="edit Primary Market details"
                                >
                                    <PencilSquare/>
                                </div>
                                <div 
                                    className="primary-markets-tab-action-icon"
                                    onClick={() => confirmDelete(pm.country.name, pm.id)}
                                    title="delete Primary Market"
                                >
                                    <Trash/>
                                </div>
                            </td>
                        </tr>
                        )
                        :
                        <tr>
                            <td colSpan={5}>Select a Business Line to load countries</td>
                        </tr>
                        }
                    </tbody>
                </Table>
            </div>
            </>
            }
        </div>
    );
}